<template>
    <div
        class="inline-flex items-center gap-x-1 rounded-full border py-1 pl-2.5 pr-1.5 text-xs font-medium"
        :class="classObject"
    >
        <AnimatedNumber :value="points" :decimals="0" />
        <svg
            v-if="progress > 0"
            class="size-3.5 flex-shrink-0"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
        >
            <path
                d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
            ></path>
        </svg>
        <svg
            v-else-if="progress < 0"
            class="size-3.5 flex-shrink-0"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
        >
            <path
                d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
            ></path>
        </svg>
        <Icon v-else :icon="IconEnums.POINT" class="size-2.5 text-slate-700" />
    </div>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";

import AnimatedNumber from "@/components/ui/AnimatedNumber";
import { getProperty } from "@/utils/object";
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";

export default {
    name: "PointBadge",
    components: {
        Icon,
        AnimatedNumber,
    },
    data: () => ({
        IconEnums,
    }),
    computed: {
        /**
         * The currently authenticated user
         * @type {Object}
         */
        user() {
            return this.$store.getters[AuthStoreNamespacedTypes.getters.USER]();
        },
        points() {
            return getProperty(this.user, "point_count", 0);
        },
        progress() {
            return getProperty(this.user, "progress", 0);
        },
        classObject() {
            return {
                "border-slate-200 border-teal-500 bg-teal-100 text-teal-600 hover:bg-teal-200/90":
                    this.progress > 0,
                "border-red-200 border-red-400 bg-red-200 text-red-500 hover:bg-red-200/90":
                    this.progress < 0,
                "border-slate-200 border-slate-400 bg-slate-200 text-slate-500 hover:bg-slate-200/90":
                    this.progress === 0,
            };
        },
    },
};
</script>
