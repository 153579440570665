<template>
    <fieldset class="flex flex-col gap-4">
        <slot />
    </fieldset>
</template>

<script>
export default {
    name: "FormFieldSet",
};
</script>
