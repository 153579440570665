<template>
    <li class="flex">
        <div class="flex grow items-center justify-between gap-x-2">
            <span
                class="text-sm font-medium text-slate-600"
                v-text="title"
            ></span>
            <div
                :class="badgeClass"
                class="rounded-full bg-white ring-1 ring-slate-200"
            >
                <ProgressRing :radius="10" :progress="value" :stroke="2" />
            </div>
        </div>
    </li>
</template>

<script>
import ProgressRing from "@/components/ui/ProgressRing";

export default {
    name: "FactorIndicator",
    components: { ProgressRing },
    props: {
        title: {
            type: String,
            required: true,
        },
        value: {
            type: Number,
            required: true,
        },
    },
    computed: {
        badgeClass() {
            return {
                "text-red-600": this.value < 50,
                "text-orange-500": this.value >= 50 && this.value < 70,
                "text-teal-500": this.value >= 70,
            };
        },
    },
};
</script>
