<template>
    <div>
        <Alert :error="error" />
        <ul v-if="loading" class="flex flex-col divide-y divide-slate-200">
            <li
                v-for="card in count"
                :key="card"
                class="flex flex-col p-4 lg:flex-row"
            >
                <div class="h-42 mb-4 flex w-full flex-col lg:mb-0 lg:w-64">
                    <div class="media animate-pulse rounded-xl"></div>
                </div>
                <div class="flex w-full flex-col lg:mx-6">
                    <div
                        class="mb-4 h-4 w-3/4 animate-pulse rounded bg-slate-400"
                    ></div>
                    <div
                        class="h-4 w-1/4 animate-pulse rounded bg-slate-400"
                    ></div>
                </div>
            </li>
        </ul>
        <ul v-else class="flex flex-col divide-y divide-slate-200">
            <slot />
        </ul>
    </div>
</template>

<script>
import Alert from "@/components/ui/Alert";

export default {
    name: "ListLoader",
    components: { Alert },
    props: {
        loading: {
            type: Boolean,
            default: true,
        },
        error: {
            type: Object,
            required: false,
        },
        count: {
            type: Number,
            required: true,
        },
    },
};
</script>
