<template>
    <div>
        <Alert :error="error" />
        <div v-if="loading" class="grid gap-5" :class="gridClass">
            <Card
                v-for="card in count"
                :key="card"
                :gutter="false"
                class="media-card animate-pulse"
            >
                <div class="media">
                    <div class="media-figure">
                        <div class="absolute left-0 top-0 w-full"></div>
                    </div>
                </div>
                <div class="p-3">
                    <div class="h-4 w-3/4 rounded bg-slate-400"></div>
                </div>
            </Card>
        </div>
        <div v-else class="grid gap-5" :class="gridClass">
            <slot />
        </div>
    </div>
</template>

<script>
import Card from "@/components/layout/Card";
import Alert from "@/components/ui/Alert";

export default {
    name: "CardListLoader",
    components: { Alert, Card },
    props: {
        loading: {
            type: Boolean,
            default: true,
        },
        error: {
            type: Object,
            required: false,
        },
        count: {
            type: Number,
            required: true,
        },
        inline: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        gridClass() {
            return this.inline ? "lg:grid-cols-" + this.count : null;
        },
    },
};
</script>
