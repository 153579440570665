/**
 * A store for handling videos
 *
 * @typedef {{ videoIndexLocale: string|null, videos: array, featuredVideos: array, videosLoading: boolean, videosError: ErrorOrObject, video: Object, videoLoading: boolean, videoError: ErrorOrObject, lastUpdateTime: string|null }} VideoStoreState
 */
import { addNamespace } from "./namespace";
import { postData } from "@/api";
import { isRecentEvent } from "@/utils/date";
import { getLocale } from "@/lang";
import { getProperty } from "@/utils/object";

/**
 * The types used in this store
 * @enum {string}
 */
export const VideoStoreTypes = {
    getters: {
        VIDEOS: "videos",
        FEATURED_VIDEOS: "featuredVideos",
        VIDEOS_LOADING: "videosLoading",
        VIDEOS_ERROR: "videosError",
        VIDEO: "video",
        VIDEO_RELATED: "videoRelated",
        VIDEO_HIGHLIGHTS: "videoHighlights",
        VIDEO_LOADING: "videoLoading",
        VIDEO_ERROR: "videoError",
        LAST_UPDATE_TIME: "lastUpdateTime",
    },
    actions: {
        GET_VIDEOS: "getVideos",
        GET_FEATURED_VIDEOS: "getFeaturedVideos",
        GET_VIDEO: "getVideo",
    },
    mutations: {
        SET_VIDEO_INDEX_LOCALE: "setVideoIndexLocale",
        SET_VIDEOS: "setVideos",
        SET_FEATURED_VIDEOS: "setFeaturedVideos",
        SET_VIDEOS_LOADING: "setVideosLoading",
        SET_VIDEOS_ERROR: "setVideosError",
        SET_VIDEO: "setVideo",
        SET_RELATED_VIDEOS: "setRelatedVideos",
        SET_VIDEO_HIGHLIGHTS: "setVideoHighlights",
        SET_VIDEO_LOADING: "setVideoLoading",
        SET_VIDEO_ERROR: "setVideoError",
        SET_LAST_UPDATE_TIME: "setLastUpdateTime",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const VideoStoreNamespacedTypes = addNamespace("video", VideoStoreTypes);

/**
 * @returns {VideoStoreState}
 */
export function state() {
    return {
        videoIndexLocale: null,
        videos: [],
        featuredVideos: [],
        videosLoading: false,
        videosError: null,
        video: {
            id: null,
            external_id: null,
            image: null,
            category: null,
            title: null,
            description: null,
            duration: null,
            locale: null,
            quiz_id: null,
            created_at: null,
            related: [],
        },
        videoRelated: [],
        videoHighlights: [],
        videoLoading: false,
        videoError: null,
        lastUpdateTime: null,
    };
}

export const getters = {
    [VideoStoreTypes.getters.VIDEOS]: (state) => () => {
        return state.videos;
    },
    [VideoStoreTypes.getters.FEATURED_VIDEOS]: (state) => () => {
        return state.featuredVideos;
    },
    [VideoStoreTypes.getters.VIDEOS_LOADING]: (state) => () => {
        return state.videosLoading;
    },
    [VideoStoreTypes.getters.VIDEOS_ERROR]: (state) => () => {
        return state.videosError;
    },
    [VideoStoreTypes.getters.VIDEO]: (state) => () => {
        return state.video;
    },
    [VideoStoreTypes.getters.VIDEO_RELATED]: (state) => () => {
        return state.videoRelated;
    },
    [VideoStoreTypes.getters.VIDEO_HIGHLIGHTS]: (state) => () => {
        return state.videoHighlights;
    },
    [VideoStoreTypes.getters.VIDEO_LOADING]: (state) => () => {
        return state.videoLoading;
    },
    [VideoStoreTypes.getters.VIDEO_ERROR]: (state) => () => {
        return state.videoError;
    },
};

export const actions = {
    /**
     * Sends the given credentials to the server and updates the store with the returned video data.
     *
     * @param {VideoStoreState} state
     * @param {VuexCommit} commit
     * @param {boolean} force
     * @param {boolean} showLoading
     * @return {Promise}
     */
    [VideoStoreTypes.actions.GET_VIDEOS](
        { state, commit },
        { force = false, showLoading = true }
    ) {
        const locale = getLocale();

        if (state.videoIndexLocale !== locale && !force) {
            force = true;
        }

        if (
            state.lastUpdateTime !== null &&
            isRecentEvent(state.lastUpdateTime) &&
            force === false
        ) {
            return Promise.resolve();
        }

        if (showLoading) {
            commit(VideoStoreTypes.mutations.SET_VIDEOS_LOADING, true);
        }
        commit(VideoStoreTypes.mutations.SET_VIDEOS_ERROR, null);

        return postData("/videos", { locale })
            .then((response) => {
                commit(VideoStoreTypes.mutations.SET_VIDEOS, response.data);
                commit(
                    VideoStoreTypes.mutations.SET_VIDEO_INDEX_LOCALE,
                    locale
                );
                commit(
                    VideoStoreTypes.mutations.SET_LAST_UPDATE_TIME,
                    new Date().getTime()
                );
            })
            .catch((errors) => {
                commit(VideoStoreTypes.mutations.SET_VIDEOS_ERROR, errors);
            })
            .finally(() => {
                commit(VideoStoreTypes.mutations.SET_VIDEOS_LOADING, false);
            });
    },

    /**
     * Sends the given credentials to the server and updates the store with the returned video data.
     *
     * @param {VuexCommit} commit
     * @return {Promise}
     */
    [VideoStoreTypes.actions.GET_FEATURED_VIDEOS]({ commit }) {
        commit(VideoStoreTypes.mutations.SET_VIDEOS_LOADING, true);
        commit(VideoStoreTypes.mutations.SET_VIDEOS_ERROR, null);

        return postData("/videos/featured", { locale: getLocale() })
            .then((response) => {
                commit(
                    VideoStoreTypes.mutations.SET_FEATURED_VIDEOS,
                    response.data
                );
            })
            .catch((errors) => {
                commit(VideoStoreTypes.mutations.SET_VIDEOS_ERROR, errors);
            })
            .finally(() => {
                commit(VideoStoreTypes.mutations.SET_VIDEOS_LOADING, false);
            });
    },

    /**
     * Sends the given credentials to the server and updates the store with the returned video data.
     *
     * @param {VuexCommit} commit
     * @param {String} video
     * @return {Promise}
     */
    [VideoStoreTypes.actions.GET_VIDEO]({ commit }, video) {
        commit(VideoStoreTypes.mutations.SET_VIDEO_LOADING, true);
        commit(VideoStoreTypes.mutations.SET_VIDEO_ERROR, null);

        return postData("/videos/" + video, { locale: getLocale() })
            .then((response) => {
                commit(
                    VideoStoreTypes.mutations.SET_VIDEO,
                    getProperty(response.data, "video", {})
                );
                commit(
                    VideoStoreTypes.mutations.SET_RELATED_VIDEOS,
                    getProperty(response.data, "related", [])
                );
                commit(
                    VideoStoreTypes.mutations.SET_VIDEO_HIGHLIGHTS,
                    getProperty(response.data, "highlights", [])
                );
            })
            .catch((errors) => {
                commit(VideoStoreTypes.mutations.SET_VIDEO_ERROR, errors);
            })
            .finally(() => {
                commit(VideoStoreTypes.mutations.SET_VIDEO_LOADING, false);
            });
    },
};

export const mutations = {
    /**
     * Sets video index locale
     *
     * @param {VideoStoreState} state
     * @param {string} locale
     */
    [VideoStoreTypes.mutations.SET_VIDEO_INDEX_LOCALE](state, locale) {
        state.videoIndexLocale = locale;
    },

    /**
     * Sets user videos.
     *
     * @param {VideoStoreState} state
     * @param {Object} videos
     */
    [VideoStoreTypes.mutations.SET_VIDEOS](state, videos) {
        state.videos = videos;
    },

    /**
     * Sets user videos.
     *
     * @param {VideoStoreState} state
     * @param {Object} videos
     */
    [VideoStoreTypes.mutations.SET_FEATURED_VIDEOS](state, videos) {
        state.featuredVideos = videos;
    },

    /**
     * Sets the loading state for the videos form.
     *
     * @param {VideoStoreState} state
     * @param {boolean} loading
     */
    [VideoStoreTypes.mutations.SET_VIDEOS_LOADING](state, loading) {
        state.videosLoading = loading;
    },

    /**
     * Sets a new videos error.
     *
     * @param {VideoStoreState} state
     * @param {ErrorOrObject} error
     */
    [VideoStoreTypes.mutations.SET_VIDEOS_ERROR](state, error) {
        state.videosError = error;
    },

    /**
     * Set video data.
     *
     * @param {VideoStoreState} state
     * @param {string|null} id
     * @param {string|null} external_id
     * @param {string|null} image
     * @param {string|null} category
     * @param {string|null} title
     * @param {string|null} description
     * @param {string|null} duration
     * @param {string|null} locale
     * @param {string|null} quiz_id
     * @param {string|null} created_at
     * @param {array|null} related
     */
    [VideoStoreTypes.mutations.SET_VIDEO](
        state,
        {
            id = null,
            external_id = null,
            image = null,
            category = null,
            title = null,
            description = null,
            duration = null,
            quiz_id = null,
            created_at = null,
            related = [],
        }
    ) {
        state.video.id = id;
        state.video.external_id = external_id;
        state.video.image = image;
        state.video.category = category;
        state.video.title = title;
        state.video.description = description;
        state.video.duration = duration;
        state.video.quiz_id = quiz_id;
        state.video.created_at = created_at;
        state.video.related = related;
    },

    /**
     * Sets the loading state for the videos form.
     *
     * @param {VideoStoreState} state
     * @param {array} data
     */
    [VideoStoreTypes.mutations.SET_RELATED_VIDEOS](state, data) {
        state.videoRelated = data;
    },

    /**
     * Sets the loading state for the videos form.
     *
     * @param {VideoStoreState} state
     * @param {array} data
     */
    [VideoStoreTypes.mutations.SET_VIDEO_HIGHLIGHTS](state, data) {
        state.videoHighlights = data;
    },

    /**
     * Sets the loading state for the videos form.
     *
     * @param {VideoStoreState} state
     * @param {boolean} loading
     */
    [VideoStoreTypes.mutations.SET_VIDEO_LOADING](state, loading) {
        state.videoLoading = loading;
    },

    /**
     * Sets a new videos error.
     *
     * @param {VideoStoreState} state
     * @param {ErrorOrObject} error
     */
    [VideoStoreTypes.mutations.SET_VIDEO_ERROR](state, error) {
        state.videoError = error;
    },

    /**
     * Sets a new breach error.
     *
     * @param {{lastUpdateTime: Date}} state
     * @param {string} lastUpdateTime
     */
    [VideoStoreTypes.mutations.SET_LAST_UPDATE_TIME](state, lastUpdateTime) {
        state.lastUpdateTime = new Date(lastUpdateTime);
    },
};

export default {
    namespaced: true,
    Types: VideoStoreTypes,
    NamespacedTypes: VideoStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
