import { snakeToCamel } from "./string";

export const getProperty = function (object, key, defaultValue) {
    if (!object) {
        return defaultValue;
    }

    const keys = key.split(".");
    const currentKey = keys.shift();

    let value = undefined;

    if (Object.prototype.hasOwnProperty.call(object, currentKey)) {
        value = object[currentKey];
    } else {
        return defaultValue;
    }

    if (keys.length > 0) {
        if (value) {
            return getProperty(value, keys.join("."), defaultValue);
        } else {
            return defaultValue;
        }
    }

    return value;
};

export const isObject = (value) => {
    return !!value && value.constructor === Object;
};

export const isEmptyObject = (value) => {
    return isObject(value) && Object.keys(value).length === 0;
};

export const isArray = (value) => {
    return !!value && value.constructor === Array;
};

export const isObjectOrArray = (value) => {
    return (
        !!value && (value.constructor === Object || value.constructor === Array)
    );
};

export const snakeToCamelKeys = (input, nested = false) => {
    return transformKeys(input, snakeToCamel, nested);
};

export const transformKeys = (input, transformer, nested = false) => {
    if (!input) {
        return input;
    }

    if (isArray(input)) {
        return input.map((value) => {
            if (nested && isObjectOrArray(value)) {
                return transformKeys(value, transformer, true);
            }

            return value;
        });
    }

    const newObject = {};

    Object.entries(input).forEach(([key, value]) => {
        if (nested && isObjectOrArray(value)) {
            value = transformKeys(value, transformer, true);
        }

        newObject[transformer(key)] = value;
    });

    return newObject;
};

export function removeEmptyValues(obj) {
    return Object.keys(obj)
        .filter(function (k) {
            return obj[k] != null;
        })
        .reduce(function (acc, k) {
            acc[k] = obj[k];
            return acc;
        }, {});
}
