/**
 * Adds the given namespace to all the type keys in the given type map.
 * @param {string} namespace
 * @param {Object<string, Object<string, string>>} types
 * @return {Object<string, Object<string, string>>}
 */
export function addNamespace(namespace, types) {
    const namespacedTypes = {};

    Object.keys(types).forEach((typeKey) => {
        const type = types[typeKey];
        const namespacedType = {};

        Object.keys(type).forEach((internalTypeKey) => {
            namespacedType[
                internalTypeKey
            ] = `${namespace}/${type[internalTypeKey]}`;
        });

        namespacedTypes[typeKey] = namespacedType;
    });

    return namespacedTypes;
}
