import i18n from "./lang";

import { setInteractionMode, configure, extend } from "vee-validate";

setInteractionMode("passive");

import { required, email, numeric, max, min } from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);
extend("numeric", numeric);
extend("max", max);
extend("min", min);

configure({
    defaultMessage: (field, values) => {
        values._field_ = i18n.t(`forms.fields.${field}`);
        return i18n.t(`validations.messages.${values._rule_}`, values);
    },
});
