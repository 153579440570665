<template>
    <svg
        viewBox="0 0 182 141"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M.355 70.22c.184-11.099-.024-22.647.844-33.691C2.203 23.751 5.616 11.431 17.586 4.52 29.557-2.391 41.932.813 53.5 6.333c9.998 4.77 19.895 10.725 29.599 16.115l.345.191.338.203a825.589 825.589 0 0 0 6.907 4.085c-7.389 4.336-14.901 8.717-21.848 13.491a87.131 87.131 0 0 0-3.215 2.313c-3.855-2.158-7.707-4.325-11.568-6.473-3.891-2.159-7.834-4.338-11.851-6.254-2.329-1.11-8.905-4.271-11.506-2.77-2.601 1.501-3.152 8.777-3.354 11.35-.348 4.438-.433 8.941-.509 13.391-.106 6.226-.154 12.453-.257 18.601.103 6.226.151 12.453.257 18.679.076 4.45.161 8.954.509 13.391.202 2.571.753 9.848 3.354 11.35 2.601 1.502 9.178-1.659 11.506-2.771 4.017-1.916 7.96-4.095 11.851-6.254 5.445-3.021 10.861-6.093 16.237-9.079 5.341-3.202 10.709-6.356 16.048-9.561 3.815-2.291 7.673-4.616 11.342-7.137 2.126-1.461 8.152-5.576 8.152-8.58 0-3.004-6.026-7.119-8.152-8.58-2.277-1.564-4.626-3.054-6.995-4.507 1.447-.888 2.9-1.761 4.347-2.629 5.338-3.205 10.707-6.36 15.98-9.523 1.581-.878 3.159-1.76 4.736-2.644 9.105 6.879 16.312 15.493 16.312 27.883 0 13.822-8.963 22.938-19.527 30.196-9.13 6.273-19.235 11.867-28.755 17.576l-.338.202-.345.192c-9.704 5.391-19.601 11.345-29.599 16.115-11.568 5.519-23.943 8.724-35.914 1.813-11.97-6.911-15.383-19.231-16.387-32.009C.332 93.656.54 82.107.356 71.008l-.006-.395.005-.393Z"
                id="jwncjh"
            />
            <path id="ozalvp" d="M.903.139H127.51V135.09H.903z" />
            <path
                d="M132.022 70.22c-.184-11.099.025-22.647-.843-33.691-1.004-12.778-4.417-25.098-16.388-32.009-11.97-6.911-24.345-3.707-35.914 1.813-9.998 4.77-19.895 10.725-29.599 16.115l-.345.191-.338.203C39.074 28.55 28.97 34.145 19.84 40.418 9.276 47.676.313 56.792.313 70.614c0 12.39 7.206 21.001 16.312 27.883 1.559-.874 3.116-1.743 4.668-2.606 5.341-3.202 10.709-6.356 16.048-9.561a529.3 529.3 0 0 0 4.347-2.63c-2.368-1.453-4.718-2.942-6.995-4.507-2.126-1.461-8.152-5.576-8.152-8.58 0-3.004 6.026-7.119 8.152-8.58 3.668-2.521 7.526-4.846 11.342-7.136 5.338-3.205 10.707-6.36 15.98-9.523 5.443-3.024 10.86-6.096 16.305-9.117 3.891-2.159 7.834-4.338 11.851-6.254 2.329-1.11 8.905-4.271 11.506-2.77 2.601 1.501 3.152 8.777 3.354 11.35.348 4.438.433 8.941.509 13.391.106 6.226.154 12.453.257 18.601-.103 6.226-.151 12.453-.257 18.679-.076 4.45-.161 8.954-.509 13.391-.203 2.571-.753 9.848-3.354 11.35-2.601 1.502-9.178-1.659-11.506-2.771-4.017-1.916-7.96-4.095-11.851-6.254-3.873-2.151-7.73-4.324-11.568-6.473a88.86 88.86 0 0 1-3.215 2.313c-6.947 4.774-14.459 9.154-21.848 13.491a853.856 853.856 0 0 1 6.907 4.085l.338.202.345.192c9.704 5.391 19.601 11.345 29.599 16.115 11.568 5.519 23.943 8.724 35.914 1.813 11.971-6.911 15.384-19.231 16.388-32.009.868-11.043.66-22.592.843-33.691l.006-.395-.007-.393Z"
                id="dsudju"
            />
            <path id="ifnftn" d="M.867.137h126.61v134.955H.867z" />
            <linearGradient
                x1="50%"
                y1="109.383%"
                x2="50%"
                y2="2.158%"
                id="dwwpgh"
            >
                <stop stop-color="#0287D0" offset="0%" />
                <stop stop-color="#8A5FEE" offset="45%" />
                <stop stop-color="#E05EC8" offset="100%" />
            </linearGradient>
            <linearGradient
                x1="50%"
                y1="109.814%"
                x2="50%"
                y2="2.089%"
                id="zngdvw"
            >
                <stop stop-color="#BE43CC" offset="0%" />
                <stop stop-color="#FF5496" offset="49%" />
                <stop stop-color="#FB3" offset="100%" />
            </linearGradient>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <g>
                <mask id="ipoxcl" fill="#fff">
                    <use xlink:href="#jwncjh" />
                </mask>
                <path
                    fill="url(#dwwpgh)"
                    fill-rule="nonzero"
                    mask="url(#ipoxcl)"
                    d="M.331-2.391h131.733V143.62H.331z"
                />
            </g>
            <g transform="translate(2 3)">
                <mask id="ewyxpd" fill="#fff">
                    <use xlink:href="#ozalvp" />
                </mask>
                <path
                    d="m96.575 56.55.556.38c3.233 2.221 9.26 6.388 9.26 10.685 0 4.297-6.027 8.463-9.26 10.685-3.725 2.56-7.599 4.896-11.474 7.222-5.339 3.205-10.708 6.36-16.049 9.562l-.074.042c-5.421 3.011-10.815 6.07-16.238 9.079-3.951 2.192-7.911 4.38-11.991 6.326-3.54 1.688-10.162 4.825-13.883 2.677-3.721-2.148-4.316-9.451-4.623-13.361-.354-4.507-.439-9.03-.517-13.548-.106-6.227-.154-12.454-.257-18.68v-.084c.104-6.201.151-12.401.257-18.602.078-4.519.163-9.041.517-13.548.307-3.91.902-11.213 4.623-13.361 3.721-2.148 10.343.987 13.883 2.677 4.081 1.947 8.042 4.132 11.992 6.327 3.384 1.88 6.759 3.778 10.136 5.671a91.19 91.19 0 0 1 1.961-1.383c5.91-4.061 12.072-7.752 18.255-11.389a566.186 566.186 0 0 1-3.18-1.892l-.265-.16-.345-.191C70.093 16.263 60.487 10.454 50.4 5.641 39.537.458 27.974-2.68 16.863 3.735S2.688 21.733 1.744 33.732C.869 44.874 1.089 56.096.908 67.264l-.006.311.006.395c.181 11.168-.039 22.391.836 33.533.943 11.999 4.007 23.582 15.119 29.997 11.111 6.415 22.674 3.277 33.537-1.906 10.087-4.813 19.693-10.622 29.459-16.043l.271-.149.338-.202c9.581-5.742 19.411-11.161 28.622-17.49 9.92-6.816 18.419-15.262 18.419-28.091 0-9.514-4.656-16.627-11.244-22.525a1973.083 1973.083 0 0 1-7.25 4.054c-4.108 2.46-8.275 4.919-12.44 7.402"
                    mask="url(#ewyxpd)"
                />
            </g>
            <g transform="translate(49)">
                <mask id="uprcks" fill="#fff">
                    <use xlink:href="#dsudju" />
                </mask>
                <path
                    fill="url(#zngdvw)"
                    fill-rule="nonzero"
                    mask="url(#uprcks)"
                    d="M.314-2.391h131.733V143.62H.314z"
                />
            </g>
            <g transform="translate(51 3)">
                <mask id="fpjilw" fill="#fff">
                    <use xlink:href="#ifnftn" />
                </mask>
                <path
                    d="M14.852 92.446c1.062-.593 2.112-1.179 3.13-1.743 5.34-3.202 10.708-6.356 16.046-9.561l.793-.477a134.709 134.709 0 0 1-3.574-2.368c-3.232-2.221-9.259-6.387-9.259-10.683s6.027-8.462 9.259-10.684c3.726-2.56 7.6-4.896 11.474-7.221 5.316-3.192 10.664-6.333 15.981-9.523l.074-.042c5.443-3.024 10.86-6.097 16.306-9.118 3.951-2.192 7.911-4.38 11.99-6.327 3.54-1.688 10.162-4.824 13.882-2.676 3.72 2.147 4.315 9.45 4.623 13.36.354 4.506.439 9.029.517 13.547.106 6.2.153 12.401.257 18.602v.084c-.103 6.227-.151 12.454-.257 18.68-.077 4.518-.163 9.041-.517 13.547-.307 3.91-.903 11.213-4.623 13.36-3.72 2.148-10.342-.988-13.882-2.676-4.08-1.947-8.04-4.134-11.991-6.327-3.385-1.879-6.76-3.778-10.137-5.671-.648.469-1.302.93-1.961 1.383-5.911 4.062-12.074 7.753-18.257 11.39 1.063.629 2.124 1.259 3.182 1.894l.265.16.345.191c9.766 5.421 19.372 11.23 29.459 16.043 10.864 5.185 22.427 8.321 33.539 1.906 11.112-6.415 14.176-17.998 15.119-29.998.875-11.142.655-22.364.836-33.533l.006-.311-.006-.395c-.181-11.168.04-22.391-.836-33.533-.943-12-4.008-23.583-15.119-29.998C100.405-2.687 88.841.45 77.977 5.634 67.89 10.447 58.284 16.256 48.518 21.677l-.271.149-.338.203c-9.58 5.742-19.411 11.161-28.622 17.49C9.366 46.336.867 54.781.867 67.612c0 10.79 5.985 18.491 13.985 24.834"
                    mask="url(#fpjilw)"
                />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "LogoSvg",
};
</script>
