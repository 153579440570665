<template>
    <Card :gutter="false">
        <div
            class="flex flex-row items-center justify-between border-b border-slate-200 p-4"
        >
            <div>
                <h3 class="heading">
                    {{ $t("mandatory.title") }}
                </h3>
                <p class="mt-1 text-sm text-slate-500">
                    {{ $t("mandatory.text") }}
                </p>
            </div>
            <FormButton
                v-if="hasNoContent && !loading"
                :variant="ButtonVariants.SECONDARY"
                :icon="IconEnums.CLOSE"
                @click.native="hideMandatoryContent"
            />
        </div>
        <ListLoader :count="1" :loading="loading" :error="error">
            <component
                v-for="item in allItems"
                :is="item.type"
                :key="item.id"
                :content="item.content"
                :deadline="item.deadline"
                referrer="overview"
                placement="mandatory"
            />
        </ListLoader>
        <div
            v-if="!loading && hasNoContent"
            class="flex h-40 flex-col items-center justify-center"
        >
            <div
                class="mb-4 flex h-8 w-8 items-center justify-center rounded-full bg-success-normal"
            >
                <Icon class="h-6 w-6 text-white" :icon="IconEnums.CHECK" />
            </div>
            <h3 class="mb-2 font-medium leading-tight text-slate-500">
                {{ $t("mandatory.completed.title") }}
            </h3>
            <p class="text-sm font-medium leading-tight text-slate-400">
                {{ $t("mandatory.completed.text") }}
            </p>
        </div>
    </Card>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";
import { MandatoryStoreNamespacedTypes } from "@/store/modules/mandatory";
import { CourseStoreNamespacedTypes } from "@/store/modules/course";

import { getProperty } from "@/utils/object";

import ListLoader from "@/components/mandatory/content/ListLoader";
import VideoListItem from "@/components/mandatory/content/VideoListItem";
import CourseListItem from "@/components/mandatory/content/CourseListItem";
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import Card from "@/components/layout/Card";

export default {
    name: "MandatoryTraining",
    components: {
        Card,
        FormButton,
        ListLoader,
        Icon,
        "video-type": VideoListItem,
        "course-type": CourseListItem,
    },
    data: () => ({
        IconEnums,
        ButtonVariants,
    }),
    computed: {
        /**
         * The request loading state
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                MandatoryStoreNamespacedTypes.getters.MANDATORY_LOADING
            ]();
        },

        /**
         * The request error state
         * @type {ErrorOrObject}
         */
        error() {
            return this.$store.getters[
                MandatoryStoreNamespacedTypes.getters.MANDATORY_ERROR
            ]();
        },

        /**
         * The mandatory content
         * @type {Object}
         */
        content() {
            return this.$store.getters[
                MandatoryStoreNamespacedTypes.getters.MANDATORY
            ]();
        },

        /**
         * Mandatory courses
         * @type {Array}
         */
        courses() {
            return getProperty(this.content, "courses", []);
        },

        /**
         * Mandatory videos
         * @type {Array}
         */
        videos() {
            return getProperty(this.content, "videos", []);
        },

        /**
         * Mandatory content
         * @type {Array}
         */
        allItems() {
            return [...this.courses, ...this.videos];
        },

        /**
         * @type {Boolean}
         */
        hasNoContent() {
            return this.allItems.length === 0;
        },
    },
    methods: {
        hideMandatoryContent() {
            this.$store.commit(
                AuthStoreNamespacedTypes.mutations.SET_MANDATORY_STATUS,
                false
            );
        },
    },
    beforeCreate() {
        this.$store.commit(
            MandatoryStoreNamespacedTypes.mutations.SET_MANDATORY_LOADING,
            true
        );
    },
    mounted() {
        this.$store.dispatch(
            MandatoryStoreNamespacedTypes.actions.GET_MANDATORY
        );

        this.$store.dispatch(CourseStoreNamespacedTypes.actions.GET_PROGRESS);
    },
};
</script>
