<template>
    <div class="container">
        <div class="px-4 py-4 sm:p-0">
            <div
                class="grid grid-cols-1 items-start gap-4 lg:grid-cols-4 lg:gap-6"
            >
                <div class="grid grid-cols-1 gap-4 lg:col-span-3">
                    <slot name="content" />
                </div>
                <div class="grid grid-cols-1 gap-4">
                    <slot name="sidebar" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TwoCol",
};
</script>
