<template>
    <div class="relative flex h-full min-h-screen w-full flex-col bg-slate-100">
        <div
            v-if="isLoading"
            class="flex h-full w-full flex-grow flex-col items-center justify-center"
        >
            <div class="mb-4 rounded-full bg-white p-2 shadow-xl">
                <Icon
                    :icon="IconEnums.LOADING"
                    class="size-6 animate-spin text-slate-800"
                />
            </div>
            <p class="font-medium text-slate-700">
                {{ $t("login.loading")
                }}<span v-for="x in 3" :key="x" class="load_dot">.</span>
            </p>
        </div>
        <div
            v-else-if="requireAuth"
            class="flex h-full w-full flex-grow bg-white"
        >
            <div
                class="relative z-10 flex flex-1 flex-col bg-white px-4 py-6 md:flex-none md:px-16"
            >
                <div
                    class="mx-auto flex h-full w-full max-w-md flex-col justify-between sm:px-4 md:w-96 md:max-w-sm md:px-0"
                >
                    <div class="mb-10 flex flex-row justify-between">
                        <LogoSvg class="h-9" />
                        <LocaleSwitcher />
                    </div>
                    <div>
                        <h3
                            class="font-display mb-6 text-2xl font-semibold text-slate-800"
                        >
                            {{ $t("login.title") }}
                        </h3>
                        <TwoFactorForm
                            v-if="showTwoFactor"
                            @authenticated="handleAuthenticated"
                        />
                        <LoginForm v-else @validated="handleValidated" />
                        <SsoForm />
                    </div>
                    <div class="mt-10 text-center">
                        <p class="text-slate-500">
                            &copy; {{ currentYear }} Moxso
                        </p>
                    </div>
                </div>
            </div>
            <div class="relative hidden w-full py-6 pr-6 md:flex">
                <div
                    class="gradient-bg-down flex h-full w-full items-center justify-center rounded-2xl bg-slate-900"
                ></div>
            </div>
        </div>
        <slot v-else />
    </div>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";
import { SsoStoreNamespacedTypes } from "@/store/modules/sso";

import { getProperty } from "@/utils/object";
import { redirectTo } from "@/router";

import Icon from "../icons/BaseIcon";
import { IconEnums } from "@/utils/icons";

import LoginForm from "../forms/LoginForm";
import TwoFactorForm from "../forms/TwoFactorForm";
import LogoSvg from "./LogoSvg";
import LocaleSwitcher from "@/components/ui/LocaleSwitcher";
import SsoForm from "@/components/forms/SsoForm";

export default {
    name: "BlockingLoginWrapper",
    components: {
        SsoForm,
        Icon,
        LocaleSwitcher,
        LogoSvg,
        TwoFactorForm,
        LoginForm,
    },
    props: {
        requireAuth: {
            type: Boolean,
            required: true,
        },
    },
    data: () => ({
        showTwoFactor: false,
        IconEnums: IconEnums,
    }),
    computed: {
        /**
         * The currently authenticated user
         * @type {Object}
         */
        user() {
            return this.$store.getters[AuthStoreNamespacedTypes.getters.USER]();
        },

        /**
         * The request loading state
         * @type {Boolean}
         */
        gateLoading() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.GATE_LOADING
            ]();
        },

        /**
         * The request loading state
         * @type {Boolean}
         */
        ssoLoading() {
            return this.$store.getters[
                SsoStoreNamespacedTypes.getters.SSO_LOADING
            ]();
        },

        /**
         * Display loading state
         * @type {Boolean}
         */
        isLoading() {
            return this.gateLoading || this.ssoLoading;
        },

        /**
         * The current year
         * @type {Number}
         */
        currentYear() {
            return new Date().getFullYear();
        },
    },
    methods: {
        handleValidated() {
            this.showTwoFactor = true;
        },
        handleAuthenticated() {
            this.showTwoFactor = false;
            if (getProperty(this.user, "onboarded", false) === false) {
                redirectTo("onboarding");
            }
        },
    },
};
</script>
