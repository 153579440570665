<template>
    <div class="flash-message">
        <Transition name="flash">
            <div v-if="message" class="content-wrapper">
                <div class="content">
                    <Icon
                        :icon="icon"
                        :class="{
                            'star-pulse text-point-yellow':
                                icon === IconEnums.POINT,
                        }"
                        class="mr-2 inline-block size-5"
                    />
                    <p class="mb-0">{{ message.text }}</p>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
import { IconEnums } from "@/utils/icons";
import Icon from "@/components/icons/BaseIcon";

export default {
    name: "FlashMessage",
    components: { Icon },
    data: () => ({
        IconEnums,
        message: null,
    }),
    computed: {
        icon() {
            return !this.message.icon ? IconEnums.QUESTION : this.message.icon;
        },
    },
    mounted() {
        let timer;
        window.Bus.$on("flash-message", (message) => {
            clearTimeout(timer);
            this.message = message;
            timer = setTimeout(() => {
                this.message = null;
            }, 5000);
        });
    },
};
</script>

<style lang="scss" scoped>
.flash-enter-active,
.flash-leave-active {
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.flash-enter-active .content-wrapper,
.flash-leave-active .content-wrapper {
    transition: opacity 0.3s linear 0.1s;
}

.flash-enter .content-wrapper {
    opacity: 0;
}

.flash-enter-to .content-wrapper,
.flash-leave .content-wrapper {
    opacity: 1;
}

.flash-enter,
.flash-leave-to {
    transform: translateY(calc(100% - 6px));
}
</style>
