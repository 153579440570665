/**
 * Feature flag mixin
 */
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";

export default {
    computed: {
        /**
         * The features state of the user.
         * @type {array}
         */
        features() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.FEATURES
            ]();
        },
    },
    methods: {
        hasFeature(feature) {
            return this.features.includes(feature);
        },
    },
};
