/**
 * A store for handling the different locales in the app.
 *
 * @typedef {{locale: string|null}} LocaleStoreState
 */

import { addNamespace } from "./namespace";
import { setLocale } from "@/lang";
import { localeEn, locales } from "@/utils/enums";

/**
 * The types used in this store
 * @enum {string}
 */
export const LocaleStoreTypes = {
    getters: {
        LOCALE: "locale",
    },
    actions: {
        UPDATE_LOCALE: "updateLocale",
    },
    mutations: {
        SET_LOCALE: "setLocale",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const LocaleStoreNamespacedTypes = addNamespace(
    "locale",
    LocaleStoreTypes
);

/**
 * @returns {LocaleStoreState}
 */
export function state() {
    return {
        locale: localeEn,
    };
}

export const getters = {
    [LocaleStoreTypes.getters.LOCALE]: (state) => () => {
        return state.locale;
    },
};

export const actions = {
    /**
     * Updates the current locale.
     *
     * @param {LocaleStoreState} state
     * @param {VuexCommit} commit
     * @param {string} locale
     */
    [LocaleStoreTypes.actions.UPDATE_LOCALE]({ state, commit }, locale) {
        let updatedLocal = locale ? locale : state.locale;

        if (!locales.includes(updatedLocal)) {
            updatedLocal = localeEn;
        }

        commit(LocaleStoreTypes.mutations.SET_LOCALE, updatedLocal);
    },
};

export const mutations = {
    /**
     * Sets the current locale
     *
     * @param {LocaleStoreState} state
     * @param {string|null} locale
     */
    [LocaleStoreTypes.mutations.SET_LOCALE](state, locale) {
        state.locale = locale;
        setLocale(locale);
    },
};

export default {
    namespaced: true,
    Types: LocaleStoreTypes,
    NamespacedTypes: LocaleStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
