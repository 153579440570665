/**
 * Icon enums
 * @type {Object<{name: String, title: String}>}
 */

export const IconEnums = {
    LOADING: { name: "Loading", title: "icons.loading" },
    DANISH: { name: "Danish", title: "icons.danish" },
    ENGLISH: { name: "English", title: "icons.english" },
    GERMAN: { name: "German", title: "icons.german" },
    CLICK: { name: "Click", title: "icons.click" },
    PLAY: { name: "Play", title: "icons.play" },
    BREACH: { name: "Breach", title: "icons.breach" },
    ERROR: { name: "Error", title: "icons.error" },
    EMAIL: { name: "Email", title: "icons.email" },
    TRASH: { name: "Trash", title: "icons.trash" },
    EYE: { name: "Eye", title: "icons.eye" },
    DOMAIN: { name: "Domain", title: "icons.domain" },
    GROUP: { name: "Group", title: "icons.group" },
    LOGOUT: { name: "Logout", title: "icons.logout" },
    POINT: { name: "Point", title: "icons.point" },
    QUESTION: { name: "Question", title: "icons.question" },
    MICROSOFT: { name: "Microsoft", title: "icons.microsoft" },
    LINKEDIN: { name: "Linkedin", title: "icons.linkedin" },
    REWIND: { name: "Rewind", title: "icons.rewind" },
    CHECK: { name: "Check", title: "icons.check" },
    WRONG: { name: "Wrong", title: "icons.wrong" },
    CLOSE: { name: "Close", title: "icons.close" },
    REFRESH: { name: "Refresh", title: "icons.refresh" },
    BACK: { name: "Back", title: "icons.back" },
    SEARCH: { name: "Search", title: "icons.search" },
    DOCUMENT: { name: "Document", title: "icons.document" },
    DOWNLOAD: { name: "Download", title: "icons.download" },
    CHECKMARK: {
        name: "Checkmark",
        title: "icons.checkmark",
    },
};

/**
 * Icon components
 */
import IconLoading from "@/components/icons/IconLoading";
import IconDanish from "@/components/icons/IconDanish";
import IconEnglish from "@/components/icons/IconEnglish";
import IconGerman from "@/components/icons/IconGerman";
import IconClick from "@/components/icons/IconClick";
import IconPlay from "@/components/icons/IconPlay";
import IconBreach from "@/components/icons/IconBreach";
import IconError from "@/components/icons/IconError";
import IconEmail from "@/components/icons/IconEmail";
import IconTrash from "@/components/icons/IconTrash";
import IconEye from "@/components/icons/IconEye";
import IconDomain from "@/components/icons/IconDomain";
import IconGroup from "@/components/icons/IconGroup";
import IconLogout from "@/components/icons/IconLogout";
import IconPoint from "@/components/icons/IconPoint";
import IconQuestion from "@/components/icons/IconQuestion";
import IconMicrosoft from "@/components/icons/IconMicrosoft";
import IconLinkedin from "@/components/icons/IconLinkedin";
import IconRewind from "@/components/icons/IconRewind";
import IconCheck from "@/components/icons/IconCheck";
import IconWrong from "@/components/icons/IconWrong";
import IconClose from "@/components/icons/IconClose";
import IconRefresh from "@/components/icons/IconRefresh";
import IconBack from "@/components/icons/IconBack";
import IconSearch from "@/components/icons/IconSearch";
import IconDocument from "@/components/icons/IconDocument";
import IconDownload from "@/components/icons/IconDownload";
import IconCheckmark from "@/components/icons/IconCheckmark";

export const IconComponents = {
    IconLoading,
    IconDanish,
    IconEnglish,
    IconGerman,
    IconClick,
    IconPlay,
    IconBreach,
    IconError,
    IconEmail,
    IconTrash,
    IconEye,
    IconDomain,
    IconGroup,
    IconLogout,
    IconPoint,
    IconQuestion,
    IconMicrosoft,
    IconLinkedin,
    IconRewind,
    IconCheck,
    IconWrong,
    IconClose,
    IconRefresh,
    IconBack,
    IconSearch,
    IconDocument,
    IconDownload,
    IconCheckmark,
};
