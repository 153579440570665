/**
 * A store for handling courses
 *
 * @typedef {{ courses: array, progress: array, coursesLoading: boolean, coursesError: ErrorOrObject, course: Object, courseLoading: boolean, courseError: ErrorOrObject }} CourseStoreState
 */
import { addNamespace } from "./namespace";
import { getData, postData } from "@/api";
import { getProperty } from "@/utils/object";
import { getLocale } from "@/lang";

/**
 * The types used in this store
 * @enum {string}
 */
export const CourseStoreTypes = {
    getters: {
        COURSES: "courses",
        PROGRESS: "progress",
        COMPLETED: "completed",
        COURSES_LOADING: "coursesLoading",
        COURSES_ERROR: "coursesError",
        COURSE: "course",
        COURSE_PROGRESS: "courseProgress",
        COURSE_LOADING: "courseLoading",
        COURSE_ERROR: "courseError",
    },
    actions: {
        GET_COURSES: "getCourses",
        GET_COURSE: "getCourse",
        GET_PROGRESS: "getProgress",
        STORE_PROGRESS: "storeProgress",
        DOWNLOAD_FILE: "downloadFile",
    },
    mutations: {
        SET_COURSES: "setCourses",
        SET_PROGRESS: "setProgress",
        SET_COMPLETED: "setCompleted",
        SET_FEATURED_COURSES: "setFeaturedCourses",
        SET_COURSES_LOADING: "setCoursesLoading",
        SET_COURSES_ERROR: "setCoursesError",
        SET_COURSE: "setCourse",
        SET_COURSE_PROGRESS: "setCourseProgress",
        SET_COURSE_LOADING: "setCourseLoading",
        SET_COURSE_ERROR: "setCourseError",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const CourseStoreNamespacedTypes = addNamespace(
    "course",
    CourseStoreTypes
);

/**
 * @returns {CourseStoreState}
 */
export function state() {
    return {
        courses: [],
        progress: [],
        completed: [],
        coursesLoading: false,
        coursesError: null,
        course: {
            id: null,
            checksum: null,
            title: null,
            image: null,
            video: null,
            description: null,
            content: [],
            featured: false,
        },
        courseProgress: {},
        courseLoading: false,
        courseError: null,
    };
}

export const getters = {
    [CourseStoreTypes.getters.COURSES]: (state) => () => {
        return state.courses;
    },
    [CourseStoreTypes.getters.PROGRESS]: (state) => () => {
        return state.progress;
    },
    [CourseStoreTypes.getters.COMPLETED]: (state) => () => {
        return state.completed;
    },
    [CourseStoreTypes.getters.COURSES_LOADING]: (state) => () => {
        return state.coursesLoading;
    },
    [CourseStoreTypes.getters.COURSES_ERROR]: (state) => () => {
        return state.coursesError;
    },
    [CourseStoreTypes.getters.COURSE]: (state) => () => {
        return state.course;
    },
    [CourseStoreTypes.getters.COURSE_PROGRESS]: (state) => () => {
        return state.courseProgress;
    },
    [CourseStoreTypes.getters.COURSE_LOADING]: (state) => () => {
        return state.courseLoading;
    },
    [CourseStoreTypes.getters.COURSE_ERROR]: (state) => () => {
        return state.courseError;
    },
};

export const actions = {
    /**
     * Sends the given credentials to the server and updates the store with the returned course data.
     *
     * @param {VuexCommit} commit
     * @return {Promise}
     */
    [CourseStoreTypes.actions.GET_COURSES]({ commit }) {
        commit(CourseStoreTypes.mutations.SET_COURSES_LOADING, true);
        commit(CourseStoreTypes.mutations.SET_COURSES_ERROR, null);

        return postData("/courses", { locale: getLocale() })
            .then((response) => {
                commit(
                    CourseStoreTypes.mutations.SET_COMPLETED,
                    getProperty(response, "data.completed", [])
                );
                commit(
                    CourseStoreTypes.mutations.SET_PROGRESS,
                    getProperty(response, "data.progress", [])
                );
                commit(
                    CourseStoreTypes.mutations.SET_COURSES,
                    getProperty(response, "data.courses", [])
                );
            })
            .catch((errors) => {
                commit(CourseStoreTypes.mutations.SET_COURSES_ERROR, errors);
            })
            .finally(() => {
                commit(CourseStoreTypes.mutations.SET_COURSES_LOADING, false);
            });
    },

    /**
     * Sends the given credentials to the server and updates the store with the returned course data.
     *
     * @param {VuexCommit} commit
     * @param {String} id
     * @return {Promise}
     */
    [CourseStoreTypes.actions.GET_COURSE]({ commit }, id) {
        commit(CourseStoreTypes.mutations.SET_COURSE_LOADING, true);
        commit(CourseStoreTypes.mutations.SET_COURSE_ERROR, null);

        return postData("/courses/" + id, { locale: getLocale() })
            .then((response) => {
                commit(
                    CourseStoreTypes.mutations.SET_COURSE,
                    getProperty(response, "data.course", {
                        id: null,
                        checksum: null,
                        title: null,
                        image: null,
                        video: null,
                        description: null,
                        content: [],
                        featured: false,
                    })
                );
                commit(
                    CourseStoreTypes.mutations.SET_COURSE_PROGRESS,
                    getProperty(response, "data.progress", { step: 0 })
                );
            })
            .catch((errors) => {
                commit(CourseStoreTypes.mutations.SET_COURSE_ERROR, errors);
            })
            .finally(() => {
                commit(CourseStoreTypes.mutations.SET_COURSE_LOADING, false);
            });
    },

    /**
     * Sends the given credentials to the server and updates the store with the returned course data.
     *
     * @param {VuexCommit} commit
     * @return {Promise}
     */
    [CourseStoreTypes.actions.GET_PROGRESS]({ commit }) {
        commit(CourseStoreTypes.mutations.SET_COURSE_ERROR, null);
        return getData("/courses/progress")
            .then((response) => {
                commit(
                    CourseStoreTypes.mutations.SET_PROGRESS,
                    getProperty(response.data, "progress", [])
                );
            })
            .catch((errors) => {
                commit(CourseStoreTypes.mutations.SET_COURSE_ERROR, errors);
            });
    },

    /**
     * Sends the given credentials to the server and updates the store with the returned course data.
     *
     * @param {VuexCommit} commit
     * @param {CourseStoreState} state
     * @param {Number} step
     * @return {Promise}
     */
    [CourseStoreTypes.actions.STORE_PROGRESS]({ commit, state }, step) {
        commit(CourseStoreTypes.mutations.SET_COURSE_ERROR, null);

        if (state.course.id === null) {
            return Promise.resolve();
        }

        return postData("/courses/progress", {
            course: state.course.id,
            step: step,
        })
            .then((response) => {
                commit(
                    CourseStoreTypes.mutations.SET_PROGRESS,
                    getProperty(response, "data", [])
                );
            })
            .catch((errors) => {
                commit(CourseStoreTypes.mutations.SET_COURSE_ERROR, errors);
            });
    },

    /**
     * Sends the given credentials to the server and updates the store with the returned course data.
     *
     * @param {VuexCommit} commit
     * @param {string} file
     * @return {Promise}
     */
    [CourseStoreTypes.actions.DOWNLOAD_FILE]({ commit }, file) {
        commit(CourseStoreTypes.mutations.SET_COURSE_ERROR, null);

        if (file === null) {
            return Promise.resolve();
        }

        return postData("/courses/download", {
            file: file,
        }).catch((errors) => {
            commit(CourseStoreTypes.mutations.SET_COURSE_ERROR, errors);
        });
    },
};

export const mutations = {
    /**
     * Sets user courses.
     *
     * @param {CourseStoreState} state
     * @param {Object} courses
     */
    [CourseStoreTypes.mutations.SET_COURSES](state, courses) {
        state.courses = courses;
    },

    /**
     * Sets user courses.
     *
     * @param {CourseStoreState} state
     * @param {Array} progress
     */
    [CourseStoreTypes.mutations.SET_PROGRESS](state, progress) {
        state.progress = progress;
    },

    /**
     * Sets user courses.
     *
     * @param {CourseStoreState} state
     * @param {Array} completed
     */
    [CourseStoreTypes.mutations.SET_COMPLETED](state, completed) {
        state.completed = completed;
    },

    /**
     * Sets the loading state for the courses form.
     *
     * @param {CourseStoreState} state
     * @param {boolean} loading
     */
    [CourseStoreTypes.mutations.SET_COURSES_LOADING](state, loading) {
        state.coursesLoading = loading;
    },

    /**
     * Sets a new courses error.
     *
     * @param {CourseStoreState} state
     * @param {ErrorOrObject} error
     */
    [CourseStoreTypes.mutations.SET_COURSES_ERROR](state, error) {
        state.coursesError = error;
    },

    /**
     * Set course data.
     *
     * @param {CourseStoreState} state
     * @param {string|null} id
     * @param {string|null} checksum
     * @param {string|null} title
     * @param {string|null} slug
     * @param {string|null} image
     * @param {string|null} video
     * @param {string|null} description
     * @param {array|null} content
     * @param {string|null} locale
     */
    [CourseStoreTypes.mutations.SET_COURSE](
        state,
        {
            id = null,
            checksum = null,
            title = null,
            image = null,
            video = null,
            description = null,
            content = null,
        }
    ) {
        state.course.id = id;
        state.course.checksum = checksum;
        state.course.title = title;
        state.course.image = image;
        state.course.video = video;
        state.course.description = description;
        state.course.content = content;
    },

    /**
     * Sets the progress state for the current course.
     *
     * @param {CourseStoreState} state
     * @param {Object} progress
     */
    [CourseStoreTypes.mutations.SET_COURSE_PROGRESS](state, progress) {
        state.courseProgress = progress;
    },

    /**
     * Sets the loading state for the courses form.
     *
     * @param {CourseStoreState} state
     * @param {boolean} loading
     */
    [CourseStoreTypes.mutations.SET_COURSE_LOADING](state, loading) {
        state.courseLoading = loading;
    },

    /**
     * Sets a new courses error.
     *
     * @param {CourseStoreState} state
     * @param {ErrorOrObject} error
     */
    [CourseStoreTypes.mutations.SET_COURSE_ERROR](state, error) {
        state.courseError = error;
    },
};

export default {
    namespaced: true,
    Types: CourseStoreTypes,
    NamespacedTypes: CourseStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
