<template>
    <Card>
        <div class="grid gap-4 sm:grid-cols-12">
            <div class="sm:order-2 sm:col-span-5">
                <div class="overflow-hidden rounded-xl bg-slate-100">
                    <img class="w-full rounded" :src="image" alt="media" />
                </div>
            </div>
            <div class="sm:order-1 sm:col-span-7">
                <div
                    class="flex h-full flex-col items-start justify-between space-y-3"
                >
                    <div>
                        <h3 class="heading" v-text="title"></h3>
                        <p
                            class="mt-1 text-slate-500 sm:mt-3"
                            v-text="text"
                        ></p>
                    </div>
                </div>
            </div>
        </div>
    </Card>
</template>

<script>
import Card from "@/components/layout/Card";

export default {
    name: "InformationCard",
    components: { Card },
    props: {
        title: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        image: {
            type: String,
            required: true,
        },
    },
};
</script>
