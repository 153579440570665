<template>
    <g fill="currentColor"
        ><path fill="#f35325" d="M0 0h10v10H0z" /><path
            fill="#81bc06"
            d="M11 0h10v10H11z"
        /><path fill="#05a6f0" d="M0 11h10v10H0z" /><path
            fill="#ffba08"
            d="M11 11h10v10H11z"
        />
    </g>
</template>

<script>
import IconSvgBase from "./IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconMicrosoft",
    data: () => ({
        viewBoxHeight: 21,
        viewBoxWidth: 21,
    }),
};
</script>
