<template>
    <div class="mt-6">
        <div class="relative">
            <div class="absolute inset-0 flex items-center">
                <div class="w-full border-t border-slate-300"></div>
            </div>
            <div class="relative flex justify-center text-sm">
                <span class="bg-white px-2 text-slate-500">
                    {{ $t("sso.or") }}
                </span>
            </div>
        </div>
        <div class="mt-6 flex flex-col">
            <Alert :error="error" />
            <FormButton
                :variant="ButtonVariants.SECONDARY"
                :size="ButtonSizeVariants.XL"
                :loading="loading"
                @click.native="login('microsoft')"
            >
                <Icon :icon="IconEnums.MICROSOFT" />
                <span class="ml-2">{{
                    $t("sso.login_with", { provider: "Microsoft" })
                }}</span>
            </FormButton>
        </div>
    </div>
</template>

<script>
import { SsoStoreNamespacedTypes } from "@/store/modules/sso";
import AmplitudeMixin from "@/mixins/AmplitudeMixin";

import Alert from "../ui/Alert";
import FormButton, {
    ButtonSizeVariants,
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import Icon from "../icons/BaseIcon";
import { IconEnums } from "@/utils/icons";

export default {
    name: "SsoForm",
    mixins: [AmplitudeMixin],
    components: {
        FormButton,
        Icon,
        Alert,
    },
    data: () => ({
        ButtonVariants,
        ButtonSizeVariants,
        IconEnums,
        loading: false,
    }),
    computed: {
        /**
         * The request error state
         * @type {ErrorOrObject}
         */
        error() {
            return this.$store.getters[
                SsoStoreNamespacedTypes.getters.SSO_ERROR
            ]();
        },
    },
    methods: {
        login(provider) {
            this.loading = true;
            this.$store
                .dispatch(SsoStoreNamespacedTypes.actions.GET_GRANT, provider)
                .then((response) => {
                    this.loading = false;
                    if (!this.error) {
                        window.location.replace(response.data.url);
                        this.trackAmplitudeEvent("login", {
                            method: "sso",
                            provider: provider,
                        });
                    }
                });
        },
    },
};
</script>
