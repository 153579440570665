export function stripLinksFromHtml(html) {
    return html.replace(new RegExp("<[^>]*>", "g"), "");
}

export function stringToSnake(string) {
    return string
        .match(
            /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
        )
        .map((x) => x.toLowerCase())
        .join("_");
}

export function camelToSnake(string) {
    return string
        .replace(/[A-Z]|\d+/g, (match) => {
            return `_${match.toLowerCase()}`;
        })
        .replace(/^_/, "");
}

export function kebabToSnake(string) {
    return stringToSnake(string).replace("-", "_").toLowerCase();
}

export function kebabToCamel(string) {
    return string
        .replace(/-([a-z])/g, (g) => g[1].toUpperCase())
        .replace(/^-/, "");
}

export function camelToKebab(string) {
    return string
        .replace(/[A-Z]|\d+/g, (match) => {
            return `-${match.toLowerCase()}`;
        })
        .replace(/^-/, "");
}

export function snakeToCamel(string) {
    return string.replace(/_\w/g, (match) => {
        return match.substr(1).toUpperCase();
    });
}

export function uuid() {
    let dt = new Date().getTime();
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
            const r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
    );
}

export function stringToBoolean(value, default_) {
    if (default_ !== undefined && typeof default_ !== "boolean") {
        throw new TypeError(
            `Expected the \`default\` option to be of type \`boolean\`, got \`${typeof default_}\``
        );
    }

    if (value === undefined || value === null) {
        return default_;
    }

    value = String(value).trim();

    if (/^(?:y|yes|true|1|on)$/i.test(value)) {
        return true;
    }

    if (/^(?:n|no|false|0|off)$/i.test(value)) {
        return false;
    }

    return default_;
}
