<template>
    <svg
        v-if="height"
        :viewBox="`0 0 ${radius * 2} ${height}`"
        height="100%"
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
    >
        <defs>
            <!-- Determine the gradient color on the full part of the gauge -->
            <linearGradient v-if="hasGradient" :id="`gaugeGradient-${_uid}`">
                <stop
                    v-for="(color, index) in gaugeColor"
                    :key="`${color.color}-${index}`"
                    :offset="`${color.offset}%`"
                    :stop-color="color.color"
                />
            </linearGradient>

            <mask :id="`innerCircle-${_uid}`">
                <!-- Mask to make sure only the part inside the circle is visible -->
                <!-- this.radius - 0.5 to avoid any weird display -->
                <circle
                    :r="radius - 0.5"
                    :cx="X_CENTER"
                    :cy="Y_CENTER"
                    fill="white"
                />

                <!-- Mask to remove the inside of the gauge -->
                <circle
                    :r="innerRadius"
                    :cx="X_CENTER"
                    :cy="Y_CENTER"
                    fill="black"
                />

                <template v-if="separatorPaths">
                    <!-- Mask for each separator -->
                    <path
                        v-for="(separator, index) in separatorPaths"
                        :key="index"
                        :d="separator"
                        fill="black"
                    />
                </template>
            </mask>
        </defs>

        <g :mask="`url(#innerCircle-${_uid})`">
            <!-- Draw a circle if the full gauge has a 360° angle, otherwise draw a path -->
            <circle
                v-if="isCircle"
                :r="radius"
                :cx="X_CENTER"
                :cy="Y_CENTER"
                :fill="hasGradient ? `url(#gaugeGradient-${_uid})` : gaugeColor"
            />
            <path
                v-else
                :d="basePath"
                :fill="hasGradient ? `url(#gaugeGradient-${_uid})` : gaugeColor"
            />

            <!-- Draw a circle if the empty gauge has a 360° angle, otherwise draw a path -->
            <circle
                v-if="value === min && isCircle"
                :r="radius"
                :cx="X_CENTER"
                :cy="Y_CENTER"
                :fill="baseColor"
            />
            <path
                v-else
                :d="gaugePath"
                :fill="baseColor"
                :filter="`url(#innershadow-${_uid})`"
            />
        </g>

        <template v-if="scaleLines">
            <!-- Display a line for each tick of the scale -->
            <line
                v-for="(line, index) in scaleLines"
                :key="`${line.xE}-${index}`"
                :x1="line.xS"
                :y1="line.yS"
                :x2="line.xE"
                :y2="line.yE"
                stroke-width="1"
                :stroke="baseColor"
            />
        </template>

        <!-- This allow to display html inside the svg -->
        <foreignObject x="0" y="0" width="100%" :height="height">
            <slot />
        </foreignObject>
    </svg>
</template>

<script>
import * as TWEEN from "@tweenjs/tween.js";
import { getProperty } from "@/utils/object";
import { polarToCartesian, describePath } from "@/utils/svg";

export default {
    name: "GaugeChart",
    props: {
        value: {
            type: Number,
            default: 70,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 100,
        },
        tweenedValue: {
            type: Number,
            default: 0,
        },
        startAngle: {
            type: Number,
            default: -90,
            validator: (value) => {
                if (value < -360 || value > 360) {
                    console.warn(
                        'GaugeChart - props "startAngle" must be between -360 and 360'
                    );
                }
                return true;
            },
        },
        endAngle: {
            type: Number,
            default: 90,
            validator: (value) => {
                if (value < -360 || value > 360) {
                    console.warn(
                        'GaugeChart - props "endAngle" must be between -360 and 360'
                    );
                }
                return true;
            },
        },
        innerRadius: {
            type: Number,
            default: 60,
            validator: (value) => {
                if (value < 0 || value > 100) {
                    console.warn(
                        `GaugeChart - props "innerRadius" must be between 0 and ${this.radius}`
                    );
                }
                return true;
            },
        },
        separatorStep: {
            type: Number,
            default: 10,
            validator: (value) => {
                if (value !== null && value < 0) {
                    console.warn(
                        'GaugeChart - props "separatorStep" must be null or >= 0'
                    );
                }
                return true;
            },
        },
        separatorThickness: {
            type: Number,
            default: 4,
        },
        gaugeColor: {
            type: [Array, String],
            default: () => [
                { offset: 0, color: "#347AB0" },
                { offset: 100, color: "#8CDFAD" },
            ],
        },
        baseColor: {
            type: String,
            default: "#DDDDDD",
        },
        easing: {
            type: String,
            default: "Circular.Out",
        },
        scaleInterval: {
            type: Number,
            default: 5,
            validator: (value) => {
                if (value !== null && value < 0) {
                    console.warn(
                        'GaugeChart - props "scaleInterval" must be null or >= 0'
                    );
                }
                return true;
            },
        },
        transitionDuration: {
            type: Number,
            default: 1500,
        },
    },
    data() {
        return {
            X_CENTER: 100,
            Y_CENTER: 100,
            radius: 100,
            localTweenedValue: this.tweenedValue,
        };
    },
    computed: {
        /**
         * Height of the viewbox calculated by getting
         * - the lower y between the center and the start and end angle
         * - (this.radius * 2) if one of the angle is bigger than 180°
         * @type {Number}
         */
        height() {
            const { endAngle, startAngle } = this;
            const { y: yStart } = polarToCartesian(
                this.radius,
                startAngle,
                this.X_CENTER,
                this.Y_CENTER
            );
            const { y: yEnd } = polarToCartesian(
                this.radius,
                endAngle,
                this.X_CENTER,
                this.Y_CENTER
            );

            return Math.abs(endAngle) <= 180 && Math.abs(startAngle) <= 180
                ? Math.max(this.Y_CENTER, yStart, yEnd)
                : this.radius * 2;
        },
        /**
         * d property of the path of the base gauge (the colored one)
         * @type {String}
         */
        basePath() {
            const { startAngle, endAngle } = this;

            return describePath(
                this.radius,
                startAngle,
                endAngle,
                this.X_CENTER,
                this.Y_CENTER
            );
        },
        /**
         * d property of the gauge according to the value.
         * This gauge will hide a part of the base gauge
         * @type {String}
         */
        gaugePath() {
            const { endAngle, getAngle, localTweenedValue } = this;

            return describePath(
                this.radius,
                getAngle(localTweenedValue),
                endAngle,
                this.X_CENTER,
                this.Y_CENTER
            );
        },
        /**
         * Total angle of the gauge
         * @type {Number}
         */
        totalAngle() {
            const { startAngle, endAngle } = this;

            return Math.abs(endAngle - startAngle);
        },
        /**
         * True if the gauge is a full circle
         * @type {Boolean}
         */
        isCircle() {
            return Math.abs(this.totalAngle) === 360;
        },
        /**
         * True if the gaugeColor is an array
         * Result in displaying a gradient instead of a simple color
         * @type {Boolean}
         */
        hasGradient() {
            return Array.isArray(this.gaugeColor);
        },
        /**
         * Array of the path of each separator
         */
        separatorPaths() {
            const {
                separatorStep,
                getAngle,
                min,
                max,
                separatorThickness,
                isCircle,
            } = this;

            if (separatorStep > 0) {
                const paths = [];
                // If the gauge is a circle, this will add a separator at the start
                let i = isCircle ? min : min + separatorStep;

                for (i; i < max; i += separatorStep) {
                    const angle = getAngle(i);
                    const halfAngle = separatorThickness / 2;

                    paths.push(
                        describePath(
                            this.radius + 2,
                            angle - halfAngle,
                            angle + halfAngle,
                            this.X_CENTER,
                            this.Y_CENTER
                        )
                    );
                }

                return paths;
            }

            return null;
        },
        /**
         * Array of line configuration for each scale
         */
        scaleLines() {
            const { scaleInterval, isCircle, min, max, getAngle, innerRadius } =
                this;

            if (scaleInterval > 0) {
                const lines = [];
                // if gauge is a circle, remove the first scale
                let i = isCircle ? min + scaleInterval : min;

                for (i; i < max + scaleInterval; i += scaleInterval) {
                    const angle = getAngle(i);
                    const startCoordinate = polarToCartesian(
                        innerRadius - 4,
                        angle,
                        this.X_CENTER,
                        this.Y_CENTER
                    );
                    const endCoordinate = polarToCartesian(
                        innerRadius - 8,
                        angle,
                        this.X_CENTER,
                        this.Y_CENTER
                    );

                    lines.push({
                        xS: startCoordinate.x,
                        yS: startCoordinate.y,
                        xE: endCoordinate.x,
                        yE: endCoordinate.y,
                    });
                }

                return lines;
            }

            return null;
        },
    },
    watch: {
        /**
         * Watch the value and tween it to make an animation
         * If value < min, used value will be min
         * If value > max, used value will be max
         */
        value: {
            immediate: true,
            handler(next) {
                const {
                    easing,
                    localTweenedValue,
                    min,
                    max,
                    transitionDuration,
                } = this;
                let safeValue = next;

                if (next < min) {
                    safeValue = min;
                }

                if (next > max) {
                    safeValue = max;
                }

                function animate() {
                    if (TWEEN.update()) {
                        requestAnimationFrame(animate);
                    }
                }

                new TWEEN.Tween({ tweeningValue: localTweenedValue })
                    .to({ tweeningValue: safeValue }, transitionDuration)
                    .easing(getProperty(TWEEN.Easing, easing))
                    .onUpdate((object) => {
                        this.localTweenedValue = object.tweeningValue;
                    })
                    .start();

                animate();
            },
        },
    },
    methods: {
        /**
         * Get an angle for a value
         * @param   {Number} value
         * @returns {Number} angle - in degree
         */
        getAngle(value) {
            const { min, max, startAngle, totalAngle } = this;
            // Make sure not to divide by 0
            const totalValue = max - min || 1;

            return (value * totalAngle) / totalValue + startAngle;
        },
    },
    mounted() {
        this.localTweenedValue = this.tweenedValue;
    },
};
</script>
