/**
 * A store for handling the different layouts in the app.
 *
 * @typedef {{elements: Object<string, boolean>}} LayoutStoreState
 */

import Vue from "vue";
import { addNamespace } from "./namespace";

/**
 * The types used in this store
 * @enum {string}
 */
export const LayoutStoreTypes = {
    getters: { IS_VISIBLE: "isVisible" },
    actions: {
        SET_VISIBLE: "setVisible",
        SET_VISIBLE_MULTIPLE: "setVisibleMultiple",
    },
    mutations: { SET_VISIBLE: "setVisible" },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const LayoutStoreNamespacedTypes = addNamespace(
    "layout",
    LayoutStoreTypes
);

export function state() {
    return { elements: {} };
}

export const getters = {
    /**
     * Returns true if the element with the given ID is visible.
     * Returns false if the element with the given ID isn't visible or hasn't been added to the store.
     *
     * @param {LayoutStoreState} state
     * @return {function(*): boolean}
     */
    [LayoutStoreTypes.getters.IS_VISIBLE]: (state) => (id) => {
        return !!state.elements[id];
    },
};

export const actions = {
    /**
     * Sets the visibility of the given element ID.
     *
     * @param {VuexCommit} commit
     * @param {string} id
     * @param {boolean} visible
     */
    [LayoutStoreTypes.actions.SET_VISIBLE]({ commit }, { id, visible }) {
        commit(LayoutStoreTypes.mutations.SET_VISIBLE, { id, visible });
    },

    /**
     * Sets the visibility of each element in the given map.
     * Example: {some-element: true, some-other-element: false}
     *
     * @param {VuexCommit} commit
     * @param {Object<string, boolean>} map
     */
    [LayoutStoreTypes.actions.SET_VISIBLE_MULTIPLE]({ commit }, map) {
        Object.keys(map).forEach((id) => {
            commit(LayoutStoreTypes.mutations.SET_VISIBLE, {
                id,
                visible: map[id],
            });
        });
    },
};

export const mutations = {
    /**
     * Sets the visibility of the given element ID.
     *
     * @param {LayoutStoreState} state
     * @param {string} id
     * @param {boolean} visible
     */
    [LayoutStoreTypes.mutations.SET_VISIBLE](state, { id, visible }) {
        Vue.set(state.elements, id, visible);
    },
};

export default {
    namespaced: true,
    Types: LayoutStoreTypes,
    NamespacedTypes: LayoutStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
