<template>
    <router-link
        :to="{
            name: 'videos.single',
            params: { category: slug, video: id },
            query: { referrer: referrer, placement: placement },
        }"
    >
        <Card :gutter="false" class="media-card">
            <div class="media">
                <img :src="image" class="media-figure" alt="media" />
                <div class="media-plc"></div>
                <div
                    class="absolute bottom-2 left-2 flex items-center rounded bg-white/10 px-1.5 py-0.5 text-sm text-white backdrop-blur"
                >
                    <Icon
                        :icon="IconEnums.PLAY"
                        class="h-3 w-2 fill-current stroke-current"
                    /><time class="ml-2">{{ duration }}</time>
                </div>
                <Icon
                    v-if="isWatched"
                    :icon="IconEnums.CHECKMARK"
                    class="absolute right-2 top-2 h-5 w-5 flex-none fill-emerald-500 stroke-white"
                />
            </div>
            <div class="px-4 py-2">
                <div class="font-medium text-slate-700">
                    {{ title }}
                </div>
            </div>
        </Card>
    </router-link>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";

import { formatTime } from "@/utils/date";
import { getProperty } from "@/utils/object";
import { camelToKebab } from "@/utils/string";

import { IconEnums } from "@/utils/icons";
import Icon from "../icons/BaseIcon";

import Card from "@/components/layout/Card";

export default {
    name: "VideoCard",
    components: { Card, Icon },
    props: {
        video: {
            type: Object,
            required: true,
        },
        category: {
            type: String,
            required: true,
        },
        referrer: {
            type: String,
            required: false,
        },
        placement: {
            type: String,
            required: false,
        },
    },
    data: () => ({
        IconEnums,
    }),
    filters: {
        formatTime,
    },
    computed: {
        /**
         * Video ID
         * @type {String}
         */
        id() {
            return getProperty(this.video, "id", null);
        },

        /**
         * Video image
         * @type {String}
         */
        image() {
            let img = getProperty(this.video, "image", null);
            return img ? img : "/img/placeholder.jpg";
        },

        /**
         * Video title
         * @type {String}
         */
        title() {
            return getProperty(this.video, "title", null);
        },

        /**
         * Video duration
         * @type {String}
         */
        duration() {
            return formatTime(getProperty(this.video, "duration", null));
        },

        /**
         * Category slug.
         * @type {String}
         */
        slug() {
            return camelToKebab(this.category);
        },

        /**
         * Videos already viewed by the user.
         * @type {String}
         */
        watchedVideos() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.WATCHED_VIDEOS
            ]();
        },

        /**
         * If the video is already watched by the user.
         * @type {boolean}
         */
        isWatched() {
            return this.watchedVideos.includes(this.id);
        },
    },
};
</script>
