import Vue from "vue";
import VueRouter from "vue-router";

const routes = {
    base: "/",
    mode: "history",
    linkExactActiveClass: "active",
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
    routes: [
        {
            path: "/",
            name: "overview",
            component: require("./views/Overview").default,
            meta: {
                auth: true,
                title: "overview",
            },
        },
        {
            path: "/sso/:provider",
            name: "ssoRedirect",
            component: require("./views/SsoRedirect").default,
            meta: {
                auth: false,
                title: "SSO",
                layout: "unauthenticated",
            },
        },
        {
            path: "/onboarding",
            name: "onboarding",
            component: () => import("./views/Onboarding"),
            meta: {
                auth: true,
                title: "onboarding",
                layout: "onboarding",
            },
        },
        {
            path: "/videos",
            name: "videos.index",
            component: () => import("./views/video/Index"),
            meta: {
                auth: true,
                title: "videos",
            },
        },
        {
            path: "/videos/:category",
            name: "videos.category",
            component: () => import("./views/video/Category"),
            meta: {
                auth: true,
                title: "videos",
            },
        },
        {
            path: "/videos/:category/video/:video",
            name: "videos.single",
            component: () => import("./views/video/Single"),
            meta: {
                auth: true,
                title: "videos",
            },
        },
        {
            path: "/quiz",
            name: "quiz",
            component: () => import("./views/Quiz"),
            meta: {
                auth: true,
                title: "quiz",
                layout: "fullscreen",
            },
        },
        {
            path: "/courses",
            name: "courses.index",
            component: () => import("./views/course/Index"),
            meta: {
                auth: true,
                title: "courses",
            },
        },
        {
            path: "/courses/:id",
            name: "courses.single",
            component: () => import("./views/course/Single"),
            meta: {
                auth: true,
                title: "courses",
                layout: "fullscreen",
                exit_to: "courses.index",
            },
        },
        {
            path: "/simulations",
            name: "simulations",
            component: () => import("./views/Simulations"),
            meta: {
                auth: true,
                title: "simulations",
            },
        },
        {
            path: "/breaches",
            name: "breaches",
            component: () => import("./views/Breaches"),
            meta: {
                auth: true,
                title: "breaches",
            },
        },
        { path: "*", component: () => import("./views/PageNotFound") },
    ],
};

Vue.use(VueRouter);

const router = new VueRouter(routes);

export function updateQuery(query) {
    router.push({ query: query });
}

export function redirectTo(name) {
    if (router.currentRoute.name !== name) {
        router.push({ name: name });
    }
}

export function redirectToPath(path) {
    if (router.currentRoute.path !== path) {
        router.push({ path: path });
    }
}

export default router;
