<template>
    <ValidationProvider
        tag="div"
        class="sm:col-span-6"
        :vid="vid"
        :rules="rules"
        :name="name || label"
        v-slot="{ errors, ariaInput, ariaMsg }"
    >
        <label
            class="pointer-events-none mb-2 block text-sm font-medium text-slate-900"
            @click="$refs.input.focus()"
            :for="name"
        >
            {{ label || name }}
        </label>
        <input
            class="block w-full rounded-md border-slate-300 px-3 py-3.5 text-slate-800 transition-colors placeholder:text-slate-500 hover:bg-slate-50 focus:border-indigo-500 focus:bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500/10 focus:ring-offset-2 focus:ring-offset-slate-50"
            :class="{ 'is-invalid': errors[0] }"
            :id="name"
            :type="type"
            :placeholder="placeholder"
            ref="input"
            v-model="innerValue"
            v-bind="ariaInput"
            autocomplete="off"
        />
        <span class="mt-2 block text-sm text-slate-500" v-if="help">{{
            help
        }}</span>
        <span
            class="mt-3 text-sm text-red-600"
            v-bind="ariaMsg"
            v-if="errors[0]"
            >{{ errors[0] }}</span
        >
    </ValidationProvider>
</template>

<script>
import FieldMixin from "./FieldMixin";

export default {
    name: "FormInput",
    mixins: [FieldMixin],
    props: {
        placeholder: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "text",
            validator(value) {
                return [
                    "url",
                    "text",
                    "password",
                    "tel",
                    "search",
                    "number",
                    "email",
                ].includes(value);
            },
        },
    },
};
</script>
