<template>
    <BlockingLoginWrapper :requireAuth="shouldBeAuthenticated">
        <FlashMessage />
        <Navigation
            v-if="isDefaultLayout"
            @show-search="toggleShowSearch"
            @show-score="toggleShowScore"
        />
        <SimpleNavigation v-if="isFullscreenLayout" />
        <ScoreSlideOver :active="showScore" @close="toggleShowScore" />
        <RouterView :class="`${layout}-layout`" />
        <footer v-if="isDefaultLayout">
            <p class="pb-4 text-center text-sm text-slate-500">
                &copy; {{ currentYear }} Moxso
            </p>
        </footer>
        <CommandPalette v-if="showSearch" @close="toggleShowSearch" />
        <SvgSprite />
    </BlockingLoginWrapper>
</template>

<script>
import { AuthStoreNamespacedTypes } from "./store/modules/auth";
import { SsoStoreNamespacedTypes } from "@/store/modules/sso";
import { LayoutStoreNamespacedTypes } from "./store/modules/layout";
import { LocaleStoreNamespacedTypes } from "./store/modules/locale";
import AmplitudeMixin from "@/mixins/AmplitudeMixin";

import { getProperty } from "@/utils/object";

import BlockingLoginWrapper from "./components/ui/BlockingLoginWrapper";
import FlashMessage from "@/components/ui/FlashMessage";
import Navigation from "./components/layout/Navigation";
import SimpleNavigation from "@/components/layout/SimpleNavigation";
import SvgSprite from "@/components/layout/SvgSprite";
import CommandPalette from "@/components/search/CommandPalette";
import ScoreSlideOver from "@/components/ui/ScoreSlideOver";

export default {
    mixins: [AmplitudeMixin],
    components: {
        ScoreSlideOver,
        CommandPalette,
        SvgSprite,
        SimpleNavigation,
        FlashMessage,
        Navigation,
        BlockingLoginWrapper,
    },
    data: () => ({
        layout: "default",
        metaAuth: true,
        showSearch: false,
        showScore: false,
    }),
    computed: {
        /**
         * The authentication state of the user.
         * @type {Boolean}
         */
        isLoggedIn() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.IS_LOGGED_IN
            ]();
        },

        /**
         * Determine if view requires authentication.
         * @type {Boolean}
         */
        shouldBeAuthenticated() {
            return this.metaAuth && !this.isLoggedIn;
        },

        /**
         * Current layout.
         * @type {String}
         */
        isDefaultLayout() {
            return this.$store.getters[
                LayoutStoreNamespacedTypes.getters.IS_VISIBLE
            ]("default-layout");
        },

        /**
         * Current layout.
         * @type {String}
         */
        isFullscreenLayout() {
            return this.$store.getters[
                LayoutStoreNamespacedTypes.getters.IS_VISIBLE
            ]("fullscreen-layout");
        },

        /**
         * The error state for SSO requests
         * @type {ErrorOrObject}
         */
        ssoError() {
            return this.$store.getters[
                SsoStoreNamespacedTypes.getters.SSO_ERROR
            ]();
        },

        /**
         * The current year.
         * @type {Number}
         */
        currentYear() {
            return new Date().getFullYear();
        },
    },
    watch: {
        $route: {
            handler(newRoute) {
                this.metaAuth = getProperty(newRoute, "meta.auth", true);
                this.layout = getProperty(newRoute, "meta.layout", "default");
            },
            immediate: true,
        },
        layout: {
            handler(newLayout) {
                this.applyLayout(newLayout);
            },
            immediate: true,
        },
    },
    methods: {
        applyLayout(layout) {
            const displayDefault = layout === "default";
            const displayFullscreen = layout === "fullscreen";
            const displayUnauthenticated = layout === "unauthenticated";
            this.$store.dispatch(
                LayoutStoreNamespacedTypes.actions.SET_VISIBLE_MULTIPLE,
                {
                    "default-layout": displayDefault,
                    "fullscreen-layout": displayFullscreen,
                    "unauthenticated-layout": displayUnauthenticated,
                }
            );
        },
        isVisible(element) {
            return this.$store.getters[
                LayoutStoreNamespacedTypes.getters.IS_VISIBLE
            ](element);
        },
        setLocale(locale = null) {
            this.$store.dispatch(
                LocaleStoreNamespacedTypes.actions.UPDATE_LOCALE,
                locale
            );
        },
        checkCurrentState() {
            const state = getProperty(this.$route.query, "state", null);
            const code = getProperty(this.$route.query, "code", null);
            const redirect = getProperty(this.$route.query, "redirect", null);

            if (code && state) {
                this.validateSsoGrant(code, state);
            } else if (state) {
                this.$store.dispatch(
                    AuthStoreNamespacedTypes.actions.VALIDATE_GATE,
                    { state, redirect }
                );
            } else {
                if (this.isLoggedIn) {
                    this.$store.dispatch(
                        AuthStoreNamespacedTypes.actions.CHECK_CURRENT_SESSION
                    );
                }
            }
        },
        validateSsoGrant(code, state) {
            this.$store
                .dispatch(SsoStoreNamespacedTypes.actions.VALIDATE_TOKEN, {
                    code,
                    state,
                })
                .then((response) => {
                    this.$router.replace({ query: null });
                    if (!this.ssoError) {
                        this.$store.commit(
                            AuthStoreNamespacedTypes.mutations.SET_TOKEN,
                            response.data.token
                        );

                        this.$store.dispatch(
                            AuthStoreNamespacedTypes.actions
                                .CHECK_CURRENT_SESSION
                        );
                    }
                });
        },
        toggleShowSearch() {
            this.showSearch = !this.showSearch;
        },
        toggleShowScore() {
            this.showScore = !this.showScore;
        },
    },
    mounted() {
        this.$store.dispatch(
            AuthStoreNamespacedTypes.actions.FETCH_CSRF_COOKIE
        );
    },
    created() {
        this.checkCurrentState();

        const locale = getProperty(this.$route.query, "locale", null);
        if (locale) {
            this.setLocale(locale);
        } else {
            this.setLocale();
        }

        this.initAmplitude();
    },
};
</script>
