<template>
    <router-link
        :to="route"
        :class="[
            '-m-3 flex items-center rounded-md p-3 text-base font-medium hover:bg-slate-100',
            { 'bg-slate-100': isActive },
            {
                'text-slate-900': !isActive,
            },
        ]"
    >
        {{ $t("navigation." + text) }}
    </router-link>
</template>

<script>
export default {
    name: "NavItemMobile",
    props: {
        route: {
            type: Object,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
    },
    computed: {
        isActive() {
            return this.$route.name === this.route.name;
        },
    },
};
</script>
