<template>
    <nav class="bg-slate-900">
        <div class="relative mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex h-16 items-center justify-between">
                <div
                    class="relative flex flex-shrink-0 flex-row items-center justify-center"
                >
                    <router-link :to="{ name: 'overview' }">
                        <LogoSvg class="h-8" />
                    </router-link>
                </div>
                <div class="ml-10 flex items-baseline space-x-4">
                    <FormButton
                        :to="{ name: exitPage }"
                        :variant="ButtonVariants.DARK"
                    >
                        {{ $t("navigation.exit") }}
                        <Icon class="ml-2 h-5 w-5" :icon="IconEnums.LOGOUT"
                    /></FormButton>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { getProperty } from "@/utils/object";
import LogoSvg from "../ui/LogoSvg";
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";

export default {
    name: "SimpleNavigation",
    components: {
        LogoSvg,
        Icon,
        FormButton,
    },
    data: () => ({
        IconEnums,
        ButtonVariants,
    }),
    computed: {
        exitPage() {
            return getProperty(this.$route, "meta.exit_to", "overview");
        },
    },
};
</script>
