<template>
    <nav class="bg-slate-900">
        <div class="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="flex h-16 items-center justify-between">
                <div class="flex items-center">
                    <div
                        class="relative flex flex-shrink-0 flex-row items-center justify-center"
                    >
                        <router-link
                            :to="{
                                name: 'overview',
                            }"
                        >
                            <LogoSvg class="h-8" />
                        </router-link>
                    </div>
                    <div class="hidden md:block">
                        <div class="ml-6 flex items-baseline space-x-2">
                            <router-link
                                :to="{ name: 'overview' }"
                                class="nav-item"
                                exact
                            >
                                {{ $t("navigation.overview") }}
                            </router-link>
                            <router-link
                                v-if="hasFeature('hub-videos')"
                                :to="{ name: 'videos.index' }"
                                class="nav-item"
                            >
                                {{ $t("navigation.videos") }}
                            </router-link>
                            <router-link
                                v-if="hasFeature('hub-courses')"
                                :to="{ name: 'courses.index' }"
                                class="nav-item"
                            >
                                {{ $t("navigation.courses") }}
                            </router-link>
                            <router-link
                                v-if="hasFeature('hub-simulations')"
                                :to="{ name: 'simulations' }"
                                class="nav-item"
                            >
                                {{ $t("navigation.simulations") }}
                            </router-link>
                            <router-link
                                v-if="hasFeature('hub-breaches')"
                                :to="{ name: 'breaches' }"
                                class="nav-item"
                            >
                                {{ $t("navigation.breaches") }}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div
                    class="hidden space-x-3 md:flex md:flex-row md:items-center"
                >
                    <PointBadge
                        @click.native="$emit('show-score')"
                        class="cursor-pointer"
                    />
                    <button
                        v-if="hasFeature('hub-search')"
                        @click="$emit('show-search')"
                        type="button"
                        class="highlight-white/5 hidden h-9 w-36 items-center space-x-2 rounded bg-slate-800 px-4 text-left text-slate-300 shadow-sm ring-1 ring-slate-900/10 hover:bg-slate-700 hover:ring-slate-300 focus:outline-none focus:ring-2 focus:ring-slate-500 lg:flex"
                    >
                        <Icon
                            class="h-5 w-5 flex-none text-slate-400"
                            :icon="IconEnums.SEARCH"
                        />
                        <span class="flex-auto text-sm font-medium">{{
                            $t("search.placeholder")
                        }}</span>
                    </button>
                    <div
                        class="cursor-pointer rounded bg-slate-800 px-3 py-2 text-sm font-medium text-slate-300 hover:bg-slate-700 hover:text-white"
                        @click="logout"
                    >
                        <Icon class="h-5 w-5" :icon="IconEnums.LOGOUT" />
                    </div>
                </div>
                <div
                    class="-my-2 -mr-2 flex flex-row items-center space-x-3 md:hidden"
                >
                    <PointBadge
                        @click.native="$emit('show-score')"
                        class="cursor-pointer"
                    />
                    <button
                        type="button"
                        class="inline-flex items-center justify-center rounded-md bg-slate-800 p-2 text-slate-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        @click="toggleNav"
                        :aria-expanded="open.toString()"
                    >
                        <span class="sr-only">Open menu</span>
                        <svg
                            class="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <div
                v-show="open"
                class="absolute inset-x-0 top-0 z-50 origin-top-right transform p-2 transition md:hidden"
            >
                <div
                    class="divide-y-2 divide-slate-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                >
                    <div class="px-5 pb-6 pt-5">
                        <div class="flex items-center justify-between">
                            <div>
                                <LogoSvg class="h-8" />
                            </div>
                            <div class="-mr-2">
                                <button
                                    type="button"
                                    class="inline-flex items-center justify-center rounded-md bg-white p-2 text-slate-400 hover:bg-slate-100 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                    @click="toggleNav"
                                >
                                    <span class="sr-only">Close menu</span>
                                    <svg
                                        class="h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        ></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="mt-6">
                            <nav class="grid gap-y-8">
                                <NavItemMobile
                                    :route="{ name: 'overview' }"
                                    text="overview"
                                />
                                <NavItemMobile
                                    v-if="hasFeature('hub-videos')"
                                    :route="{ name: 'videos.index' }"
                                    text="videos"
                                />
                                <NavItemMobile
                                    v-if="hasFeature('hub-courses')"
                                    :route="{ name: 'courses.index' }"
                                    text="courses"
                                />
                                <NavItemMobile
                                    v-if="hasFeature('hub-simulations')"
                                    :route="{ name: 'simulations' }"
                                    text="simulations"
                                />
                                <NavItemMobile
                                    v-if="hasFeature('hub-breaches')"
                                    :route="{ name: 'breaches' }"
                                    text="breaches"
                                />
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </nav>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";
import FeatureFlagMixin from "@/mixins/FeatureFlagMixin";
import AmplitudeMixin from "@/mixins/AmplitudeMixin";
import { getProperty } from "@/utils/object";

import LogoSvg from "../ui/LogoSvg";
import NavItemMobile from "@/components/layout/NavItemMobile";
import { IconEnums } from "@/utils/icons";
import Icon from "@/components/icons/BaseIcon";
import PointBadge from "@/components/ui/PointBadge";

export default {
    name: "NavigationElement",
    mixins: [FeatureFlagMixin, AmplitudeMixin],
    components: {
        PointBadge,
        NavItemMobile,
        LogoSvg,
        Icon,
    },
    data: () => ({
        IconEnums,
        open: false,
    }),
    computed: {
        /**
         * The currently authenticated user
         * @type {Object}
         */
        user() {
            return this.$store.getters[AuthStoreNamespacedTypes.getters.USER]();
        },
        points() {
            return getProperty(this.user, "point_count", 0);
        },
        score() {
            return getProperty(this.user, "score.total", 0);
        },
    },
    watch: {
        $route: {
            handler() {
                this.open = false;
            },
            immediate: true,
        },
    },
    methods: {
        toggleNav() {
            this.open = !this.open;
        },
        logout() {
            this.$store
                .dispatch(AuthStoreNamespacedTypes.actions.LOGOUT)
                .then(() => {
                    this.trackAmplitudeEvent("Logout");
                });
        },
    },
};
</script>
