export function polarToCartesian(radius, angle, xCenter, yCenter) {
    const angleInRadians = ((angle - 90) * Math.PI) / 180;

    return {
        x: xCenter + radius * Math.cos(angleInRadians),
        y: yCenter + radius * Math.sin(angleInRadians),
    };
}

export function describePath(radius, startAngle, endAngle, xCenter, yCenter) {
    const start = polarToCartesian(radius, endAngle, xCenter, yCenter);
    const end = polarToCartesian(radius, startAngle, xCenter, yCenter);

    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    return [
        "M",
        start.x,
        start.y,
        "A",
        radius,
        radius,
        0,
        largeArcFlag,
        0,
        end.x,
        end.y,
        "L",
        xCenter,
        yCenter,
    ].join(" ");
}
