<template>
    <div class="mb-4 mt-1 flex items-center text-sm text-slate-500 lg:mb-0">
        <div v-if="showDeadlineAlert" class="relative mr-2 flex h-3 w-3">
            <div
                class="absolute inline-flex h-full w-full animate-ping-slow rounded-full bg-error-dark opacity-75"
            ></div>
            <div
                class="relative inline-flex h-3 w-3 rounded-full bg-error-dark"
            ></div>
        </div>
        {{ $t("mandatory.deadline", { date: formattedDeadline }) }}
    </div>
</template>

<script>
import { formatDate, isInNearFuture } from "@/utils/date";

export default {
    name: "DeadlineBadge",
    props: {
        deadline: {
            type: String,
            required: false,
            default: null,
        },
    },
    computed: {
        formattedDeadline() {
            return this.deadline ? formatDate(this.deadline) : null;
        },
        showDeadlineAlert() {
            return this.deadline ? isInNearFuture(this.deadline) : false;
        },
    },
};
</script>
