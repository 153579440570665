var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"mandatory-course relative flex flex-col p-4 lg:flex-row"},[_c('div',{staticClass:"mb-4 w-full lg:mb-0 lg:h-32 lg:w-56"},[_c('router-link',{attrs:{"to":{
                name: 'courses.single',
                params: { slug: _vm.slug, id: _vm.id },
                query: {
                    referrer: this.referrer,
                    placement: this.placement,
                },
            }}},[(_vm.video)?_c('div',{staticClass:"media rounded-xl ring-1 ring-slate-200"},[_c('video',{staticClass:"media-figure",attrs:{"autoplay":"","loop":"","muted":"","poster":_vm.image},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.video,"type":"video/mp4"}})]),_c('div',{staticClass:"absolute bottom-2 left-2 flex items-center rounded bg-slate-600 px-1.5 py-0.5 text-sm text-white"},[_vm._v(" "+_vm._s(_vm.$tc("courses.lessons", _vm.totalLessons))+" ")])]):_c('div',{staticClass:"media rounded-xl"},[_c('img',{staticClass:"media-figure",attrs:{"src":_vm.image,"alt":"media"}}),_c('div',{staticClass:"absolute bottom-2 left-2 flex items-center rounded bg-slate-600 px-1.5 py-0.5 text-sm text-white"},[_vm._v(" "+_vm._s(_vm.$tc("courses.lessons", _vm.totalLessons))+" ")])])])],1),_c('div',{staticClass:"relative flex flex-grow flex-col justify-between lg:ml-4 lg:items-start"},[_c('div',[_c('h4',{staticClass:"heading"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.deadline)?_c('DeadlineBadge',{attrs:{"deadline":_vm.deadline}}):_vm._e()],1),_c('FormButton',{attrs:{"variant":_vm.ButtonVariants.SECONDARY,"size":_vm.ButtonSizeVariants.SM,"to":{
                name: 'courses.single',
                params: { slug: _vm.slug, id: _vm.id },
                query: {
                    referrer: this.referrer,
                    placement: this.placement,
                },
            }}},[_vm._v(" "+_vm._s(_vm.currentStep > 0 ? _vm.$t("courses.progress.button") : _vm.$t("courses.content.introduction.start")))]),(_vm.currentStep > 0)?_c('div',{staticClass:"absolute right-1 top-1 hidden flex-row items-center justify-start sm:flex"},[_c('div',{staticClass:"rounded-full bg-white p-0.5 ring-2 ring-slate-100"},[_c('ProgressRing',{attrs:{"radius":14,"progress":_vm.percentageCompleted,"stroke":3}})],1),_c('p',{staticClass:"pl-2 text-sm font-medium text-slate-800"},[_vm._v(" "+_vm._s(_vm.percentageCompleted + "%")+" ")])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }