<template>
    <div :class="['card', { 'p-4': gutter }]">
        <slot />
    </div>
</template>

<script>
export default {
    name: "CardItem",
    props: {
        gutter: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
