<template>
    <header class="relative bg-white shadow-sm">
        <div class="mx-auto max-w-7xl px-4 py-2 sm:px-6 lg:px-8">
            <div class="flex h-8 flex-row items-center justify-between">
                <div class="flex flex-row items-center">
                    <div v-if="returnTo" class="flex flex-row items-center">
                        <router-link
                            class="heading text-slate-500 hover:text-blue-500"
                            :to="returnTo"
                            >{{
                                returnToText
                                    ? returnToText
                                    : $t("navigation.back")
                            }}</router-link
                        >
                        <Icon
                            :icon="IconEnums.BACK"
                            class="mx-1 hidden h-6 w-6 text-slate-300 sm:inline"
                        />
                    </div>
                    <h1 class="heading hidden sm:inline">
                        {{ translate ? $t(title + ".title") : title }}
                    </h1>
                </div>
                <LocaleSwitcher v-if="locale" />
            </div>
        </div>
    </header>
</template>

<script>
import LocaleSwitcher from "@/components/ui/LocaleSwitcher";
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";

export default {
    name: "PageHeader",
    components: { Icon, LocaleSwitcher },
    data: () => ({
        IconEnums,
    }),
    props: {
        title: {
            type: String,
            required: true,
        },
        locale: {
            type: Boolean,
            default: true,
        },
        translate: {
            type: Boolean,
            default: true,
        },
        returnTo: {
            type: Object,
            default: () => {},
        },
        returnToText: {
            type: String,
            default: "",
        },
    },
};
</script>
