var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col rounded-lg border p-4"},[_c('div',{staticClass:"relative mb-4"},[_c('div',{staticClass:"flex h-2.5 w-full overflow-hidden rounded-full bg-slate-200"},[_c('div',{staticClass:"flex w-full flex-col justify-center overflow-hidden whitespace-nowrap bg-gradient-to-r from-red-500 via-yellow-400 to-teal-400 text-center text-xs text-white",attrs:{"role":"progressbar","aria-valuenow":_vm.value,"aria-valuemin":"0","aria-valuemax":"100"}})]),_c('div',{class:[
                _vm.value < 25
                    ? 'bg-red-500'
                    : _vm.value < 50
                    ? 'bg-orange-500'
                    : _vm.value < 75
                    ? 'bg-yellow-400'
                    : 'bg-teal-400',
                'absolute top-1/2 h-5 w-2 -translate-y-1/2 transform rounded-full border-2 border-white transition-all duration-300 ease-in-out',
            ],style:({ left: `calc(${_vm.formatedValue}% - 0.5rem)` })})]),_c('h3',{staticClass:"text-sm font-medium leading-tight text-slate-900",domProps:{"textContent":_vm._s(_vm.title)}}),_c('p',{staticClass:"mt-1 text-xs text-slate-600",domProps:{"textContent":_vm._s(_vm.text)}})])
}
var staticRenderFns = []

export { render, staticRenderFns }