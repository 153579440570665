<template>
    <li class="mandatory-video relative flex flex-col p-4 lg:flex-row">
        <div class="mb-4 w-full lg:mb-0 lg:h-32 lg:w-56">
            <router-link
                router-link
                :to="{
                    name: 'videos.single',
                    params: { category: slug, video: id },
                    query: {
                        referrer: this.referrer,
                        placement: this.placement,
                    },
                }"
            >
                <div class="media rounded-xl ring-1 ring-slate-200">
                    <img :src="image" class="media-figure" alt="media" />
                    <div class="media-plc"></div>
                    <div
                        class="absolute bottom-2 left-2 flex items-center rounded bg-slate-600 px-1.5 py-0.5 text-sm text-white"
                    >
                        <Icon
                            :icon="IconEnums.PLAY"
                            class="h-3 w-2 fill-current stroke-current"
                        /><time class="ml-2">{{ duration }}</time>
                    </div>
                </div></router-link
            >
        </div>
        <div
            class="relative flex flex-grow flex-col justify-between lg:ml-4 lg:items-start"
        >
            <div>
                <p class="heading">
                    {{ title }}
                </p>
                <DeadlineBadge v-if="deadline" :deadline="deadline" />
            </div>
            <FormButton
                :to="{
                    name: 'videos.single',
                    params: { category: slug, video: id },
                    query: {
                        referrer: this.referrer,
                        placement: this.placement,
                    },
                }"
                :variant="ButtonVariants.SECONDARY"
                :size="ButtonSizeVariants.SM"
            >
                {{ $t("videos.play") }}</FormButton
            >
        </div>
    </li>
</template>

<script>
import { formatTime } from "@/utils/date";
import { getProperty } from "@/utils/object";
import { camelToKebab } from "@/utils/string";

import { IconEnums } from "@/utils/icons";
import Icon from "@/components/icons/BaseIcon";
import FormButton, {
    ButtonVariants,
    ButtonSizeVariants,
} from "@/components/forms/fields/FormButton";
import DeadlineBadge from "@/components/mandatory/content/DeadlineBadge";

export default {
    name: "VideoListItem",
    components: { DeadlineBadge, Icon, FormButton },
    props: {
        content: {
            type: Object,
            required: true,
        },
        deadline: {
            type: String,
            required: false,
            default: null,
        },
        referrer: {
            type: String,
            required: false,
        },
        placement: {
            type: String,
            required: false,
        },
    },
    data: () => ({
        IconEnums,
        ButtonVariants,
        ButtonSizeVariants,
    }),
    computed: {
        tagName() {
            return "router-link";
        },
        id() {
            return getProperty(this.content, "id", null);
        },
        image() {
            let img = getProperty(this.content, "image", null);
            return img ? img : "/img/placeholder.jpg";
        },
        title() {
            return getProperty(this.content, "title", null);
        },
        duration() {
            return formatTime(getProperty(this.content, "duration", null));
        },
        slug() {
            return camelToKebab(getProperty(this.content, "category", null));
        },
    },
};
</script>
