<template>
    <main>
        <PageHeader v-show="show" :title="title || defaultTitle" />
        <slot />
    </main>
</template>

<script>
import PageHeader from "@/components/layout/PageHeader";
import { getProperty } from "@/utils/object";

export default {
    name: "PageWrapper",
    components: { PageHeader },
    props: {
        show: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            required: false,
        },
    },
    computed: {
        defaultTitle() {
            return getProperty(this.$route, "meta.title", null);
        },
    },
};
</script>
