<template>
    <Message
        :visible="hasErrors"
        class="border-2 border-red-400 bg-red-50"
        :allow-dismiss="allowDismiss"
        @dismiss="$emit('dismiss')"
    >
        <template v-if="!isNested" #header>
            <h5 class="text-sm font-medium text-red-600">
                {{ heading || $t("api.messages.general_exception") }}
            </h5>
        </template>
        <div class="mt-2 text-sm font-medium text-red-600">
            <ul v-if="subErrors.length > 0" class="list-disc space-y-1 pl-5">
                <li
                    v-for="subError in subErrors"
                    :key="JSON.stringify(subError)"
                >
                    {{ $t(subError.error) }}
                </li>
            </ul>
            <p v-else-if="errorMessage">
                {{ $t(errorMessage) }}
            </p>
        </div>
        <p v-if="retryCallback">
            <button @click="retryCallback" class="btn btn-outline-primary">
                {{ retryLabel }}
            </button>
        </p>
    </Message>
</template>

<script>
import Message from "./Message";
import { getProperty, isObject, isEmptyObject } from "@/utils/object";

export default {
    name: "AlertItem",
    components: { Message },
    props: {
        error: {
            type: [Error, Object],
            required: false,
        },
        heading: {
            type: String,
            required: false,
        },
        isNested: {
            type: Boolean,
            required: false,
            default: false,
        },
        retryCallback: {
            type: Function,
            required: false,
        },
        retryLabel: {
            type: String,
            required: false,
            default() {
                return "Retry action";
            },
        },
        allowDismiss: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        errorMessage() {
            return getProperty(this.error, "message", null);
        },
        subErrors() {
            return getProperty(this.error, "errors", []);
        },
        hasErrors() {
            return isObject(this.error) && !isEmptyObject(this.error);
        },
    },
};
</script>
