export const mathMax = Math.max;

export const mathPow = Math.pow;

export const toInteger = (value, defaultValue = NaN) => {
    const integer = parseInt(value, 10);
    return isNaN(integer) ? defaultValue : integer;
};

export const toFloat = (value, defaultValue = NaN) => {
    const float = parseFloat(value);
    return isNaN(float) ? defaultValue : float;
};

export const toFixed = (val, precision) =>
    toFloat(val).toFixed(toInteger(precision, 0));

export const toPercent = (from, to, precision) => {
    const percent = (from / to) * 100;
    return toInteger(toFixed(percent, precision));
};

export function isNegative(number) {
    return toInteger(number) < 0;
}
