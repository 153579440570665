<template>
    <div class="relative rounded-xl bg-white shadow">
        <div class="flex flex-row items-center justify-between p-4">
            <h3 class="heading">
                {{ $t("statistics.leaderboard") }}
            </h3>
        </div>
        <Alert :error="error" />
        <ul
            v-if="loading"
            role="list"
            class="divide-y divide-slate-200 border-t border-slate-200"
        >
            <li
                v-for="card in 3"
                :key="card"
                class="flex flex-grow animate-pulse flex-row items-center space-x-4 px-6 py-3"
            >
                <div class="h-10 w-6 rounded-2xl bg-slate-200"></div>
                <div class="h-8 w-8 rounded-full bg-slate-200"></div>
                <div class="flex-1 space-y-6 py-1">
                    <div class="h-2 rounded bg-slate-200"></div>

                    <div class="grid grid-cols-3 gap-4">
                        <div class="col-span-2 h-2 rounded bg-slate-200"></div>
                        <div class="col-span-1 h-2 rounded bg-slate-200"></div>
                    </div>
                </div>
                <div class="h-10 w-6 rounded-2xl bg-slate-200"></div>
            </li>
        </ul>
        <div
            v-else-if="isMissingData"
            class="flex flex-col items-center border-t p-4 text-center"
        >
            <Icon
                :icon="IconEnums.DOCUMENT"
                class="mb-2 h-8 w-8 text-slate-300"
            />
            <p class="mb-1 font-bold text-slate-500">
                {{ $t("statistics.missing_data.title") }}
            </p>
            <p class="text-slate-400">
                {{ $t("statistics.missing_data.text") }}
            </p>
        </div>
        <ul
            v-else
            role="list"
            class="divide-y divide-slate-200 border-t border-slate-200"
        >
            <li
                class="flex items-center justify-between px-6 py-3"
                v-for="(user, index) in top"
                :key="user.rank"
            >
                <div class="flex flex-grow flex-row items-center">
                    <p class="mr-4 text-xl font-bold text-slate-700">
                        #{{ index + 1 }}
                    </p>
                    <div class="flex flex-row items-center">
                        <Avatar :name="user.full_name" :image="user.avatar" />
                    </div>
                    <div class="ml-3">
                        <p class="text-sm font-medium text-slate-500">
                            {{ user.full_name }}
                        </p>
                    </div>
                </div>
                <div class="flex flex-row items-center">
                    <svg
                        v-if="index === 0"
                        class="mr-2 h-9 w-9 rotate-12"
                        viewBox="0 0 266 266"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g fill="none" fill-rule="evenodd">
                            <path
                                d="m214.665 219.981-7.154 18.793a18.433 18.433 0 0 1-20.16 11.635l-19.845-3.178a9.284 9.284 0 0 0-7.284 1.951l-15.59 12.677a18.41 18.41 0 0 1-23.263 0l-15.59-12.677a9.284 9.284 0 0 0-7.303-1.951l-19.845 3.178a18.43 18.43 0 0 1-20.16-11.635l-7.154-18.793a9.299 9.299 0 0 0-5.314-5.335l-18.786-7.155a18.42 18.42 0 0 1-11.632-20.169l3.177-19.851a9.296 9.296 0 0 0-1.858-7.287L4.14 144.59a18.426 18.426 0 0 1 0-23.271l12.672-15.596a9.298 9.298 0 0 0 1.859-7.286l-3.085-19.852a18.426 18.426 0 0 1 11.632-20.167l18.786-7.175a9.142 9.142 0 0 0 5.314-5.316l7.172-18.793a18.409 18.409 0 0 1 20.16-11.636l19.827 3.18a9.288 9.288 0 0 0 7.284-1.86L121.35 4.14a18.412 18.412 0 0 1 23.264 0l15.59 12.677a9.29 9.29 0 0 0 7.283 1.86l19.845-3.18a18.408 18.408 0 0 1 20.16 11.636l7.155 18.793a9.286 9.286 0 0 0 5.332 5.316l18.786 7.175a18.434 18.434 0 0 1 11.631 20.167l-3.158 19.833a9.296 9.296 0 0 0 1.95 7.287l12.674 15.594a18.425 18.425 0 0 1 0 23.273l-12.673 15.595a9.296 9.296 0 0 0-1.95 7.287l3.177 19.85a18.447 18.447 0 0 1-2.232 12.154 18.43 18.43 0 0 1-9.4 8.014l-18.786 7.157a9.281 9.281 0 0 0-5.333 5.353Z"
                                fill="#FFC800"
                                fill-rule="nonzero"
                            />
                            <g fill="#FFF">
                                <path
                                    d="M54.993 132.565c.162-9.667-.021-19.726.743-29.346.885-11.13 3.89-21.86 14.432-27.88 10.543-6.02 21.442-3.23 31.63 1.579 8.804 4.154 17.52 9.341 26.067 14.036l.304.167.297.176a728.924 728.924 0 0 0 6.083 3.559c-6.507 3.776-13.123 7.593-19.241 11.75a76.722 76.722 0 0 0-2.831 2.016c-3.395-1.88-6.788-3.768-10.188-5.639-3.427-1.88-6.9-3.778-10.437-5.447-2.051-.967-7.843-3.72-10.134-2.413-2.29 1.308-2.775 7.645-2.953 9.886-.307 3.866-.382 7.788-.449 11.664-.093 5.423-.135 10.847-.226 16.203.09 5.423.133 10.847.226 16.27.067 3.876.142 7.799.449 11.664.178 2.24.663 8.578 2.953 9.886 2.291 1.308 8.083-1.445 10.134-2.414 3.537-1.669 7.01-3.567 10.437-5.447 4.795-2.632 3.33-2.038 8.065-4.64 3.083-1.827 14.16-8.255 22.073-12.888 4.16-2.436 7.17-4.168 8.284-4.925 1.872-1.272 7.179-4.857 7.179-7.473 0-2.617-5.307-6.201-7.18-7.474-2.005-1.362-4.074-2.863-6.16-4.128-2.087-1.266 20.454-12.317 21.843-13.087 8.018 5.992 14.596 13.897 14.596 24.689 0 12.04-7.894 19.98-17.198 26.301-8.04 5.465-16.94 10.337-25.324 15.31l-.297.176-.304.167c-8.546 4.696-17.263 9.882-26.068 14.037-10.188 4.807-21.086 7.599-31.629 1.579-10.542-6.02-13.548-16.75-14.432-27.881-.764-9.619-.581-19.679-.743-29.346l-.005-.344.004-.343Z"
                                />
                                <path
                                    d="M211.983 133.565c-.163-9.667.022-19.726-.743-29.346-.884-11.13-3.89-21.86-14.433-27.88-10.541-6.02-21.44-3.23-31.628 1.579-8.805 4.154-17.522 9.341-26.068 14.036l-.304.167-.297.176c-8.385 4.972-17.284 9.846-25.324 15.31-9.304 6.322-17.197 14.262-17.197 26.302 0 10.792 6.346 18.292 14.365 24.287 1.373-.761 2.745-1.518 4.111-2.27 4.704-2.79 9.432-5.536 14.134-8.328a467.19 467.19 0 0 0 3.828-2.29c-2.086-1.267-4.155-2.564-6.16-3.927-1.873-1.272-7.18-4.857-7.18-7.473 0-2.617 5.307-6.201 7.18-7.474 3.23-2.196 6.628-4.22 9.988-6.215 4.701-2.792 9.43-5.54 14.074-8.295 4.793-2.634 9.564-5.31 14.36-7.942 3.426-1.88 6.898-3.778 10.436-5.447 2.051-.967 7.843-3.72 10.133-2.413 2.29 1.308 2.776 7.645 2.954 9.886.306 3.866.381 7.788.448 11.665.094 5.423.136 10.847.227 16.202-.091 5.423-.133 10.847-.227 16.27-.067 3.876-.142 7.8-.448 11.664-.179 2.24-.663 8.578-2.954 9.886-2.29 1.308-8.083-1.445-10.133-2.414-3.538-1.668-7.01-3.566-10.437-5.447-3.41-1.874-6.808-3.766-10.188-5.638-.924.69-1.87 1.361-2.831 2.014-6.118 4.159-12.734 7.974-19.241 11.752a753.876 753.876 0 0 1 6.083 3.558l.297.176.304.167c8.546 4.696 17.262 9.882 26.068 14.037 10.187 4.807 21.086 7.599 31.628 1.579 10.543-6.02 13.549-16.75 14.433-27.881.764-9.619.581-19.679.742-29.346l.006-.344-.006-.343Z"
                                />
                            </g>
                        </g>
                    </svg>
                    <p class="text-xl font-bold">
                        {{ user.point_count }}
                    </p>
                </div>
            </li>
        </ul>
        <ul
            v-if="!loading && !isInTopFive"
            role="list"
            class="divide-y divide-slate-200 border-t border-slate-200"
        >
            <li
                class="leader-board-you z-10 -my-0.5 flex items-center justify-between border bg-slate-50 px-6 py-3"
            >
                <div class="flex flex-grow flex-row items-center">
                    <p class="mr-4 text-xl font-bold text-slate-700">
                        #{{ rank }}
                    </p>
                    <div class="flex flex-row items-center">
                        <Avatar :name="name" :image="avatar" />
                    </div>
                    <div class="ml-3">
                        <p class="text-sm font-bold text-slate-900">
                            {{ name }}
                        </p>
                    </div>
                </div>
                <AnimatedNumber
                    class="text-xl font-bold"
                    :value="points"
                    :decimals="0"
                />
            </li>
        </ul>
        <div
            v-if="!isInTopFive"
            class="flex items-center justify-center -space-x-2 px-6 py-3 opacity-50"
        >
            <Avatar
                v-for="letter in fakeAvatars"
                :key="letter"
                :name="letter"
            />
        </div>
    </div>
</template>

<script>
import { StatisticsStoreNamespacedTypes } from "@/store/modules/statistics";
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";

import { formatDateTime } from "@/utils/date";
import { getProperty } from "@/utils/object";

import Alert from "@/components/ui/Alert";
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";
import Avatar from "@/components/ui/Avatar";
import AnimatedNumber from "@/components/ui/AnimatedNumber";

export default {
    name: "LeaderBoard",
    components: { AnimatedNumber, Alert, Icon, Avatar },
    data: () => ({
        IconEnums,
        fakeAvatars: ["a", "c", "u", "s", "r", "p"],
    }),
    filters: {
        formatDateTime,
    },
    computed: {
        /**
         * The request loading state
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                StatisticsStoreNamespacedTypes.getters.LEADERBOARD_LOADING
            ]();
        },

        /**
         * The request error state
         * @type {ErrorOrObject}
         */
        error() {
            return this.$store.getters[
                StatisticsStoreNamespacedTypes.getters.LEADERBOARD_ERROR
            ]();
        },

        /**
         * The leaderboard data
         * @type {Object}
         */
        leaderboard() {
            return this.$store.getters[
                StatisticsStoreNamespacedTypes.getters.LEADERBOARD
            ]();
        },

        top() {
            return getProperty(this.leaderboard, "top", []);
        },

        /**
         * The currently authenticated user
         * @type {Object}
         */
        user() {
            return this.$store.getters[AuthStoreNamespacedTypes.getters.USER]();
        },
        avatar() {
            return getProperty(this.user, "avatar");
        },
        name() {
            return getProperty(this.user, "first_name", null);
        },
        points() {
            return getProperty(this.user, "point_count", 0);
        },
        rank() {
            return getProperty(this.leaderboard, "rank", 0);
        },
        isInTopFive() {
            return this.rank <= 5;
        },
        isMissingData() {
            return this.points < 20;
        },
    },
    mounted() {
        this.$store.dispatch(
            StatisticsStoreNamespacedTypes.actions.GET_LEADERBOARD
        );
    },
};
</script>
