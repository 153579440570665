/**
 * A store for handling statistics
 *
 * @typedef {{ statistics: Object|null, statisticsLoading: boolean, statisticsError: ErrorOrObject, leaderboard: Object|null, leaderboardLoading: boolean, leaderboardError: ErrorOrObject }} StatisticsStoreState
 */
import { addNamespace } from "./namespace";
import { getData } from "@/api";

/**
 * The types used in this store
 * @enum {string}
 */
export const StatisticsStoreTypes = {
    getters: {
        STATISTICS: "statistics",
        STATISTICS_LOADING: "statisticsLoading",
        STATISTICS_ERROR: "statisticsError",
        LEADERBOARD: "leaderboard",
        LEADERBOARD_LOADING: "leaderboardLoading",
        LEADERBOARD_ERROR: "leaderboardError",
    },
    actions: {
        GET_STATISTICS: "getStatistics",
        GET_LEADERBOARD: "getLeaderboard",
    },
    mutations: {
        SET_STATISTICS: "setStatistics",
        SET_STATISTICS_LOADING: "setStatisticsLoading",
        SET_STATISTICS_ERROR: "setStatisticsError",
        SET_LEADERBOARD: "setLeaderboard",
        SET_LEADERBOARD_LOADING: "setLeaderboardLoading",
        SET_LEADERBOARD_ERROR: "setLeaderboardError",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const StatisticsStoreNamespacedTypes = addNamespace(
    "statistics",
    StatisticsStoreTypes
);

/**
 * @returns {StatisticsStoreState}
 */
export function state() {
    return {
        statistics: {
            breaches: {
                total: 0,
                groups: [],
            },
            quiz: {
                total: 0,
                groups: [],
            },
        },
        statisticsLoading: false,
        statisticsError: null,
        leaderboard: {
            rank: 0,
            employees: [],
        },
        leaderboardLoading: false,
        leaderboardError: null,
    };
}

export const getters = {
    [StatisticsStoreTypes.getters.STATISTICS]: (state) => () => {
        return state.statistics;
    },
    [StatisticsStoreTypes.getters.STATISTICS_LOADING]: (state) => () => {
        return state.statisticsLoading;
    },
    [StatisticsStoreTypes.getters.STATISTICS_ERROR]: (state) => () => {
        return state.statisticsError;
    },
    [StatisticsStoreTypes.getters.LEADERBOARD]: (state) => () => {
        return state.leaderboard;
    },
    [StatisticsStoreTypes.getters.LEADERBOARD_LOADING]: (state) => () => {
        return state.leaderboardLoading;
    },
    [StatisticsStoreTypes.getters.LEADERBOARD_ERROR]: (state) => () => {
        return state.leaderboardError;
    },
};

export const actions = {
    /**
     * Sends the given credentials to the server and updates the store with the returned statistics data.
     *
     * @param {VuexCommit} commit
     * @return {Promise}
     */
    [StatisticsStoreTypes.actions.GET_STATISTICS]({ commit }) {
        commit(StatisticsStoreTypes.mutations.SET_STATISTICS_LOADING, true);
        commit(StatisticsStoreTypes.mutations.SET_STATISTICS_ERROR, null);

        return getData("/statistics")
            .then((response) => {
                commit(
                    StatisticsStoreTypes.mutations.SET_STATISTICS,
                    response.data
                );
            })
            .catch((errors) => {
                commit(
                    StatisticsStoreTypes.mutations.SET_STATISTICS_ERROR,
                    errors
                );
            })
            .finally(() => {
                commit(
                    StatisticsStoreTypes.mutations.SET_STATISTICS_LOADING,
                    false
                );
            });
    },

    /**
     * Sends the given credentials to the server and updates the store with the returned statistics data.
     *
     * @param {VuexCommit} commit
     * @return {Promise}
     */
    [StatisticsStoreTypes.actions.GET_LEADERBOARD]({ commit }) {
        commit(StatisticsStoreTypes.mutations.SET_LEADERBOARD_LOADING, true);
        commit(StatisticsStoreTypes.mutations.SET_LEADERBOARD_ERROR, null);

        return getData("/leaderboard")
            .then((response) => {
                commit(
                    StatisticsStoreTypes.mutations.SET_LEADERBOARD,
                    response.data
                );
            })
            .catch((errors) => {
                commit(
                    StatisticsStoreTypes.mutations.SET_LEADERBOARD_ERROR,
                    errors
                );
            })
            .finally(() => {
                commit(
                    StatisticsStoreTypes.mutations.SET_LEADERBOARD_LOADING,
                    false
                );
            });
    },
};

export const mutations = {
    /**
     * Sets user statistics.
     *
     * @param {StatisticsStoreState} state
     * @param {Object} statistics
     */
    [StatisticsStoreTypes.mutations.SET_STATISTICS](state, statistics) {
        state.statistics = statistics;
    },

    /**
     * Sets the loading state for the statistics form.
     *
     * @param {StatisticsStoreState} state
     * @param {boolean} loading
     */
    [StatisticsStoreTypes.mutations.SET_STATISTICS_LOADING](state, loading) {
        state.statisticsLoading = loading;
    },

    /**
     * Sets a new statistics error.
     *
     * @param {StatisticsStoreState} state
     * @param {ErrorOrObject} error
     */
    [StatisticsStoreTypes.mutations.SET_STATISTICS_ERROR](state, error) {
        state.statisticsError = error;
    },

    /**
     * Set leaderboard.
     *
     * @param {StatisticsStoreState} state
     * @param {Object} leaderboard
     */
    [StatisticsStoreTypes.mutations.SET_LEADERBOARD](state, leaderboard) {
        state.leaderboard = leaderboard;
    },

    /**
     * Sets the loading state for the leaderboard.
     *
     * @param {StatisticsStoreState} state
     * @param {boolean} loading
     */
    [StatisticsStoreTypes.mutations.SET_LEADERBOARD_LOADING](state, loading) {
        state.leaderboardLoading = loading;
    },

    /**
     * Sets a new leaderboard error.
     *
     * @param {StatisticsStoreState} state
     * @param {ErrorOrObject} error
     */
    [StatisticsStoreTypes.mutations.SET_LEADERBOARD_ERROR](state, error) {
        state.leaderboardError = error;
    },
};

export default {
    namespaced: true,
    Types: StatisticsStoreTypes,
    NamespacedTypes: StatisticsStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
