/**
 * A store for handling mandatory training
 *
 * @typedef {{ mandatory: Object, mandatoryLoading: boolean, mandatoryError: ErrorOrObject}} MandatoryStoreState
 */
import { addNamespace } from "./namespace";
import { postData } from "@/api";
import { getProperty } from "@/utils/object";
import { getLocale } from "@/lang";

/**
 * The types used in this store
 * @enum {string}
 */
export const MandatoryStoreTypes = {
    getters: {
        MANDATORY: "mandatory",
        MANDATORY_LOADING: "mandatoryLoading",
        MANDATORY_ERROR: "mandatoryError",
    },
    actions: {
        GET_MANDATORY: "getMandatory",
    },
    mutations: {
        SET_MANDATORY: "setMandatory",
        SET_MANDATORY_LOADING: "setMandatoryLoading",
        SET_MANDATORY_ERROR: "setMandatoryError",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const MandatoryStoreNamespacedTypes = addNamespace(
    "mandatory",
    MandatoryStoreTypes
);

/**
 * @returns {MandatoryStoreState}
 */
export function state() {
    return {
        mandatory: {
            courses: [],
            videos: [],
        },
        mandatoryLoading: false,
        mandatoryError: null,
    };
}

export const getters = {
    [MandatoryStoreTypes.getters.MANDATORY]: (state) => () => {
        return state.mandatory;
    },
    [MandatoryStoreTypes.getters.MANDATORY_LOADING]: (state) => () => {
        return state.mandatoryLoading;
    },
    [MandatoryStoreTypes.getters.MANDATORY_ERROR]: (state) => () => {
        return state.mandatoryError;
    },
};

export const actions = {
    /**
     * Sends the given credentials to the server and updates the store with the returned mandatory data.
     *
     * @param {VuexCommit} commit
     * @return {Promise}
     */
    [MandatoryStoreTypes.actions.GET_MANDATORY]({ commit }) {
        commit(MandatoryStoreTypes.mutations.SET_MANDATORY_LOADING, true);
        commit(MandatoryStoreTypes.mutations.SET_MANDATORY_ERROR, null);

        return postData("/mandatory/data", { locale: getLocale() })
            .then((response) => {
                commit(MandatoryStoreTypes.mutations.SET_MANDATORY, {
                    courses: getProperty(response, "data.courses", []),
                    videos: getProperty(response, "data.videos", []),
                });
            })
            .catch((errors) => {
                commit(
                    MandatoryStoreTypes.mutations.SET_MANDATORY_ERROR,
                    errors
                );
            })
            .finally(() => {
                commit(
                    MandatoryStoreTypes.mutations.SET_MANDATORY_LOADING,
                    false
                );
            });
    },
};

export const mutations = {
    /**
     * Sets user mandatory.
     *
     * @param {MandatoryStoreState} state
     * @param {Object} data
     */
    [MandatoryStoreTypes.mutations.SET_MANDATORY](state, { courses, videos }) {
        state.mandatory.courses = courses;
        state.mandatory.videos = videos;
    },

    /**
     * Sets the loading state for the mandatory form.
     *
     * @param {MandatoryStoreState} state
     * @param {boolean} loading
     */
    [MandatoryStoreTypes.mutations.SET_MANDATORY_LOADING](state, loading) {
        state.mandatoryLoading = loading;
    },

    /**
     * Sets a new mandatory error.
     *
     * @param {MandatoryStoreState} state
     * @param {ErrorOrObject} error
     */
    [MandatoryStoreTypes.mutations.SET_MANDATORY_ERROR](state, error) {
        state.mandatoryError = error;
    },
};

export default {
    namespaced: true,
    Types: MandatoryStoreTypes,
    NamespacedTypes: MandatoryStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
