<template>
    <div class="flex flex-col rounded-lg border p-4">
        <div class="relative mb-4">
            <div
                class="flex h-2.5 w-full overflow-hidden rounded-full bg-slate-200"
            >
                <div
                    class="flex w-full flex-col justify-center overflow-hidden whitespace-nowrap bg-gradient-to-r from-red-500 via-yellow-400 to-teal-400 text-center text-xs text-white"
                    role="progressbar"
                    :aria-valuenow="value"
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
            </div>
            <div
                :style="{ left: `calc(${formatedValue}% - 0.5rem)` }"
                :class="[
                    value < 25
                        ? 'bg-red-500'
                        : value < 50
                        ? 'bg-orange-500'
                        : value < 75
                        ? 'bg-yellow-400'
                        : 'bg-teal-400',
                    'absolute top-1/2 h-5 w-2 -translate-y-1/2 transform rounded-full border-2 border-white transition-all duration-300 ease-in-out',
                ]"
            ></div>
        </div>
        <h3
            class="text-sm font-medium leading-tight text-slate-900"
            v-text="title"
        ></h3>
        <p class="mt-1 text-xs text-slate-600" v-text="text"></p>
    </div>
</template>
<script>
export default {
    name: "ScoreCard",
    props: {
        title: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        value: {
            type: Number,
            required: true,
        },
    },
    computed: {
        formatedValue() {
            if (this.value === 0) return 2;

            return this.value;
        },
    },
};
</script>
