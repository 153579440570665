<template>
    <div
        class="absolute bottom-0 left-0 right-0 top-0 z-50 flex flex-col items-center justify-center overflow-hidden"
    >
        <div class="flex flex-row items-center justify-center">
            <LogoSvg class="size-12" />
            <div class="mx-6 rounded-full bg-white p-2 shadow-xl">
                <Icon
                    :icon="IconEnums.LOADING"
                    class="size-6 animate-spin text-slate-900"
                />
            </div>
            <Icon :icon="IconEnums.MICROSOFT" class="size-10" />
        </div>
        <p class="mt-10 font-medium text-slate-700">
            {{ $t("sso.redirect_to") }}
            <span class="capitalize">{{ provider }}</span
            ><span v-for="x in 3" :key="x" class="load_dot">.</span>
        </p>
    </div>
</template>

<script>
import { SsoStoreNamespacedTypes } from "@/store/modules/sso";

import { getProperty } from "@/utils/object";
import { redirectTo } from "@/router";
import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";
import LogoSvg from "@/components/ui/LogoSvg";

export default {
    name: "OnboardingPage",
    components: { LogoSvg, Icon },
    data: () => ({
        IconEnums,
    }),
    computed: {
        provider() {
            return getProperty(this.$route.params, "provider", null);
        },
    },
    methods: {
        /**
         * Request the SSO redirect URL and redirect to it
         * @return {void}
         */
        redirectToProvider() {
            if (this.provider) {
                this.$store
                    .dispatch(
                        SsoStoreNamespacedTypes.actions.GET_GRANT,
                        this.provider
                    )
                    .then((response) => {
                        if (!this.error) {
                            const url = getProperty(response, "data.url", null);
                            if (url) {
                                setTimeout(() => {
                                    window.location.replace(url);
                                }, 2000);
                            } else {
                                redirectTo("/");
                            }
                        }
                    });
            } else {
                redirectTo("/");
            }
        },
    },
    created() {
        this.redirectToProvider();
    },
};
</script>
