<template>
    <li
        class="flex cursor-pointer select-none p-3 hover:bg-slate-800"
        role="option"
        tabindex="-1"
    >
        <img
            :src="image"
            class="flex h-10 w-auto flex-none overflow-hidden rounded-lg"
            alt="media"
        />
        <div class="ml-4 flex-auto">
            <p class="text-sm font-medium text-white">
                {{ title }}
            </p>
            <p class="text-sm text-slate-400">
                {{ categoryTitle }}
            </p>
        </div>
    </li>
</template>

<script>
import { getProperty } from "@/utils/object";
import { camelToSnake } from "@/utils/string";

export default {
    name: "VideoItem",
    props: {
        video: {
            type: Object,
            required: true,
        },
    },
    computed: {
        image() {
            let img = getProperty(this.video, "image", null);
            return img ? img : "/img/placeholder.jpg";
        },
        title() {
            return getProperty(this.video, "title", null);
        },
        category() {
            return getProperty(this.video, "category", null);
        },
        categoryTitle() {
            return this.category
                ? this.$t(
                      "videos.category." +
                          camelToSnake(this.category) +
                          ".title"
                  )
                : this.$t("videos.loading");
        },
    },
};
</script>
