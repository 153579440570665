<template>
    <div
        class="relative z-40"
        aria-labelledby="slide-over-title"
        role="dialog"
        aria-modal="true"
        v-if="active"
    >
        <div
            :class="showPrompt ? 'opacity-100' : 'opacity-0'"
            class="fixed inset-0 bg-slate-500 bg-opacity-75 transition-opacity duration-200 ease-in-out sm:duration-300"
        ></div>
        <div class="fixed inset-0 overflow-hidden">
            <div class="absolute inset-0 overflow-hidden">
                <div
                    class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full"
                >
                    <div
                        :class="[
                            { 'translate-x-0': showPrompt },
                            { 'translate-x-full': !showPrompt },
                        ]"
                        class="pointer-events-auto w-screen max-w-[625px] transform p-2 transition duration-200 ease-in-out sm:duration-300 md:p-4"
                    >
                        <div
                            class="flex h-full flex-col rounded-2xl bg-slate-100 shadow-xl"
                        >
                            <div
                                class="flex items-center justify-between rounded-t-2xl px-4 pt-4"
                            >
                                <slot name="title" />
                                <div
                                    class="ml-3 inline-flex cursor-pointer items-center justify-center rounded-full bg-white p-1.5 text-slate-500 hover:bg-slate-200 hover:text-slate-800 focus:outline-none sm:text-sm"
                                    @click="close"
                                >
                                    <Icon
                                        :icon="IconEnums.CLOSE"
                                        class="size-5"
                                    />
                                </div>
                            </div>
                            <div class="relative flex-1 overflow-y-auto p-4">
                                <slot name="content" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { IconEnums } from "@/utils/icons";
import Icon from "@/components/icons/BaseIcon";

export default {
    name: "SlideOver",
    components: { Icon },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        IconEnums,
        showPrompt: false,
    }),
    watch: {
        active(newValue) {
            setTimeout(() => {
                this.showPrompt = newValue;
            }, 200);
        },
    },
    methods: {
        close() {
            this.showPrompt = false;
            setTimeout(() => {
                this.$emit("close");
            }, 200);
        },
    },
    mounted() {
        document.addEventListener("keydown", (e) => {
            if (e.keyCode === 27) {
                this.close();
            }
        });
    },
};
</script>
