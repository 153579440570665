<template>
    <svg
        v-if="isValidIcon"
        :class="['icon', iconClassName]"
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        width="1em"
        :viewBox="`0 0 ${viewBoxHeight} ${viewBoxWidth}`"
    >
        <title v-if="iconTitle">{{ iconTitle }}</title>
        <component ref="icon" :is="`Icon${this.iconName}`" />
    </svg>
</template>

<script>
import { IconEnums, IconComponents } from "@/utils/icons";

import { camelToKebab } from "@/utils/string";

export default {
    name: "BaseIcon",
    components: IconComponents,
    props: {
        icon: {
            type: Object,
            required: true,
            validator(icon) {
                return Object.values(IconEnums).includes(icon);
            },
        },
        title: {
            type: String,
            required: false,
        },
    },
    data: () => ({
        viewBoxHeight: 0,
        viewBoxWidth: 0,
    }),
    mounted() {
        this.setViewBoxDimensions();
    },
    updated() {
        this.setViewBoxDimensions();
    },
    computed: {
        iconName() {
            return this.icon["name"];
        },
        iconClassName() {
            return camelToKebab(this.icon["name"]);
        },
        iconTitle() {
            if (this.title !== undefined) {
                return this.title;
            }
            return this.$t(this.icon["title"]);
        },
        isValidIcon() {
            return IconEnums[this.icon.name.toUpperCase()] === this.icon;
        },
    },
    methods: {
        setViewBoxDimensions() {
            if (this.isValidIcon && this.$refs.icon) {
                this.viewBoxWidth = this.$refs.icon.getViewBoxWidth();
                this.viewBoxHeight = this.$refs.icon.getViewBoxHeight();
            }
        },
    },
};
</script>
