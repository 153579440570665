<script>
export default {
    name: "IconSvgBase",
    data: () => ({
        viewBoxHeight: 20,
        viewBoxWidth: 20,
    }),
    methods: {
        getViewBoxHeight() {
            return this.viewBoxHeight;
        },
        getViewBoxWidth() {
            return this.viewBoxWidth;
        },
    },
};
</script>
