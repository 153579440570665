<template>
    <svg :height="radius * 2" :width="radius * 2">
        <circle
            class="progress-circle"
            stroke="currentColor"
            :stroke-dasharray="circumference + ' ' + circumference"
            :style="{ strokeDashoffset: strokeDashoffset }"
            :stroke-width="stroke"
            fill="transparent"
            :r="normalizedRadius"
            :cx="radius"
            :cy="radius"
        />
    </svg>
</template>

<script>
export default {
    name: "ProgressRing",
    props: {
        radius: {
            type: Number,
            required: true,
        },
        progress: {
            type: Number,
            required: true,
        },
        stroke: {
            type: Number,
            required: true,
        },
    },
    computed: {
        normalizedRadius() {
            return this.radius - this.stroke * 2;
        },
        circumference() {
            return 2 * Math.PI * this.normalizedRadius;
        },
        strokeDashoffset() {
            return (
                this.circumference - (this.progress / 100) * this.circumference
            );
        },
    },
};
</script>
