import "./index.css";

import Vue from "vue";

/**
 * Event bus
 */
window.Bus = new Vue();

/**
 * Vuex
 */
import store from "./store";

/**
 * Routing
 */
import Router from "./router";

/**
 * Translation
 */
import i18n from "./lang";

/**
 * Validation
 */
import "./validation";

/**
 * Run app
 */
const App = require("./App").default;

new Vue({
    router: Router,
    i18n,
    store,
    render: (h) => h(App),
}).$mount("#app");
