<template>
    <div class="mb-6">
        <div class="mb-4 flex justify-between">
            <p class="heading">{{ $t("videos.latest") }}</p>
            <router-link
                class="font-medium text-blue-500"
                :to="{ name: 'videos.index' }"
                >{{ $t("videos.more") }}</router-link
            >
        </div>
        <CardListLoader :count="3" :loading="loading" :error="error">
            <VideoCard
                v-for="video in videos"
                :key="video.id"
                :video="video"
                :category="video.category"
                referrer="overview"
                placement="featured"
            />
        </CardListLoader>
    </div>
</template>

<script>
import { VideoStoreNamespacedTypes } from "@/store/modules/video";
import { LocaleStoreNamespacedTypes } from "@/store/modules/locale";

import VideoCard from "@/components/video/VideoCard";
import CardListLoader from "@/components/ui/CardListLoader";

export default {
    name: "FeaturedVideos",
    components: { CardListLoader, VideoCard },
    computed: {
        /**
         * The request loading state
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                VideoStoreNamespacedTypes.getters.VIDEOS_LOADING
            ]();
        },
        /**
         * The request error state
         * @type {ErrorOrObject}
         */
        error() {
            return this.$store.getters[
                VideoStoreNamespacedTypes.getters.VIDEOS_ERROR
            ]();
        },

        /**
         * Featured videos.
         * @type {Array}
         */
        videos() {
            return this.$store.getters[
                VideoStoreNamespacedTypes.getters.FEATURED_VIDEOS
            ]();
        },

        /**
         * Current locale.
         * @type {String}
         */
        locale() {
            return this.$store.getters[
                LocaleStoreNamespacedTypes.getters.LOCALE
            ]();
        },
    },
    watch: {
        locale() {
            this.$store.dispatch(
                VideoStoreNamespacedTypes.actions.GET_FEATURED_VIDEOS
            );
        },
    },
    beforeCreate() {
        this.$store.commit(
            VideoStoreNamespacedTypes.mutations.SET_VIDEO_LOADING,
            true
        );
    },
    mounted() {
        this.$store.dispatch(
            VideoStoreNamespacedTypes.actions.GET_FEATURED_VIDEOS
        );
    },
};
</script>
