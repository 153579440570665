<template>
    <div class="bell" :class="classname">
        <span>1</span>
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 36 36"
                id="bellIcon"
            >
                <path
                    d="M14.8912285,3.36057461 C14.9674355,1.49221129 16.5685194,0 18.5324757,0 C20.5450486,0 22.1765613,1.56700338 22.1765613,3.5 C22.1765613,3.55027291 22.1754577,3.60029826 22.1732726,3.65005492 C26.4675924,5.03249294 29.8240194,8.49693961 31.0467487,12.8506974 C31.1765685,13.1427691 31.2847107,13.4424665 31.3686763,13.7481464 C31.3686763,13.7481464 31.9509191,14.0595001 32.6903714,21.174592 C33.4298236,28.2896839 35.4436204,30.6656208 35.4436204,30.6656208 C36.9482129,33.6117171 35.4810869,36 32.1658325,36 L3.93337601,36 C0.618511253,36 -0.876188328,33.5979375 0.455902979,30.5593834 C0.455902979,30.5593834 2.1835454,28.1534321 3.23050709,21.1745923 C4.32693018,13.8660525 4.70649524,13.7615043 4.70649524,13.7615043 C4.75251339,13.5998958 4.80504059,13.4398413 4.86372981,13.2815999 C6.06669276,8.37583074 9.9576172,4.52182268 14.8912285,3.36057461 Z"
                ></path>
            </svg>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 12 6"
                id="ballIcon"
            >
                <path
                    d="M-1.0658141e-14,0 L12,0 C11.8355615,3.3428549 9.21215441,6 6,6 C2.78784559,6 0.164438452,3.3428549 -8.89739671e-15,1.72778458e-14 Z"
                ></path>
            </svg>
            <span></span>
        </div>
    </div>
</template>

<script>
export default {
    name: "EmailAnimation",
    data: () => ({
        classname: null,
    }),
    mounted() {
        setTimeout(() => {
            this.classname = "notification";
        }, 1000);
    },
};
</script>

<style scoped lang="scss">
$background: #fff;
$active: #5628ee;
$activeDot: #ff9e58;
$inactive: #99a3ba;

.bell {
    margin: 0 auto;
    display: table;
    cursor: pointer;
    position: relative;
    outline: 0;
    & > span {
        top: -2px;
        right: 5px;
        width: 20px;
        height: 20px;
        line-height: 16px;
        border-radius: 50%;
        font-size: 9px;
        font-weight: bold;
        color: #fff;
        z-index: 1;
        opacity: 0;
        transform: scale(0);
        transition: all 0.15s ease;
        text-align: center;
        display: block;
        position: absolute;
        background: $activeDot;
        border: 2px solid $background;
    }
    & > div {
        margin-bottom: 9px;
        transform-origin: 50% 0;
        position: relative;
        & > span {
            width: 17px;
            height: 4px;
            border-radius: 2px;
            right: 110%;
            top: 90%;
            transform-origin: 100% 50%;
            transform: scaleY(0.9) scaleX(0);
            display: block;
            position: absolute;
            background: $active;
            &:before,
            &:after {
                content: "";
                left: 5px;
                width: 12px;
                height: 4px;
                border-radius: 2px;
                display: block;
                position: absolute;
                background: $active;
            }
            &:before {
                top: -8px;
                transform: scaleY(0.8) rotate(24deg);
            }
            &:after {
                bottom: -8px;
                transform: scaleY(0.8) rotate(-24deg);
            }
        }
        svg {
            transition: fill 0.3s ease;
            display: block;
            fill: $inactive;
            &:nth-child(1) {
                width: 36px;
                height: 36px;
            }
            &:nth-child(2) {
                width: 12px;
                height: 6px;
                top: 100%;
                left: 50%;
                margin-left: -6px;
                margin-top: 2px;
                position: absolute;
            }
        }
    }
    &.notification {
        & > span {
            opacity: 1;
            transform: scale(1);
            transition: all 0.25s ease 0.25s;
        }
        & > div {
            animation: tilt 0.25s linear forwards;
            & > span {
                transform: scaleY(0.9) scaleX(1);
                transition: transform 0.07s ease 0.3s;
                animation: removeLines 0.05s linear forwards 0.37s;
                &:before,
                &:after {
                    animation: removeLinesP 0.05s linear forwards 0.37s;
                }
            }
            svg {
                fill: $active;
                &:nth-child(2) {
                    animation: ballMove 0.45s linear forwards;
                }
            }
        }
        &.default {
            & > span {
                opacity: 0;
                transform: scale(0);
                transition: all 0.15s ease;
            }
            & > div {
                animation: tiltRemove 0.25s linear forwards;
                svg {
                    fill: $inactive;
                    &:nth-child(2) {
                        animation: ballMoveRemove 0.25s linear forwards;
                    }
                }
            }
        }
    }
}

@keyframes tilt {
    0% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(40deg);
    }
    100% {
        transform: rotate(20deg);
    }
}

@keyframes tiltRemove {
    0% {
        transform: rotate(20deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes ballMove {
    0% {
        transform: translateX(0);
    }
    15% {
        transform: translateX(6px);
    }
    50% {
        transform: translateX(-6px);
    }
    100% {
        transform: translateX(6px);
    }
}

@keyframes ballMoveRemove {
    0% {
        transform: translateX(6px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes removeLines {
    0% {
        width: 17px;
        transform: scaleY(0.9) scaleX(1);
    }
    100% {
        width: 0;
        transform: scaleY(0.9) scaleX(1) translateX(-17px);
    }
}

@keyframes removeLinesP {
    0% {
        width: 12px;
    }
    100% {
        width: 0;
    }
}
</style>
