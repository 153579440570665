/**
 * A store breach.
 *
 * @typedef {{ breachLoading: boolean, breachError: ErrorOrObject, breaches: Object, lastUpdateTime: string|null }} BreachStoreState
 */
import { addNamespace } from "./namespace";
import { getData, postData } from "@/api";
import { isRecentEvent } from "@/utils/date";

/**
 * The types used in this store
 * @enum {string}
 */
export const BreachStoreTypes = {
    getters: {
        BREACH_LOADING: "breachLoading",
        BREACH_ERROR: "breachError",
        BREACHES: "breaches",
        LAST_UPDATE_TIME: "lastUpdateTime",
    },
    actions: {
        GET_BREACHES: "getBreaches",
        SAVE_EMAIL: "saveEmail",
        DELETE_EMAIL: "deleteEmail",
    },
    mutations: {
        SET_BREACH_LOADING: "setBreachLoading",
        SET_BREACH_ERROR: "setBreachError",
        SET_BREACHES: "setBreaches",
        SET_LAST_UPDATE_TIME: "setLastUpdateTime",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const BreachStoreNamespacedTypes = addNamespace(
    "breach",
    BreachStoreTypes
);

/**
 * @returns {BreachStoreState}
 */
export function state() {
    return {
        breachLoading: false,
        breachError: null,
        breaches: {
            records: [],
            emails: [],
            meta: {
                count: 0,
            },
        },
        lastUpdateTime: null,
    };
}

export const getters = {
    [BreachStoreTypes.getters.BREACH_LOADING]: (state) => () => {
        return state.breachLoading;
    },
    [BreachStoreTypes.getters.BREACH_ERROR]: (state) => () => {
        return state.breachError;
    },
    [BreachStoreTypes.getters.BREACHES]: (state) => () => {
        return state.breaches;
    },
    [BreachStoreTypes.getters.LAST_UPDATE_TIME]: (state) => () => {
        return state.lastUpdateTime;
    },
};

export const actions = {
    /**
     * Sends the given breach to the server.
     *
     * @param {BreachStoreState} state
     * @param {VuexCommit} commit
     * @return {Promise}
     */
    [BreachStoreTypes.actions.GET_BREACHES]({ state, commit }) {
        if (
            state.lastUpdateTime !== null &&
            isRecentEvent(state.lastUpdateTime)
        ) {
            return Promise.resolve();
        }

        commit(BreachStoreTypes.mutations.SET_BREACH_LOADING, true);
        commit(BreachStoreTypes.mutations.SET_BREACH_ERROR, null);

        return getData("/breaches")
            .then((response) => {
                commit(BreachStoreTypes.mutations.SET_BREACHES, response.data);
                commit(
                    BreachStoreTypes.mutations.SET_LAST_UPDATE_TIME,
                    new Date().getTime()
                );
            })
            .catch((errors) => {
                commit(BreachStoreTypes.mutations.SET_BREACH_ERROR, errors);
            })
            .finally(() => {
                commit(BreachStoreTypes.mutations.SET_BREACH_LOADING, false);
            });
    },

    /**
     * Sends the given breach to the server.
     *
     * @param {VuexCommit} commit
     * @param {string} email
     * @return {Promise}
     */
    [BreachStoreTypes.actions.SAVE_EMAIL]({ commit }, email) {
        commit(BreachStoreTypes.mutations.SET_BREACH_ERROR, null);

        return postData("/breaches/email", { email: email })
            .then((response) => {
                commit(BreachStoreTypes.mutations.SET_BREACHES, response.data);
                commit(
                    BreachStoreTypes.mutations.SET_LAST_UPDATE_TIME,
                    new Date().getTime()
                );
            })
            .catch((errors) => {
                commit(BreachStoreTypes.mutations.SET_BREACH_ERROR, errors);
            });
    },

    /**
     * Sends the given breach to the server.
     *
     * @param {VuexCommit} commit
     * @param {object} email
     * @return {Promise}
     */
    [BreachStoreTypes.actions.DELETE_EMAIL]({ commit }, email) {
        commit(BreachStoreTypes.mutations.SET_BREACH_ERROR, null);

        return postData("/breaches/email/delete", { email: email })
            .then((response) => {
                commit(BreachStoreTypes.mutations.SET_BREACHES, response.data);
                commit(
                    BreachStoreTypes.mutations.SET_LAST_UPDATE_TIME,
                    new Date().getTime()
                );
            })
            .catch((errors) => {
                commit(BreachStoreTypes.mutations.SET_BREACH_ERROR, errors);
            });
    },
};

export const mutations = {
    /**
     * Sets the loading state for breach.
     *
     * @param {BreachStoreState} state
     * @param {boolean} loading
     */
    [BreachStoreTypes.mutations.SET_BREACH_LOADING](state, loading) {
        state.breachLoading = loading;
    },

    /**
     * Sets a new breach error.
     *
     * @param {BreachStoreState} state
     * @param {ErrorOrObject} error
     */
    [BreachStoreTypes.mutations.SET_BREACH_ERROR](state, error) {
        state.breachError = error;
    },

    /**
     * Sets a new breach error.
     *
     * @param {BreachStoreState} state
     * @param {Object} data
     */
    [BreachStoreTypes.mutations.SET_BREACHES](state, data) {
        state.breaches = data;
    },

    /**
     * Sets a new breach error.
     *
     * @param {{lastUpdateTime: Date}} state
     * @param {string} lastUpdateTime
     */
    [BreachStoreTypes.mutations.SET_LAST_UPDATE_TIME](state, lastUpdateTime) {
        state.lastUpdateTime = new Date(lastUpdateTime);
    },
};

export default {
    namespaced: true,
    Types: BreachStoreTypes,
    NamespacedTypes: BreachStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
