/**
 * Amplitude mixin
 */
import { AmplitudeStoreNamespacedTypes } from "@/store/modules/amplitude";
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";
import { getProperty } from "@/utils/object";
import { stringToBoolean } from "@/utils/string";

export default {
    computed: {
        /**
         * The authentication state of the user.
         * @type {Boolean}
         */
        isLoggedIn() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.IS_LOGGED_IN
            ]();
        },

        /**
         * The currently authenticated user
         * @type {Object}
         */
        user() {
            return this.$store.getters[AuthStoreNamespacedTypes.getters.USER]();
        },

        /**
         * The referrer query parameter
         * @returns {string|null}
         */
        referrer() {
            return getProperty(this.$route, "query.referrer", null);
        },

        /**
         * The placement query parameter
         * @returns {string|null}
         */
        placement() {
            return getProperty(this.$route, "query.placement", null);
        },

        /**
         * @returns {boolean}
         */
        isDebugging() {
            return stringToBoolean(process.env.VUE_APP_DEBUG, false);
        },
    },
    watch: {
        isLoggedIn(newVal) {
            if (newVal) {
                this.setAmplitudeUser();
            }
        },
    },
    methods: {
        initAmplitude() {
            this.$store
                .dispatch(
                    AmplitudeStoreNamespacedTypes.actions.INIT_AMPLITUDE,
                    {
                        debug: this.isDebugging,
                        user: this.user,
                    }
                )
                .then(() => {});
        },
        setAmplitudeUser() {
            this.$store
                .dispatch(
                    AmplitudeStoreNamespacedTypes.actions.SET_AMPLITUDE_USER,
                    this.user
                )
                .then(() => {});
        },
        trackAmplitudeEvent(event, properties = {}) {
            if (
                getProperty(properties, "entry_point", null) === null &&
                this.referrer
            ) {
                properties.entry_point = this.referrer;
            }

            if (
                getProperty(properties, "entry_placement", null) === null &&
                this.placement
            ) {
                properties.entry_placement = this.placement;
            }

            if (getProperty(properties, "disable_route_tracking", false)) {
                properties.disable_route_tracking = null;
                properties.entry_point = null;
                properties.entry_placement = null;
            }

            this.$store
                .dispatch(
                    AmplitudeStoreNamespacedTypes.actions.TRACK_AMPLITUDE_EVENT,
                    {
                        event: event,
                        properties: properties,
                    }
                )
                .then(() => {});
        },
    },
};
