/**
 * A store for quiz.
 *
 * @typedef {{ session: string|null, loading: boolean, error: ErrorOrObject, questions: Object, answer: Object, answerError: ErrorOrObject, currentQuestionChecksum: string|null, progress: Array, timestamp: string|null, questionCount: Number }} QuizStoreState
 */
import Vue from "vue";
import { addNamespace } from "./namespace";
import { postData } from "@/api";
import { getProperty } from "@/utils/object";
import { localeEn } from "@/utils/enums";

/**
 * The types used in this store
 * @enum {string}
 */
export const QuizStoreTypes = {
    getters: {
        SESSION: "session",
        LOADING: "loading",
        ERROR: "error",
        QUESTION: "question",
        CURRENT_QUESTION: "currentQuestion",
        CURRENT_ANSWER: "currentAnswer",
        ANSWER_ERROR: "answerError",
        PROGRESS: "progress",
        TIMESTAMP: "timestamp",
        QUESTION_COUNT: "questionCount",
        HELP: "help",
    },
    actions: {
        INITIATE: "initiate",
        GET_QUESTION: "getQuestion",
        SAVE_ANSWER: "saveAnswer",
        GET_HELP: "getHelp",
        UPDATE_PROGRESS: "updateProgress",
        RESET_PROGRESS: "resetProgress",
    },
    mutations: {
        SET_SESSION: "setSession",
        SET_LOADING: "seLoading",
        SET_ERROR: "setError",
        SET_QUESTION: "setQuestion",
        SET_CURRENT_QUESTION: "setCurrentQuestion",
        SET_ANSWER: "setAnswer",
        SET_ANSWER_ERROR: "setAnswerError",
        SET_CURRENT_USER_PROGRESS: "setCurrentUserProgress",
        CLEAR_USER_PROGRESS: "clearUserProgress",
        SET_STARTED_TIMESTAMP: "setStartedTimestamp",
        SET_HELP: "setHelp",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const QuizStoreNamespacedTypes = addNamespace("quiz", QuizStoreTypes);

/**
 * @returns {QuizStoreState}
 */
export function state() {
    return {
        session: null,
        loading: false,
        error: null,
        questions: {},
        answer: {},
        answerError: null,
        currentQuestionChecksum: null,
        progress: [],
        timestamp: null,
        questionCount: 5,
        help: {
            is_phishing: false,
            summary: null,
            details: null,
        },
    };
}

export const getters = {
    [QuizStoreTypes.getters.SESSION]: (state) => () => {
        return state.session;
    },
    [QuizStoreTypes.getters.LOADING]: (state) => () => {
        return state.loading;
    },
    [QuizStoreTypes.getters.ERROR]: (state) => () => {
        return state.error;
    },
    [QuizStoreTypes.getters.QUESTION]: (state) => (checksum) => {
        return state.questions[checksum];
    },
    [QuizStoreTypes.getters.CURRENT_QUESTION]: (state) => () => {
        return state.questions[state.currentQuestionChecksum] || {};
    },
    [QuizStoreTypes.getters.CURRENT_ANSWER]: (state) => () => {
        return state.answer[state.currentQuestionChecksum] || null;
    },
    [QuizStoreTypes.getters.ANSWER_ERROR]: (state) => () => {
        return state.answerError;
    },
    [QuizStoreTypes.getters.PROGRESS]: (state) => () => {
        return state.progress;
    },
    [QuizStoreTypes.getters.TIMESTAMP]: (state) => () => {
        return state.startedTimestamp;
    },
    [QuizStoreTypes.getters.QUESTION_COUNT]: (state) => () => {
        return state.questionCount;
    },
    [QuizStoreTypes.getters.HELP]: (state) => () => {
        return state.help;
    },
};

export const actions = {
    /**
     * Sends the given quiz to the server.
     *
     * @param {VuexCommit} commit
     * @param {string} id
     * @return {Promise}
     */
    [QuizStoreTypes.actions.INITIATE]({ commit }, id) {
        commit(QuizStoreTypes.mutations.SET_SESSION, id);
    },

    /**
     * Sends the given quiz to the server.
     *
     * @param {VuexCommit} commit
     * @param {string} locale
     * @return {Promise}
     */
    [QuizStoreTypes.actions.GET_QUESTION]({ commit }, locale = localeEn) {
        commit(QuizStoreTypes.mutations.SET_LOADING, true);
        commit(QuizStoreTypes.mutations.SET_ERROR, null);
        commit(QuizStoreTypes.mutations.SET_ANSWER_ERROR, null);

        return postData("/quiz/question", {
            locale: locale,
        })
            .then((response) => {
                let question = getProperty(response, "data", null);
                commit(QuizStoreTypes.mutations.SET_QUESTION, {
                    checksum: question.checksum,
                    question: question,
                });
                commit(
                    QuizStoreTypes.mutations.SET_CURRENT_QUESTION,
                    question.checksum
                );
            })
            .catch((errors) => {
                commit(QuizStoreTypes.mutations.SET_ERROR, errors);
            })
            .finally(() => {
                commit(QuizStoreTypes.mutations.SET_LOADING, false);
            });
    },

    /**
     * Sends the given quiz to the server.
     *
     * @param {VuexCommit} commit
     * @param {VuexDispatch} dispatch
     * @param answer
     * @param checksum
     * @param time
     * @param {Object} question
     * @param type
     * @return {Promise}
     */
    [QuizStoreTypes.actions.SAVE_ANSWER](
        { commit, dispatch },
        { answer, checksum, time, question, type }
    ) {
        const payload = {
            answer,
            checksum,
            time,
            question,
        };

        if (type) {
            payload.type = type;
        }

        return postData("/quiz/answer", payload)
            .then((response) => {
                const correct = getProperty(response.data, "correct", false);

                commit(QuizStoreTypes.mutations.SET_ANSWER, {
                    answer: answer,
                    checksum: checksum,
                    correct: correct,
                    time: time,
                    updatedAt: new Date().toISOString(),
                });

                dispatch(QuizStoreTypes.actions.UPDATE_PROGRESS, {
                    correct: correct ? "correct" : "incorrect",
                    checksum: checksum,
                    delay: true,
                });

                commit(QuizStoreTypes.mutations.SET_ANSWER_ERROR, null);

                return correct;
            })
            .catch((errors) => {
                commit(QuizStoreTypes.mutations.SET_ANSWER_ERROR, errors);

                return null;
            });
    },

    /**
     * Sends the given quiz to the server.
     *
     * @param {VuexCommit} commit
     * @param checksum
     * @param {Object} question
     * @param {string} locale
     * @return {Promise}
     */
    [QuizStoreTypes.actions.GET_HELP](
        { commit },
        { checksum, question, locale }
    ) {
        const payload = {
            checksum,
            question,
            locale,
        };

        return postData("/quiz/help", payload)
            .then((response) => {
                commit(QuizStoreTypes.mutations.SET_HELP, {
                    is_phishing: getProperty(
                        response.data,
                        "is_phishing",
                        false
                    ),
                    summary: getProperty(response.data, "summary"),
                    details: getProperty(response.data, "details"),
                });
            })
            .catch((errors) => {
                commit(QuizStoreTypes.mutations.SET_ERROR, errors);

                return null;
            });
    },

    [QuizStoreTypes.actions.UPDATE_PROGRESS](
        { commit },
        { correct, checksum, delay = false }
    ) {
        if (delay) {
            setTimeout(() => {
                commit(QuizStoreTypes.mutations.SET_CURRENT_USER_PROGRESS, {
                    checksum,
                    correct,
                });
            }, 1000);
            return;
        }

        commit(QuizStoreTypes.mutations.SET_CURRENT_USER_PROGRESS, {
            checksum,
            correct,
        });
    },

    [QuizStoreTypes.actions.RESET_PROGRESS]({ commit }) {
        commit(QuizStoreTypes.mutations.SET_STARTED_TIMESTAMP, {
            started_at: new Date().toISOString(),
        });
        commit(QuizStoreTypes.mutations.CLEAR_USER_PROGRESS);
    },
};

export const mutations = {
    [QuizStoreTypes.mutations.SET_SESSION](state, id) {
        state.session = id;
    },
    [QuizStoreTypes.mutations.SET_LOADING](state, loading) {
        state.loading = loading;
    },
    [QuizStoreTypes.mutations.SET_ERROR](state, error) {
        state.error = error;
    },
    [QuizStoreTypes.mutations.SET_QUESTION](state, { checksum, question }) {
        Vue.set(state.questions, checksum, question);
    },
    [QuizStoreTypes.mutations.SET_CURRENT_QUESTION](state, checksum) {
        state.currentQuestionChecksum = checksum;
    },
    [QuizStoreTypes.mutations.SET_ANSWER](
        state,
        { answer, checksum, correct, lapsedTime, updatedAt }
    ) {
        Vue.set(state.answer, checksum, {
            answer,
            correct,
            lapsedTime,
            updatedAt,
        });
    },
    [QuizStoreTypes.mutations.SET_ANSWER_ERROR](state, error) {
        state.answerError = error;
    },
    [QuizStoreTypes.mutations.SET_CURRENT_USER_PROGRESS](
        state,
        { checksum, correct }
    ) {
        state.progress.splice(
            state.progress.findIndex((x) => x == null),
            1,
            {
                checksum: checksum,
                answer: correct,
            }
        );
    },
    [QuizStoreTypes.mutations.CLEAR_USER_PROGRESS](state) {
        state.progress = new Array(state.questionCount).fill(null);
    },
    [QuizStoreTypes.mutations.SET_STARTED_TIMESTAMP](state, timestamp) {
        state.startedTimestamp = timestamp.started_at;
    },
    /**
     * Set help information.
     *
     * @param {QuizStoreState} state
     * @param {Object} help
     */
    [QuizStoreTypes.mutations.SET_HELP](state, help) {
        state.help = help;
    },
};

export default {
    namespaced: true,
    Types: QuizStoreTypes,
    NamespacedTypes: QuizStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
