/**
 * This file provides dummy API responses for developing without access to a backend server.
 * Not all API end points are available.
 *
 * The API will respond with responses from this file if the API URL is configured to `dummy-responses`.
 *
 * All responses will be returned as promises which will resolve within 0,25 - 1,5 seconds to mimic a real world API.
 */
import i18n from "./lang";
import { uuid } from "./utils/string";

/**
 * The fake URL used when configuring the application to use the dummy API.
 * @type {string}
 */
export const dummyApiUrl = `dummy-responses`;

/**
 * The minimum allowed delay when responding to requests.
 * @type {number}
 */
const minDelay = 250;

/**
 * The maximum allowed delay when responding to requests.
 * @type {number}
 */
const maxDelay = 1500;

/**
 * The difference between the minimum and maximum allowed delays.
 * @type {number}
 */
const delayDifference = maxDelay - minDelay;

/**
 * A dummy session object.
 * @type {{features: string[], mandatory_training: boolean, token: string, user: {id: string, workspace_id: string, first_name: string, last_name: string, full_name: string, email: string, avatar: string, locale: string, onboarded: boolean, point_count: number, progress: number, watched_videos: []}}
 */
const dummySession = {
    features: [
        "hub-breaches",
        "hub-courses",
        "hub-leaderboard",
        "hub-quiz",
        "hub-search",
        "hub-simulations",
        "hub-videos",
    ],
    mandatory_training: false,
    token: "52867|0jgWtlPkUQGmea4bqLSCt1h071RnpWAZGR1IgqnWc1ff77e1",
    user: {
        id: "a8b52840-1a68-4c27-963b-ded14420b30c",
        workspace_id: "d6bef3ad-ce69-4ed6-b088-************",
        first_name: "John",
        last_name: "Doe",
        full_name: "John Doe",
        email: "john@doe.com",
        avatar: "https://api.dicebear.com/7.x/adventurer-neutral/png?seed=a8b52840-1a68-4c27-963b-ded14420b30c",
        locale: "en_US",
        onboarded: true,
        point_count: 9999,
        score: 75,
        progress: 0,
    },
    watched_videos: [],
};

/**
 * A dummy video object.
 * @return {{id: string, external_id: string, title: string, category: string, created_at: string, description: string, duration: number, image: string}}
 */
const dummyVideo = () => {
    let id = uuid();
    return {
        id: id,
        external_id: "9ff150646c4c60a8da87a4bd0bc33a22",
        title: "Video title",
        category: "phishing",
        created_at: "2023-07-05T06:00:00.000000Z",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque augue libero, mollis sit amet lectus a, porta cursus dolor. Praesent vulputate iaculis magna ac consectetur. Praesent rutrum accumsan cursus. Aenean fermentum consequat consectetur. Fusce condimentum odio massa, ac vulputate nunc scelerisque eu.",
        duration: 255,
        image: "https://d1vfmqj1s5shnz.cloudfront.net/videos/is4kfmbidshls4mlwpnf.jpeg",
    };
};

/**
 * A dummy course object.
 * @return {{id: string, title: string, description: string, featured: boolean, image: string, video: string, lesson_count: number}}
 */
const dummyCourse = (featured) => {
    let id = uuid();
    return {
        id: id,
        title: "Course title",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        featured: featured,
        image: "https://d1vfmqj1s5shnz.cloudfront.net/course/9B8QNSiJoyDBZVTG4Zllh0DIaOrc7HRK9gBkpau1.jpg",
        video: "https://d1vfmqj1s5shnz.cloudfront.net/course/Dm5V41RlxJvptizb0RyZC49hvSBcmnHnr1yLaSlh.mp4",
        lesson_count: 7,
    };
};

/**
 * A dummy simulation object.
 * @return {{id: string, from_email: string, from_name: string, has_click: boolean, has_open: boolean, sent_at: string, subject: string, template: {format: string, html: string}}}
 */
const dummySimulation = () => {
    let id = uuid();
    return {
        id: id,
        from_email: "no-reply@microsoft.no-reply.nu",
        from_name: "Microsoft",
        has_click: false,
        has_open: true,
        sent_at: "2024-04-02T06:30:07.000000Z",
        subject: "Meeting in progress - Microsoft teams",
        template: {
            format: "html",
            html: "<p>email</p>",
        },
    };
};

/**
 * A dummy breach object.
 * @return {{id: string, breached_at: string, data_classes: string[], description: string, domain: string, email: string, is_sensitive: boolean, is_verified: boolean, logo: string, name: string, total_count: number}}
 */
const dummyBreach = () => {
    let id = uuid();
    return {
        id: id,
        breached_at: "2023-11-08",
        data_classes: [
            "Email addresses",
            "Geographic locations",
            "Names",
            "Usernames",
        ],
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque augue libero, mollis sit amet lectus a, porta cursus dolor. Praesent vulputate iaculis magna ac consectetur.",
        domain: "chess.com",
        email: "78557cb8-d57e-40d5-98bf-2b04ef96b67d",
        is_sensitive: false,
        is_verified: true,
        logo: "https://haveibeenpwned.com/Content/Images/PwnedLogos/Chess.png",
        name: "Chess",
        total_count: 827620,
    };
};

/**
 * A map of responses to GET calls.
 * Each response has its absolute URL as the key and either a JSON response or a function returning a JSON response as its value.
 *
 * @type {Object<string, string|number|Object|Array|function():string|number|Object|Array>}
 */
const getResponses = {
    "/auth/csrf": null,
    "/session": () => {
        return dummySession;
    },
    "/leaderboard": {
        rank: 2,
        top: [
            {
                id: "ede66acf-3f61-4449-a359-99d1fac1756b",
                full_name: "Jane Doe",
                avatar: "https://api.dicebear.com/7.x/adventurer-neutral/png?seed=ede66acf-3f61-4449-a359-99d1fac1756b",
                point_count: 11111,
                rank: 1,
            },
            {
                id: "a8b52840-1a68-4c27-963b-ded14420b30c",
                full_name: "John Doe",
                avatar: "https://api.dicebear.com/7.x/adventurer-neutral/png?seed=a8b52840-1a68-4c27-963b-ded14420b30c",
                point_count: 9999,
                rank: 2,
            },
            {
                id: "b8b52840-1a68-4c27-963b-ded14420b30c",
                full_name: "Alice Doe",
                avatar: "https://api.dicebear.com/7.x/adventurer-neutral/png?seed=b8b52840-1a68-4c27-963b-ded14420b30c",
                point_count: 8888,
                rank: 3,
            },
            {
                id: "c8b52840-1a68-4c27-963b-ded14420b30c",
                full_name: "Bob Doe",
                avatar: "https://api.dicebear.com/7.x/adventurer-neutral/png?seed=c8b52840-1a68-4c27-963b-ded14420b30c",
                point_count: 7777,
                rank: 4,
            },
            {
                id: "d8b52840-1a68-4c27-963b-ded14420b30c",
                full_name: "Eve Doe",
                avatar: "https://api.dicebear.com/7.x/adventurer-neutral/png?seed=d8b52840-1a68-4c27-963b-ded14420b30c",
                point_count: 6666,
                rank: 5,
            },
        ],
    },
    "/statistics": {
        breaches: {
            count: 500,
            groups: [
                {
                    color: "warning-normal",
                    count: 300,
                    label: "critical",
                    pct: 6000,
                },
                {
                    color: "primary-normal",
                    count: 200,
                    label: "noncritical",
                    pct: 4000,
                },
            ],
        },
        quiz: {
            count: 500,
            groups: [
                {
                    color: "warning-normal",
                    count: 300,
                    label: "incorrect",
                    pct: 6000,
                },
                {
                    color: "primary-normal",
                    count: 200,
                    label: "correct",
                    pct: 4000,
                },
            ],
        },
    },
    "/simulations": {
        meta: {
            count: 4,
        },
        data: [
            dummySimulation(),
            dummySimulation(),
            dummySimulation(),
            dummySimulation(),
        ],
    },
    "/breaches": {
        meta: {
            count: 4,
        },
        emails: [
            {
                email: "john@doe.com",
                id: "78557cb8-d57e-40d5-98bf-2b04ef96b67d",
                last_sync_at: "2024-04-04T10:51:04.000000Z",
                type: "primary",
            },
        ],
        records: [dummyBreach(), dummyBreach(), dummyBreach(), dummyBreach()],
    },
};

/**
 * A map of responses to post calls.
 * Each response has its absolute URL as the key and either a JSON response or a function returning a JSON response as its value.
 * If a function is set as the value it will receive the POST data as its only parameter.
 *
 * @type {Object<string, string|number|Object|Array|function(Object):string|number|Object|Array>}
 */
const postResponses = {
    "/auth/login": () => {
        let statusCode = 200;
        let response = {
            data: [],
            message: null,
        };

        return { statusCode, response };
    },
    "/auth/code": () => {
        let statusCode = 200;
        let response = dummySession;

        return { statusCode, response };
    },
    "/videos": () => {
        let statusCode = 200;
        let videos = [
            dummyVideo(),
            dummyVideo(),
            dummyVideo(),
            dummyVideo(),
            dummyVideo(),
        ];

        let response = {
            advanced: videos,
            gdpr: videos,
            hacking: videos,
            phishing: videos,
            physicalSecurity: videos,
            tipsAndTricks: videos,
            typesOfHacking: videos,
        };

        return { statusCode, response };
    },
    "/videos/featured": () => {
        let statusCode = 200;
        let response = [dummyVideo(), dummyVideo(), dummyVideo()];

        return { statusCode, response };
    },
    "/courses": {
        completed: [],
        courses: [
            dummyCourse(true),
            dummyCourse(true),
            dummyCourse(false),
            dummyCourse(false),
            dummyCourse(false),
            dummyCourse(false),
            dummyCourse(false),
        ],
        progress: [],
    },
};

/**
 * Returns a dummy GET response for the given path.
 * If no response is found in the response map, the request is rejected with a 404 error.
 *
 * @param {string} path
 * @return {Promise}
 */
export const getDummyResponse = (path) => {
    const cleanedPath = path.split("?")[0];
    const hasResponse = Object.prototype.hasOwnProperty.call(
        getResponses,
        cleanedPath
    );

    let response = null;

    if (hasResponse) {
        if (typeof getResponses[cleanedPath] === "function") {
            response = getResponses[cleanedPath]();
        } else {
            response = getResponses[cleanedPath];
        }
    }

    console.log(`GET: "${cleanedPath}"`);
    return respond(hasResponse, response);
};

/**
 * Returns a dummy POST response for the given path and data payload.
 * If no response is found in the response map, the request is rejected with a 404 error.
 *
 * @param {string} path
 * @param {Object=} data
 * @return {Promise}
 */
export const postDummyResponse = (path, data) => {
    const hasResponse = Object.prototype.hasOwnProperty.call(
        postResponses,
        path
    );

    let response = null;
    let statusCode = 200;

    if (hasResponse) {
        if (typeof postResponses[path] === "function") {
            response = postResponses[path](data);
        } else {
            response = postResponses[path];
        }
    }

    if (response && response.statusCode) {
        statusCode = response.statusCode;
        response = response.response;
    }

    console.log(`POST: "${path}"`, data);
    return respond(hasResponse, response, statusCode);
};

/**
 * Returns a delayed promise with the given response or a 404 error if the response isn't valid.
 *
 * @param {boolean} hasResponse
 * @param {*} response
 * @param {number} statusCode
 * @return {Promise}
 */
const respond = (hasResponse, response, statusCode = 200) => {
    console.log("Responding", hasResponse ? response : "404 error");

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if (hasResponse) {
                if (statusCode !== 200) {
                    reject(response);
                }

                resolve({ data: response });
            } else {
                reject({ message: i18n.t("errors.404") });
            }
        }, Math.round(Math.random() * delayDifference + minDelay));
    });
};
