<template>
    <Card :gutter="false">
        <div class="border-b p-4 sm:flex sm:items-center sm:justify-between">
            <div class="items-center sm:flex sm:space-x-2.5">
                <div class="flex items-center justify-center sm:flex-shrink-0">
                    <Avatar
                        :name="name"
                        :image="avatar"
                        :size="SizeVariants.LARGE"
                    />
                </div>
                <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                    <p class="font-medium text-slate-600">
                        {{ $t("overview.welcome") }},
                    </p>
                    <p class="font-bold text-slate-900" v-text="name"></p>
                </div>
            </div>
        </div>
        <div class="p-4">
            <div class="rounded-lg bg-slate-50 p-4">
                <div class="pb-6 text-center">
                    <h3
                        class="mb-0.5 font-bold text-slate-900"
                        v-text="$t('resilience_score.title').toString()"
                    ></h3>
                    <p
                        class="text-xs text-slate-600"
                        v-text="$t('resilience_score.text').toString()"
                    ></p>
                </div>
                <div class="relative mb-6 px-6">
                    <div class="relative">
                        <GaugeChart
                            :value="score"
                            :max="100"
                            :min="0"
                            :separator-thickness="4"
                            :separator-step="40"
                            :inner-radius="75"
                            :scale-interval="5"
                            base-color="#e3e8ef"
                            :gauge-color="fillColor"
                        />
                        <p
                            class="absolute -bottom-1 left-1/2 flex w-full -translate-x-1/2 transform items-center justify-center text-center"
                        >
                            <span
                                class="text-2xl font-bold text-slate-800 xl:text-3xl"
                                v-text="score"
                            ></span>
                            <span class="ml-1 font-bold text-slate-500">%</span>
                        </p>
                    </div>
                </div>
                <ul
                    class="mb-4 space-y-3 border-b border-t border-slate-300/60 py-4"
                >
                    <FactorIndicator
                        :title="
                            $t('resilience_score.quizzing.title').toString()
                        "
                        :value="quizScore"
                    />
                    <FactorIndicator
                        :title="$t('resilience_score.videos.title').toString()"
                        :value="videosScore"
                    />
                    <FactorIndicator
                        :title="
                            $t('resilience_score.simulations.title').toString()
                        "
                        :value="simulationsScore"
                    />
                    <FactorIndicator
                        :title="$t('resilience_score.skills.title').toString()"
                        :value="skillsScore"
                    />
                </ul>
                <div class="text-center">
                    <FormButton
                        :variant="ButtonVariants.SECONDARY"
                        :size="ButtonSizeVariants.SM"
                        @click="$emit('show-score')"
                        >{{ $t("resilience_score.show_breakdown") }}</FormButton
                    >
                </div>
            </div>
        </div>
    </Card>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";

import { getProperty } from "@/utils/object";

import Avatar, { SizeVariants } from "@/components/ui/Avatar";
import Card from "@/components/layout/Card";
import GaugeChart from "@/components/ui/GaugeChart";
import FactorIndicator from "@/components/ui/FactorIndicator";
import FormButton, {
    ButtonVariants,
    ButtonSizeVariants,
} from "@/components/forms/fields/FormButton";

export default {
    name: "ProfileCard",
    components: {
        FormButton,
        FactorIndicator,
        GaugeChart,
        Card,
        Avatar,
    },
    data: () => ({
        ButtonVariants,
        ButtonSizeVariants,
        SizeVariants,
    }),
    computed: {
        /**
         * The currently authenticated user
         * @type {Object}
         */
        user() {
            return this.$store.getters[AuthStoreNamespacedTypes.getters.USER]();
        },
        /**
         * The user's first name
         * @type {String}
         */
        name() {
            return getProperty(this.user, "first_name", null);
        },
        /**
         * The user's avatar
         * @type {String}
         */
        avatar() {
            return getProperty(this.user, "avatar");
        },
        /**
         * The user's resilience score
         * @type {Number}
         */
        score() {
            return getProperty(this.user, "score.total", 0);
        },
        /**
         * The user's quiz score
         * @type {Number}
         */
        quizScore() {
            let value = getProperty(this.user, "score.quizzes", 0);
            return (value / 20) * 100;
        },
        /**
         * The user's simulations score
         * @type {Number}
         */
        simulationsScore() {
            let value = getProperty(this.user, "score.simulations", 0);
            return (value / 40) * 100;
        },
        /**
         * The user's videos score
         * @type {Number}
         */
        videosScore() {
            let value = getProperty(this.user, "score.videos", 0);
            return (value / 30) * 100;
        },
        /**
         * The user's skills score
         * @type {Number}
         */
        skillsScore() {
            let value = getProperty(this.user, "score.skills", 0);
            return (value / 10) * 100;
        },
        /**
         * The fill color for the gauge chart
         * @type {String}
         */
        fillColor() {
            if (this.score < 30) {
                return "#de524c";
            }

            if (this.score < 65) {
                return "#ea7a35";
            }

            return "#65d1bf";
        },
    },
};
</script>
