<template>
    <li class="mandatory-course relative flex flex-col p-4 lg:flex-row">
        <div class="mb-4 w-full lg:mb-0 lg:h-32 lg:w-56">
            <router-link
                :to="{
                    name: 'courses.single',
                    params: { slug: slug, id: id },
                    query: {
                        referrer: this.referrer,
                        placement: this.placement,
                    },
                }"
            >
                <div
                    v-if="video"
                    class="media rounded-xl ring-1 ring-slate-200"
                >
                    <video
                        autoplay
                        loop
                        muted
                        :poster="image"
                        class="media-figure"
                    >
                        <source :src="video" type="video/mp4" />
                    </video>
                    <div
                        class="absolute bottom-2 left-2 flex items-center rounded bg-slate-600 px-1.5 py-0.5 text-sm text-white"
                    >
                        {{ $tc("courses.lessons", totalLessons) }}
                    </div>
                </div>
                <div v-else class="media rounded-xl">
                    <img :src="image" class="media-figure" alt="media" />
                    <div
                        class="absolute bottom-2 left-2 flex items-center rounded bg-slate-600 px-1.5 py-0.5 text-sm text-white"
                    >
                        {{ $tc("courses.lessons", totalLessons) }}
                    </div>
                </div>
            </router-link>
        </div>
        <div
            class="relative flex flex-grow flex-col justify-between lg:ml-4 lg:items-start"
        >
            <div>
                <h4 class="heading">
                    {{ title }}
                </h4>
                <DeadlineBadge v-if="deadline" :deadline="deadline" />
            </div>
            <FormButton
                :variant="ButtonVariants.SECONDARY"
                :size="ButtonSizeVariants.SM"
                :to="{
                    name: 'courses.single',
                    params: { slug: slug, id: id },
                    query: {
                        referrer: this.referrer,
                        placement: this.placement,
                    },
                }"
            >
                {{
                    currentStep > 0
                        ? $t("courses.progress.button")
                        : $t("courses.content.introduction.start")
                }}</FormButton
            >
            <div
                v-if="currentStep > 0"
                class="absolute right-1 top-1 hidden flex-row items-center justify-start sm:flex"
            >
                <div class="rounded-full bg-white p-0.5 ring-2 ring-slate-100">
                    <ProgressRing
                        :radius="14"
                        :progress="percentageCompleted"
                        :stroke="3"
                    />
                </div>
                <p class="pl-2 text-sm font-medium text-slate-800">
                    {{ percentageCompleted + "%" }}
                </p>
            </div>
        </div>
    </li>
</template>

<script>
import { CourseStoreNamespacedTypes } from "@/store/modules/course";
import { getProperty } from "@/utils/object";
import { toPercent } from "@/utils/math";
import ProgressRing from "@/components/ui/ProgressRing";
import FormButton, {
    ButtonVariants,
    ButtonSizeVariants,
} from "@/components/forms/fields/FormButton";
import DeadlineBadge from "@/components/mandatory/content/DeadlineBadge";

export default {
    name: "CourseListItem",
    components: { DeadlineBadge, FormButton, ProgressRing },
    props: {
        content: {
            type: Object,
            required: true,
        },
        deadline: {
            type: String,
            required: false,
            default: null,
        },
        referrer: {
            type: String,
            required: false,
        },
        placement: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            ButtonVariants,
            ButtonSizeVariants,
        };
    },
    computed: {
        title() {
            return getProperty(this.content, "title", null);
        },
        slug() {
            return getProperty(this.content, "slug", null);
        },
        id() {
            return getProperty(this.content, "id", null);
        },
        totalLessons() {
            return getProperty(this.content, "lesson_count", 0);
        },
        image() {
            let img = getProperty(this.content, "image", null);
            return img ? img : "/img/placeholder.jpg";
        },
        video() {
            return getProperty(this.content, "video", null);
        },
        /**
         * Collected progress for all courses
         * @type {Array}
         */
        progress() {
            return this.$store.getters[
                CourseStoreNamespacedTypes.getters.PROGRESS
            ]();
        },

        courseProgress() {
            return (
                this.progress.find(
                    (course) =>
                        getProperty(course, "course_id") === this.content.id
                ) || { step: 0 }
            );
        },
        currentStep() {
            return getProperty(this.courseProgress, "step", 0);
        },
        percentageCompleted() {
            return toPercent(this.currentStep, this.totalLessons, 0);
        },
    },
};
</script>
