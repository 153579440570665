<template>
    <PageWrapper title="overview">
        <TwoCol>
            <div slot="content">
                <ScoreSlideOver :active="showScore" @close="toggleShowScore" />
                <InformationCard
                    :title="$t('cards.overview.title').toString()"
                    :text="
                        $t('cards.overview.text', {
                            name: name,
                        }).toString()
                    "
                    image="/img/cards/overview-card-bg.jpg"
                />
                <MandatoryTraining v-if="hasMandatoryTraining" />
                <FeaturedVideos v-else-if="hasFeature('hub-videos')" />
                <div v-if="hasFeature('hub-quiz')" class="card p-4">
                    <div class="grid gap-4 sm:grid-cols-12">
                        <div class="sm:order-2 sm:col-span-5">
                            <div
                                class="overflow-hidden rounded-xl border bg-slate-100"
                            >
                                <img
                                    class="w-full ps-5 pt-5"
                                    src="/img/quiz-card-placeholder.svg"
                                    alt="quiz-card-placeholder"
                                />
                            </div>
                        </div>
                        <div class="sm:order-1 sm:col-span-7">
                            <div
                                class="flex h-full flex-col items-start justify-between space-y-3"
                            >
                                <div>
                                    <h3 class="heading">
                                        {{ $t("cards.quiz.title") }}
                                    </h3>
                                    <p class="mt-1 text-slate-500 sm:mt-3">
                                        {{ $t("cards.quiz.text") }}
                                    </p>
                                </div>
                                <FormButton
                                    :variant="ButtonVariants.SECONDARY"
                                    :to="{
                                        name: 'quiz',
                                        query: {
                                            referrer: 'overview',
                                            placement: 'quiz_card',
                                        },
                                    }"
                                    >{{ $t("overview.start_quiz") }}</FormButton
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <LeaderBoard v-if="hasFeature('hub-leaderboard')" />
            </div>
            <div slot="sidebar">
                <ProfileCard @show-score="toggleShowScore" />
            </div>
        </TwoCol>
    </PageWrapper>
</template>

<script>
import { StatisticsStoreNamespacedTypes } from "@/store/modules/statistics";
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";

import { getProperty } from "@/utils/object";

import ProfileCard from "@/components/ui/ProfileCard";
import TwoCol from "@/components/layout/TwoCol";
import PageWrapper from "@/components/layout/PageWrapper";
import FeaturedVideos from "@/components/video/FeaturedVideos";
import LeaderBoard from "@/components/level/LeaderBoard";
import FeatureFlagMixin from "@/mixins/FeatureFlagMixin";
import FormButton, {
    ButtonVariants,
} from "@/components/forms/fields/FormButton";
import MandatoryTraining from "@/components/mandatory/MandatoryTraining";
import InformationCard from "@/components/ui/InformationCard";
import ScoreSlideOver from "@/components/ui/ScoreSlideOver";

export default {
    name: "OverviewPage",
    mixins: [FeatureFlagMixin],
    components: {
        ScoreSlideOver,
        InformationCard,
        MandatoryTraining,
        FormButton,
        FeaturedVideos,
        PageWrapper,
        TwoCol,
        ProfileCard,
        LeaderBoard,
    },
    data: () => ({
        ButtonVariants,
        showScore: false,
    }),
    computed: {
        /**
         * The currently authenticated user
         * @type {Object}
         */
        user() {
            return this.$store.getters[AuthStoreNamespacedTypes.getters.USER]();
        },
        name() {
            return getProperty(this.user, "first_name");
        },
        /**
         * Mandatory training feature flag
         * @type {boolean}
         */
        hasMandatoryTraining() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.MANDATORY_TRAINING
            ]();
        },
        /**
         * The request loading state
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                StatisticsStoreNamespacedTypes.getters.STATISTICS_LOADING
            ]();
        },
        /**
         * The request error state
         * @type {ErrorOrObject}
         */
        error() {
            return this.$store.getters[
                StatisticsStoreNamespacedTypes.getters.STATISTICS_ERROR
            ]();
        },
    },
    methods: {
        toggleShowScore() {
            this.showScore = !this.showScore;
        },
    },
};
</script>
