import axios from "axios";
import { extractErrorObject } from "./utils/errors";
import {
    dummyApiUrl,
    getDummyResponse,
    postDummyResponse,
} from "./api-dummy-responses";

import store from "./store";
import { AuthStoreNamespacedTypes } from "./store/modules/auth";

const API_URL = process.env.VUE_APP_OAUTH_AUTH_SERVER;
export let axiosInstance = null;

export const initialize = () => {
    if (!axiosInstance) {
        axiosInstance = axios.create({
            withCredentials: true,
            baseURL: `${process.env.VUE_APP_OAUTH_AUTH_SERVER}/api/hub/v1`,
            headers: {
                common: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        });
    }
};

export const fetchCsrfCookie = () => {
    return getRawData("/auth/csrf");
};

export const setAuthToken = (token) => {
    initialize();

    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const getRawData = (path, config) => {
    if (API_URL === dummyApiUrl) {
        return getDummyResponse(path);
    }

    const rawAxiosInstance = axios.create({
        withCredentials: true,
        baseURL: `${process.env.VUE_APP_OAUTH_AUTH_SERVER}/api/hub/v1`,
    });

    if (config) {
        return rawAxiosInstance.get(path, config);
    } else {
        return rawAxiosInstance.get(path);
    }
};

export const getData = (path) => {
    if (API_URL === dummyApiUrl) {
        return getDummyResponse(path);
    }

    initialize();

    return axiosInstance
        .get(path)
        .then(extractResponseData)
        .catch(extractErrorObjectAndReject);
};

export const postData = (path, data) => {
    if (API_URL === dummyApiUrl) {
        return postDummyResponse(path, data);
    }

    initialize();

    return axiosInstance
        .post(path, data)
        .then(extractResponseData)
        .catch(extractErrorObjectAndReject);
};

export const rejectWithData = (data) => {
    if (data.status === 401 || data.status === 403 || data.status === 503) {
        store.dispatch(AuthStoreNamespacedTypes.actions.LOGOUT);
        return;
    }

    if (data.status === 419) {
        data.message = "api.messages.csrf";
        store.commit(AuthStoreNamespacedTypes.mutations.SET_CSRF_ERROR, {
            message: data.message,
        });
    }

    return Promise.reject(data);
};

export const extractErrorObjectAndReject = (error) => {
    return rejectWithData(extractErrorObject(error));
};

export const extractResponseData = (response) => {
    if (!response) {
        throw "errors.invalid-data-format";
    }

    return response;
};
