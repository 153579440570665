<template>
    <SlideOver :active="active" :gutter="false" @close="$emit('close')">
        <h2
            slot="title"
            class="text-base font-semibold leading-6 text-slate-900"
        >
            {{ $t("resilience_score.title") }}
        </h2>
        <div slot="content">
            <div class="card">
                <div
                    class="relative min-h-24 overflow-hidden rounded-t-xl bg-sky-950"
                >
                    <figure class="absolute inset-x-0 bottom-0 -mb-px">
                        <svg
                            preserveAspectRatio="none"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            viewBox="0 0 1920 100.1"
                        >
                            <path
                                fill="currentColor"
                                class="fill-white"
                                d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                            ></path>
                        </svg>
                    </figure>
                </div>
                <div
                    class="relative z-10 -mt-12 flex flex-col items-center justify-center"
                >
                    <Avatar
                        :name="name"
                        :image="avatar"
                        :size="SizeVariants.BIG"
                    />
                    <h3 class="heading mb-2 mt-1" v-text="name"></h3>
                    <PointBadge />
                </div>
                <div class="flex flex-col gap-4 p-4">
                    <div class="rounded-lg bg-slate-50 p-4">
                        <p
                            class="text-sm text-slate-600"
                            v-text="$t('resilience_score.long_text').toString()"
                        ></p>
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                        <ScoreCard
                            :title="
                                $t('resilience_score.quizzing.title').toString()
                            "
                            :text="
                                $t('resilience_score.quizzing.text').toString()
                            "
                            :value="quizScore"
                        />
                        <ScoreCard
                            :title="
                                $t('resilience_score.videos.title').toString()
                            "
                            :text="
                                $t('resilience_score.videos.text').toString()
                            "
                            :value="videosScore"
                        />
                        <ScoreCard
                            :title="
                                $t(
                                    'resilience_score.simulations.title'
                                ).toString()
                            "
                            :text="
                                $t(
                                    'resilience_score.simulations.text'
                                ).toString()
                            "
                            :value="simulationsScore"
                        />
                        <ScoreCard
                            :title="
                                $t('resilience_score.skills.title').toString()
                            "
                            :text="
                                $t('resilience_score.skills.text').toString()
                            "
                            :value="skillsScore"
                        />
                    </div>
                </div>
            </div>
        </div>
    </SlideOver>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";

import { getProperty } from "@/utils/object";

import SlideOver from "@/components/ui/SlideOver";
import ScoreCard from "@/components/ui/ScoreCard";
import Avatar, { SizeVariants } from "@/components/ui/Avatar";
import PointBadge from "@/components/ui/PointBadge";

export default {
    name: "ScoreSlideOver",
    components: {
        PointBadge,
        Avatar,
        ScoreCard,
        SlideOver,
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        SizeVariants,
    }),
    computed: {
        /**
         * The currently authenticated user
         * @type {Object}
         */
        user() {
            return this.$store.getters[AuthStoreNamespacedTypes.getters.USER]();
        },
        name() {
            return getProperty(this.user, "first_name", null);
        },
        avatar() {
            return getProperty(this.user, "avatar");
        },
        quizScore() {
            let value = getProperty(this.user, "score.quizzes", 0);
            return (value / 20) * 100;
        },
        simulationsScore() {
            let value = getProperty(this.user, "score.simulations", 0);
            return (value / 40) * 100;
        },
        videosScore() {
            let value = getProperty(this.user, "score.videos", 0);
            return (value / 30) * 100;
        },
        skillsScore() {
            let value = getProperty(this.user, "score.skills", 0);
            return (value / 10) * 100;
        },
        points() {
            return getProperty(this.user, "point_count", 0);
        },
    },
};
</script>
