<template>
    <g>
        <circle cx="256" cy="256" r="256" fill="#f0f0f0" />
        <g fill="#d80027">
            <path
                d="M200.349 222.609h309.484C493.47 97.002 386.067 0 256 0a256.902 256.902 0 0 0-55.652 6.085v216.524h.001zM133.565 222.608V31.127C63.272 69.481 12.95 139.832 2.167 222.609h131.398v-.001zM133.564 289.391H2.167c10.783 82.777 61.105 153.128 131.398 191.481l-.001-191.481zM200.348 289.392v216.523A256.902 256.902 0 0 0 256 512c130.067 0 237.47-97.002 253.833-222.609H200.348v.001z"
            />
        </g>
    </g>
</template>

<script>
import IconSvgBase from "./IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconDanish",
    data: () => ({
        viewBoxHeight: 512,
        viewBoxWidth: 512,
    }),
};
</script>
