var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PageWrapper',{attrs:{"title":"overview"}},[_c('TwoCol',[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('ScoreSlideOver',{attrs:{"active":_vm.showScore},on:{"close":_vm.toggleShowScore}}),_c('InformationCard',{attrs:{"title":_vm.$t('cards.overview.title').toString(),"text":_vm.$t('cards.overview.text', {
                        name: _vm.name,
                    }).toString(),"image":"/img/cards/overview-card-bg.jpg"}}),(_vm.hasMandatoryTraining)?_c('MandatoryTraining'):(_vm.hasFeature('hub-videos'))?_c('FeaturedVideos'):_vm._e(),(_vm.hasFeature('hub-quiz'))?_c('div',{staticClass:"card p-4"},[_c('div',{staticClass:"grid gap-4 sm:grid-cols-12"},[_c('div',{staticClass:"sm:order-2 sm:col-span-5"},[_c('div',{staticClass:"overflow-hidden rounded-xl border bg-slate-100"},[_c('img',{staticClass:"w-full ps-5 pt-5",attrs:{"src":"/img/quiz-card-placeholder.svg","alt":"quiz-card-placeholder"}})])]),_c('div',{staticClass:"sm:order-1 sm:col-span-7"},[_c('div',{staticClass:"flex h-full flex-col items-start justify-between space-y-3"},[_c('div',[_c('h3',{staticClass:"heading"},[_vm._v(" "+_vm._s(_vm.$t("cards.quiz.title"))+" ")]),_c('p',{staticClass:"mt-1 text-slate-500 sm:mt-3"},[_vm._v(" "+_vm._s(_vm.$t("cards.quiz.text"))+" ")])]),_c('FormButton',{attrs:{"variant":_vm.ButtonVariants.SECONDARY,"to":{
                                    name: 'quiz',
                                    query: {
                                        referrer: 'overview',
                                        placement: 'quiz_card',
                                    },
                                }}},[_vm._v(_vm._s(_vm.$t("overview.start_quiz")))])],1)])])]):_vm._e(),(_vm.hasFeature('hub-leaderboard'))?_c('LeaderBoard'):_vm._e()],1),_c('div',{attrs:{"slot":"sidebar"},slot:"sidebar"},[_c('ProfileCard',{on:{"show-score":_vm.toggleShowScore}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }