import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";
import {
    getEncryptedStorageItem,
    setEncryptedStorageItem,
    removeStorageItem,
} from "@/utils/storage";

import layout, { LayoutStoreNamespacedTypes } from "./modules/layout";
import auth, { AuthStoreNamespacedTypes } from "./modules/auth";
import sso, { SsoStoreNamespacedTypes } from "./modules/sso";
import locale, { LocaleStoreNamespacedTypes } from "./modules/locale";
import amplitude, { AmplitudeStoreNamespacedTypes } from "./modules/amplitude";
import mandatory, { MandatoryStoreNamespacedTypes } from "./modules/mandatory";
import video, { VideoStoreNamespacedTypes } from "./modules/video";
import course, { CourseStoreNamespacedTypes } from "./modules/course";
import simulation, {
    SimulationStoreNamespacedTypes,
} from "./modules/simulation";
import breach, { BreachStoreNamespacedTypes } from "./modules/breach";
import statistics, {
    StatisticsStoreNamespacedTypes,
} from "./modules/statistics";
import quiz, { QuizStoreNamespacedTypes } from "./modules/quiz";
import multipleChoice, {
    MultipleChoiceStoreNamespacedTypes,
} from "./modules/multipleChoice";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        layout,
        auth,
        sso,
        locale,
        amplitude,
        mandatory,
        video,
        course,
        simulation,
        breach,
        statistics,
        quiz,
        multipleChoice,
    },
    plugins: [
        createPersistedState({
            key: "state",
            paths: ["layout", "auth", "locale"],
            storage: {
                getItem: (key) => getEncryptedStorageItem(key),
                setItem: (key, value) => setEncryptedStorageItem(key, value),
                removeItem: (key) => removeStorageItem(key),
            },
        }),
    ],
    strict: process.env.MIX_APP_ENV !== "production",
});

export const Types = {
    Layout: LayoutStoreNamespacedTypes,
    Auth: AuthStoreNamespacedTypes,
    Sso: SsoStoreNamespacedTypes,
    Locale: LocaleStoreNamespacedTypes,
    Amplitude: AmplitudeStoreNamespacedTypes,
    Mandatory: MandatoryStoreNamespacedTypes,
    Video: VideoStoreNamespacedTypes,
    Course: CourseStoreNamespacedTypes,
    Simulation: SimulationStoreNamespacedTypes,
    Breach: BreachStoreNamespacedTypes,
    Statistics: StatisticsStoreNamespacedTypes,
    Quiz: QuizStoreNamespacedTypes,
    MultipleChoice: MultipleChoiceStoreNamespacedTypes,
};
