/**
 * A store for sso.
 *
 * @typedef {{ ssoLoading: boolean, ssoError: ErrorOrObject }} SsoStoreState
 */
import { addNamespace } from "./namespace";
import { getData, postData } from "@/api";

/**
 * The types used in this store
 * @enum {string}
 */
export const SsoStoreTypes = {
    getters: {
        SSO_LOADING: "ssoLoading",
        SSO_ERROR: "ssoError",
    },
    actions: {
        GET_GRANT: "getGrant",
        VALIDATE_TOKEN: "validateToken",
    },
    mutations: {
        SET_SSO_LOADING: "setSsoLoading",
        SET_SSO_ERROR: "setSsoError",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const SsoStoreNamespacedTypes = addNamespace("sso", SsoStoreTypes);

/**
 * @returns {SsoStoreState}
 */
export function state() {
    return {
        ssoLoading: false,
        ssoError: null,
    };
}

export const getters = {
    [SsoStoreTypes.getters.SSO_LOADING]: (state) => () => {
        return state.ssoLoading;
    },
    [SsoStoreTypes.getters.SSO_ERROR]: (state) => () => {
        return state.ssoError;
    },
};

export const actions = {
    /**
     * Sends the given sso to the server.
     *
     * @param {VuexCommit} commit
     * @param {string} provider
     * @return {Promise}
     */
    [SsoStoreTypes.actions.GET_GRANT]({ commit }, provider) {
        commit(SsoStoreTypes.mutations.SET_SSO_ERROR, null);

        return getData("/auth/sso/grant/" + provider).catch((errors) => {
            commit(SsoStoreTypes.mutations.SET_SSO_ERROR, errors);
        });
    },

    /**
     * Sends the given sso to the server.
     *
     * @param {VuexCommit} commit
     * @param {string} code
     * @param {string} state
     * @return {Promise}
     */
    [SsoStoreTypes.actions.VALIDATE_TOKEN]({ commit }, { code, state }) {
        commit(SsoStoreTypes.mutations.SET_SSO_LOADING, true);
        commit(SsoStoreTypes.mutations.SET_SSO_ERROR, null);

        return postData("/auth/sso/validate", { code: code, state: state })
            .catch((errors) => {
                commit(SsoStoreTypes.mutations.SET_SSO_ERROR, errors);
            })
            .finally(() => {
                commit(SsoStoreTypes.mutations.SET_SSO_LOADING, false);
            });
    },
};

export const mutations = {
    /**
     * Sets the loading state for sso.
     *
     * @param {SsoStoreState} state
     * @param {boolean} loading
     */
    [SsoStoreTypes.mutations.SET_SSO_LOADING](state, loading) {
        state.ssoLoading = loading;
    },

    /**
     * Sets a new sso error.
     *
     * @param {SsoStoreState} state
     * @param {ErrorOrObject} error
     */
    [SsoStoreTypes.mutations.SET_SSO_ERROR](state, error) {
        state.ssoError = error;
    },
};

export default {
    namespaced: true,
    Types: SsoStoreTypes,
    NamespacedTypes: SsoStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
