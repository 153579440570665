import dayjs from "dayjs";
import localeDa from "dayjs/locale/da";

export function formatDateTime(date) {
    return dayjs(date).locale(localeDa).format("DD-MM-YYYY HH:mm");
}

export function formatDate(date) {
    return dayjs(date).locale(localeDa).format("DD-MM-YYYY");
}

export function formatTime(duration) {
    const m = Math.floor((duration % 3600) / 60)
            .toString()
            .padStart(2, "0"),
        s = Math.floor(duration % 60)
            .toString()
            .padStart(2, "0");

    return m + ":" + s;
}

export function isRecentEvent(date) {
    return dayjs(date).isAfter(dayjs().subtract(2, "minutes"));
}

export function isInNearFuture(date) {
    return dayjs(date).isBefore(dayjs().add(3, "days"));
}
