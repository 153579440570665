export const localeEn = "en_US";
export const localeDa = "da_DK";
export const localeDe = "de_DE";

export const locales = [localeEn, localeDa, localeDe];

export const QuestionTypes = {
    TYPE_MULTIPLE_CHOICE: "multiple-choice",
    TYPE_TRUE_FALSE: "true-false",
};

export const sensitivityMap = [
    { name: "Account balances", score: 50 },
    { name: "Address book contacts", score: 50 },
    { name: "Age groups", score: 25 },
    { name: "Ages", score: 25 },
    { name: "Apps installed on devices", score: 25 },
    { name: "Astrological signs", score: 25 },
    { name: "Audio recordings", score: 50 },
    { name: "Auth tokens", score: 100 },
    { name: "Avatars", score: 25 },
    { name: "Bank account numbers", score: 100 },
    { name: "Beauty ratings", score: 25 },
    { name: "Biometric data", score: 100 },
    { name: "Bios", score: 25 },
    { name: "Browser user agent details", score: 25 },
    { name: "Browsing histories", score: 25 },
    { name: "Buying preferences", score: 25 },
    { name: "Car ownership statuses", score: 25 },
    { name: "Career levels", score: 25 },
    { name: "Cellular network names", score: 25 },
    { name: "Charitable donations", score: 25 },
    { name: "Chat logs", score: 50 },
    { name: "Credit card CVV", score: 50 },
    { name: "Credit cards", score: 100 },
    { name: "Credit status information", score: 25 },
    { name: "Customer feedback", score: 25 },
    { name: "Customer interactions", score: 25 },
    { name: "Dates of birth", score: 50 },
    { name: "Deceased date", score: 25 },
    { name: "Deceased statuses", score: 25 },
    { name: "Device information", score: 25 },
    { name: "Device serial numbers", score: 25 },
    { name: "Device usage tracking data", score: 25 },
    { name: "Drinking habits", score: 25 },
    { name: "Drug habits", score: 25 },
    { name: "Eating habits", score: 25 },
    { name: "Education levels", score: 25 },
    { name: "Email addresses", score: 50 },
    { name: "Email messages", score: 25 },
    { name: "Employers", score: 25 },
    { name: "Employment statuses", score: 25 },
    { name: "Encrypted keys", score: 50 },
    { name: "Ethnicities", score: 25 },
    { name: "Family members' names", score: 25 },
    { name: "Family plans", score: 25 },
    { name: "Family structure", score: 25 },
    { name: "Financial investments", score: 25 },
    { name: "Financial transactions", score: 25 },
    { name: "Fitness levels", score: 25 },
    { name: "Genders", score: 25 },
    { name: "Geographic locations", score: 25 },
    { name: "Government issued IDs", score: 50 },
    { name: "Health insurance information", score: 50 },
    { name: "Historical passwords", score: 100 },
    { name: "Home loan information", score: 25 },
    { name: "Home ownership statuses", score: 25 },
    { name: "Homepage URLs", score: 25 },
    { name: "IMEI numbers", score: 50 },
    { name: "IMSI numbers", score: 50 },
    { name: "Income levels", score: 25 },
    { name: "Instant messenger identities", score: 25 },
    { name: "IP addresses", score: 25 },
    { name: "Job applications", score: 25 },
    { name: "Job titles", score: 25 },
    { name: "Living costs", score: 25 },
    { name: "Login histories", score: 50 },
    { name: "MAC addresses", score: 25 },
    { name: "Marital statuses", score: 25 },
    { name: "Mnemonic phrases", score: 25 },
    { name: "Mothers maiden names", score: 25 },
    { name: "Names", score: 25 },
    { name: "Nationalities", score: 25 },
    { name: "Net worths", score: 25 },
    { name: "Nicknames", score: 25 },
    { name: "Occupations", score: 25 },
    { name: "Parenting plans", score: 25 },
    { name: "Partial credit card data", score: 25 },
    { name: "Partial dates of birth", score: 25 },
    { name: "Passport numbers", score: 100 },
    { name: "Password hints", score: 25 },
    { name: "Password strengths", score: 25 },
    { name: "Passwords", score: 100 },
    { name: "Payment histories", score: 25 },
    { name: "Payment methods", score: 100 },
    { name: "Personal descriptions", score: 25 },
    { name: "Personal health data", score: 25 },
    { name: "Personal interests", score: 25 },
    { name: "Phone numbers", score: 50 },
    { name: "Photos", score: 25 },
    { name: "Physical addresses", score: 50 },
    { name: "Physical attributes", score: 50 },
    { name: "PINs", score: 50 },
    { name: "Places of birth", score: 25 },
    { name: "Political donations", score: 25 },
    { name: "Political views", score: 25 },
    { name: "Private messages", score: 25 },
    { name: "Professional skills", score: 25 },
    { name: "Profile photos", score: 25 },
    { name: "Purchases", score: 25 },
    { name: "Purchasing habits", score: 25 },
    { name: "Races", score: 25 },
    { name: "Recovery email addresses", score: 50 },
    { name: "Relationship statuses", score: 25 },
    { name: "Religions", score: 25 },
    { name: "Reward program balances", score: 25 },
    { name: "Salutations", score: 25 },
    { name: "School grades (class levels)", score: 25 },
    { name: "Security questions and answers", score: 50 },
    { name: "Sexual fetishes", score: 25 },
    { name: "Sexual orientations", score: 25 },
    { name: "Smoking habits", score: 25 },
    { name: "SMS messages", score: 50 },
    { name: "Social connections", score: 25 },
    { name: "Social media profiles", score: 25 },
    { name: "Social security numbers", score: 25 },
    { name: "Spoken languages", score: 25 },
    { name: "Spouses names", score: 25 },
    { name: "Support tickets", score: 25 },
    { name: "Survey results", score: 25 },
    { name: "Taxation records", score: 50 },
    { name: "Time zones", score: 25 },
    { name: "Travel habits", score: 25 },
    { name: "User statuses", score: 25 },
    { name: "User website URLs", score: 25 },
    { name: "Usernames", score: 50 },
    { name: "Utility bills", score: 25 },
    { name: "Vehicle details", score: 25 },
    { name: "Website activity", score: 25 },
    { name: "Work habits", score: 25 },
    { name: "Years of professional experience", score: 25 },
];
