<template>
    <g>
        <path
            fill="currentColor"
            d="M13.1,10.1c1.8,0,3,1.7,3,3.9v0c0,2.2-1.2,3.9-3,3.9c-1.8,0-3-1.7-3-3.9v0
  C10.1,11.8,11.3,10.1,13.1,10.1z M11.4,14c0,1.5,0.7,2.7,1.8,2.7c1.1,0,1.8-1.2,1.8-2.7v0c0-1.5-0.7-2.7-1.8-2.7
  C12,11.3,11.4,12.5,11.4,14L11.4,14z M22,14c0,6.1-4.9,11-11,11c-5.6,0-10.3-4.2-10.9-9.7C0,15.2,0,15.1,0,15c0-0.6,0.4-1,1-1
  c0.6,0,1,0.4,1,1h0c0.5,4.5,4.3,8,8.9,8c5,0,9-4,9-9s-4-9-9-9H9.4l1.3,1.3c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0l-3-3
  c-0.4-0.4-0.4-1,0-1.4l3-3c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4L9.4,3H11C17,3,22,7.9,22,14z M6.8,10.1C7.1,10.1,7.3,10,7.5,10h0
  c0.4,0,0.7,0.3,0.7,0.7v6.6c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7v-5.9L6,11.7c-0.1,0-0.2,0-0.3,0c-0.3,0-0.6-0.3-0.6-0.6
  c0-0.3,0.2-0.5,0.5-0.6L6.8,10.1z M3.4,4l2.3,2.3c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0l-3-3c-0.4-0.4-0.4-1,0-1.4l3-3
  c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4L3.4,4z"
        ></path>
    </g>
</template>

<script>
import IconSvgBase from "./IconSvgBase";

export default {
    extends: IconSvgBase,
    name: "IconRewind",
    data: () => ({
        viewBoxHeight: 20,
        viewBoxWidth: 25,
    }),
};
</script>
