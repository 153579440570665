/**
 * A store for handling the different amplitude in the app.
 *
 * @module store/modules/amplitude
 * @typedef {{debug: boolean, initialized: boolean, user: string|null}} AmplitudeStoreState
 */

import { addNamespace } from "./namespace";
import * as amplitude from "@amplitude/analytics-browser";
import { getProperty, removeEmptyValues } from "@/utils/object";

/**
 * The types used in this store
 * @enum {string}
 */
export const AmplitudeStoreTypes = {
    getters: {
        DEBUG: "debug",
        INITIALIZED: "initialized",
    },
    actions: {
        INIT_AMPLITUDE: "initAmplitude",
        SET_AMPLITUDE_USER: "setAmplitudeUser",
        TRACK_AMPLITUDE_EVENT: "trackAmplitudeEvent",
    },
    mutations: {
        SET_DEBUG: "setDebug",
        SET_INITIALIZED: "setInitialized",
        SET_USER: "setUser",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const AmplitudeStoreNamespacedTypes = addNamespace(
    "amplitude",
    AmplitudeStoreTypes
);

/**
 * @returns {AmplitudeStoreState}
 */
export function state() {
    return {
        debug: false,
        initialized: false,
        user: null,
    };
}

export const getters = {
    [AmplitudeStoreTypes.getters.DEBUG]: (state) => () => {
        return state.debug;
    },
};

export const actions = {
    /**
     * Sets the amplitude interface
     *
     * @param {AmplitudeStoreState} state
     * @param {VuexCommit} commit
     * @param {VuexDispatch} dispatch
     * @param {boolean} debug
     * @param {Object} user
     */
    [AmplitudeStoreTypes.actions.INIT_AMPLITUDE](
        { state, commit, dispatch },
        { debug = false, user = {} }
    ) {
        if (state.initialized) {
            return;
        }

        let isDebugging = "true" === debug;
        const user_id = getProperty(user, "id", undefined);

        amplitude.init(process.env.VUE_APP_AMPLITUDE_KEY, user_id, {
            serverZone: amplitude.Types.ServerZone.EU,
            appVersion: process.env.VUE_APP_VERSION,
            logLevel: isDebugging
                ? amplitude.Types.LogLevel.Warn
                : amplitude.Types.LogLevel.None,
            trackingOptions: {
                deviceManufacturer: true,
                deviceModel: true,
                ipAddress: false,
                language: true,
                osName: true,
                osVersion: true,
                platform: true,
            },
            defaultTracking: true,
        });

        commit(AmplitudeStoreTypes.mutations.SET_DEBUG, isDebugging);
        commit(AmplitudeStoreTypes.mutations.SET_INITIALIZED, true);

        if (user_id !== undefined) {
            dispatch(AmplitudeStoreTypes.actions.SET_AMPLITUDE_USER, user);
        }
    },

    /**
     * Tracks a amplitude event
     *
     * @param {AmplitudeStoreState} state
     * @param {VuexCommit} commit
     * @param {VuexDispatch} dispatch
     * @param {Object} user
     */
    async [AmplitudeStoreTypes.actions.SET_AMPLITUDE_USER](
        { state, commit, dispatch },
        user = {}
    ) {
        if (state.user) {
            return;
        }

        if (!state.initialized) {
            await dispatch(AmplitudeStoreTypes.actions.INIT_AMPLITUDE, {
                debug: state.debug,
            });
        }

        const user_id = getProperty(user, "id");
        const workspace_id = getProperty(user, "workspace_id");

        if (user_id) {
            commit(AmplitudeStoreTypes.mutations.SET_USER, user_id);

            const identifyObj = new amplitude.Identify();

            if (workspace_id) {
                identifyObj.set("workspace", workspace_id);
            }

            return amplitude.identify(identifyObj, {
                user_id: user_id,
            });
        }
    },

    /**
     * Tracks a amplitude event
     *
     * @param {AmplitudeStoreState} state
     * @param {VuexDispatch} dispatch
     * @param {string} event
     * @param {Object} properties
     */
    async [AmplitudeStoreTypes.actions.TRACK_AMPLITUDE_EVENT](
        { state, dispatch },
        { event, properties = {} }
    ) {
        if (!state.initialized) {
            await dispatch(AmplitudeStoreTypes.actions.INIT_AMPLITUDE, {
                debug: state.debug,
            });
        }

        return amplitude.track("hub_" + event, removeEmptyValues(properties));
    },
};

export const mutations = {
    /**
     * Sets the debug mode
     *
     * @param {AmplitudeStoreState} state
     * @param {boolean} debug
     */
    [AmplitudeStoreTypes.mutations.SET_DEBUG](state, debug) {
        state.debug = debug;
    },

    /**
     * If the amplitude is initialized
     *
     * @param {AmplitudeStoreState} state
     * @param {boolean} initialized
     */
    [AmplitudeStoreTypes.mutations.SET_INITIALIZED](state, initialized) {
        state.initialized = initialized;
    },

    /**
     * Sets the user id
     *
     * @param {AmplitudeStoreState} state
     * @param {string|null} userId
     */
    [AmplitudeStoreTypes.mutations.SET_USER](state, userId) {
        state.user = userId;
    },
};

export default {
    namespaced: true,
    Types: AmplitudeStoreTypes,
    NamespacedTypes: AmplitudeStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
