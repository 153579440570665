/**
 * A store for handling auth (login, register and password resets).
 *
 * @typedef {{ id: string|null, workspace_id: string|null, first_name: string|null, email: string|null, avatar: string|null, score: Object, point_count: number|null, progress: number|null, streak: array|null, onboarded: boolean, locale: string|null }} UserData
 * @typedef {{ csrfReady: boolean, csrfError: ErrorOrObject, csrfPromise: Promise|null, token: string|null, user: UserData|null, features: array, loginLoading: boolean, loginError: ErrorOrObject}} AuthStoreState
 */

import { getData, postData, setAuthToken, fetchCsrfCookie } from "@/api";
import { addNamespace } from "./namespace";
import { updateQuery, redirectTo, redirectToPath } from "@/router";
import { getProperty } from "@/utils/object";

/**
 * The types used in this store
 * @enum {string}
 */
export const AuthStoreTypes = {
    getters: {
        CSRF_ERROR: "csrfError",
        HAS_CSRF_COOKIE: "hasCsrfCookie",
        IS_LOGGED_IN: "isLoggedIn",
        TOKEN: "token",
        USER: "user",
        FEATURES: "features",
        WATCHED_VIDEOS: "watchedVideos",
        MANDATORY_TRAINING: "mandatoryTraining",
        LOGIN_LOADING: "loginLoading",
        LOGIN_ERROR: "loginError",
        GATE_LOADING: "gateLoading",
        GATE_ERROR: "gateError",
        POINT_ERROR: "pointError",
    },
    actions: {
        FETCH_CSRF_COOKIE: "fetchCsrfCookie",
        LOGIN: "login",
        VALIDATE_CODE: "validateCode",
        CLEAR_SESSION: "clearSession",
        VALIDATE_GATE: "validateGate",
        LOGOUT: "logout",
        CHECK_CURRENT_SESSION: "checkForCurrentSession",
        FETCH_SESSION: "fetchSession",
        ASSIGN_POINTS: "assignPoints",
        CHECK_ONBOARDING_STATUS: "checkOnboardingStatus",
        UPDATE_ONBOARDING_STATE: "updateOnboardingState",
    },
    mutations: {
        CLEAR_CSRF_ERROR: "clearCsrfError",
        SET_CSRF_ERROR: "setCsrfError",
        SET_CSRF_PROMISE: "setCsrfPromise",
        SET_CSRF_READY: "setCsrfReady",
        SET_TOKEN: "setToken",
        SET_USER: "setUser",
        SET_FEATURES: "setFeatures",
        SET_WATCHED_VIDEOS: "setWatchedVideos",
        ADD_WATCHED_VIDEO: "addWatchedVideo",
        SET_MANDATORY_STATUS: "setMandatoryStatus",
        SET_MANDATORY_TRAINING: "setMandatoryTraining",
        SET_ONBOARDED: "setOnboarded",
        SET_LOGIN_LOADING: "setLoginLoading",
        SET_LOGIN_ERROR: "setLoginError",
        SET_GATE_LOADING: "setGateLoading",
        SET_GATE_ERROR: "setGateError",
        SET_POINT_ERROR: "setPointError",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const AuthStoreNamespacedTypes = addNamespace("auth", AuthStoreTypes);

/**
 * @returns {AuthStoreState}
 */
export function state() {
    return {
        csrfReady: false,
        csrfError: null,
        csrfPromise: null,
        token: null,
        user: {
            id: null,
            workspace_id: null,
            first_name: null,
            last_name: null,
            full_name: null,
            email: null,
            avatar: null,
            score: {
                simulations: 0,
                quizzes: 0,
                skills: 0,
                total: 0,
                videos: 0,
            },
            point_count: 0,
            progress: 0,
            onboarded: true,
            locale: "en_US",
        },
        features: [],
        watched_videos: [],
        mandatory_training: false,
        loginLoading: false,
        loginError: null,
        gateLoading: false,
        gateError: null,
        pointError: null,
    };
}

export const getters = {
    [AuthStoreTypes.getters.CSRF_ERROR]: (state) => () => {
        return state.csrfError;
    },
    [AuthStoreTypes.getters.HAS_CSRF_COOKIE]: (state) => () => {
        return state.csrfReady;
    },
    [AuthStoreTypes.getters.IS_LOGGED_IN]: (state) => () => {
        return state.user.id !== null;
    },
    [AuthStoreTypes.getters.TOKEN]: (state) => () => {
        return state.token;
    },
    [AuthStoreTypes.getters.USER]: (state) => () => {
        return state.user;
    },
    [AuthStoreTypes.getters.FEATURES]: (state) => () => {
        return state.features;
    },
    [AuthStoreTypes.getters.WATCHED_VIDEOS]: (state) => () => {
        return state.watched_videos;
    },
    [AuthStoreTypes.getters.MANDATORY_TRAINING]: (state) => () => {
        return state.mandatory_training;
    },
    [AuthStoreTypes.getters.LOGIN_LOADING]: (state) => () => {
        return state.loginLoading;
    },
    [AuthStoreTypes.getters.LOGIN_ERROR]: (state) => () => {
        return state.loginError;
    },
    [AuthStoreTypes.getters.GATE_LOADING]: (state) => () => {
        return state.gateLoading;
    },
    [AuthStoreTypes.getters.GATE_ERROR]: (state) => () => {
        return state.gateError;
    },
    [AuthStoreTypes.getters.POINT_ERROR]: (state) => () => {
        return state.pointError;
    },
};

export const actions = {
    /**
     * Fetches a CSRF protection cookie.
     * Returns a promise which will resolve once the cookie has been fetched.
     *
     * @param {VuexCommit} commit
     * @returns {Promise}
     */
    [AuthStoreTypes.actions.FETCH_CSRF_COOKIE]({ commit }) {
        commit(AuthStoreTypes.mutations.SET_CSRF_READY, false);
        commit(AuthStoreTypes.mutations.CLEAR_CSRF_ERROR);

        const csrfPromise = fetchCsrfCookie()
            .then(() => {
                commit(AuthStoreTypes.mutations.SET_CSRF_READY, true);
            })
            .catch((error) => {
                commit(AuthStoreTypes.mutations.SET_CSRF_ERROR, error);
            });

        commit(AuthStoreTypes.mutations.SET_CSRF_PROMISE, csrfPromise);

        return csrfPromise;
    },

    /**
     * Sends the given credentials to the server and updates the store with the returned login data.
     *
     * @param {VuexCommit} commit
     * @param {VuexDispatch} dispatch
     * @param {VuexGetters} getters
     * @param {AuthStoreState} state
     * @param {Object} credentials
     * @return {Promise}
     */
    async [AuthStoreTypes.actions.LOGIN](
        { commit, dispatch, getters, state },
        credentials
    ) {
        commit(AuthStoreTypes.mutations.SET_LOGIN_LOADING, true);
        commit(AuthStoreTypes.mutations.SET_LOGIN_ERROR, null);

        // Make sure we have a valid CSRF cookie before submitting any data
        if (state.csrfPromise === null) {
            await dispatch(AuthStoreTypes.actions.FETCH_CSRF_COOKIE);
        }

        await state.csrfPromise;

        if (!getters[AuthStoreTypes.getters.HAS_CSRF_COOKIE]()) {
            commit(AuthStoreTypes.mutations.SET_LOGIN_LOADING, false);
            return commit(AuthStoreTypes.mutations.SET_CSRF_ERROR, {
                message: "api.messages.csrf",
            });
        }

        return postData("/auth/login", credentials)
            .catch((errors) => {
                commit(AuthStoreTypes.mutations.SET_LOGIN_ERROR, errors);
            })
            .finally(() => {
                commit(AuthStoreTypes.mutations.SET_LOGIN_LOADING, false);
            });
    },

    /**
     * Sends the given credentials to the server and updates the store with the returned login data.
     *
     * @param {VuexCommit} commit
     * @param {VuexDispatch} dispatch
     * @param {Object} credentials
     * @return {Promise}
     */
    [AuthStoreTypes.actions.VALIDATE_CODE]({ commit, dispatch }, credentials) {
        commit(AuthStoreTypes.mutations.SET_LOGIN_LOADING, true);
        commit(AuthStoreTypes.mutations.SET_LOGIN_ERROR, null);

        return postData("/auth/code", credentials)
            .then(({ data }) => {
                commit(AuthStoreTypes.mutations.SET_TOKEN, data.token);
                commit(AuthStoreTypes.mutations.SET_USER, data.user);
                commit(AuthStoreTypes.mutations.SET_FEATURES, data.features);
                commit(
                    AuthStoreTypes.mutations.SET_WATCHED_VIDEOS,
                    data.watched_videos
                );
                commit(
                    AuthStoreTypes.mutations.SET_MANDATORY_TRAINING,
                    data.mandatory_training
                );

                dispatch(AuthStoreTypes.actions.CHECK_ONBOARDING_STATUS).then(
                    () => {}
                );
            })
            .catch((errors) => {
                commit(AuthStoreTypes.mutations.SET_LOGIN_ERROR, errors);
            })
            .finally(() => {
                commit(AuthStoreTypes.mutations.SET_LOGIN_LOADING, false);
            });
    },

    /**
     * Logs out the user and clears the user data from the store.
     *
     * @param {VuexCommit} commit
     * @param auto
     */
    [AuthStoreTypes.actions.CLEAR_SESSION]({ commit }) {
        commit(AuthStoreTypes.mutations.SET_USER, {
            id: null,
            workspace_id: null,
            first_name: null,
            last_name: null,
            full_name: null,
            email: null,
            avatar: null,
            score: {
                simulations: 0,
                quizzes: 0,
                skills: 0,
                total: 0,
                videos: 0,
            },
            point_count: 0,
            progress: 0,
            onboarded: false,
            locale: "en_US",
        });
        commit(AuthStoreTypes.mutations.SET_TOKEN, null);
        commit(AuthStoreTypes.mutations.SET_FEATURES, []);
        commit(AuthStoreTypes.mutations.SET_WATCHED_VIDEOS, []);
        commit(AuthStoreTypes.mutations.SET_MANDATORY_TRAINING, false);
        localStorage.clear();
    },

    /**
     * Sends the given credentials to the server and updates the store with the returned login data.
     *
     * @param {VuexDispatch} dispatch
     * @param {VuexCommit} commit
     * @param {AuthStoreState} state
     * @param {Object} params
     * @return {Promise}
     */
    async [AuthStoreTypes.actions.VALIDATE_GATE](
        { dispatch, commit, state },
        params
    ) {
        commit(AuthStoreTypes.mutations.SET_GATE_LOADING, true);
        commit(AuthStoreTypes.mutations.SET_GATE_ERROR, null);

        // Make sure we have a valid CSRF cookie before submitting any data
        if (state.csrfPromise === null) {
            await dispatch(AuthStoreTypes.actions.FETCH_CSRF_COOKIE);
        }

        await state.csrfPromise;

        if (!getters[AuthStoreTypes.getters.HAS_CSRF_COOKIE]()) {
            commit(AuthStoreTypes.mutations.SET_GATE_LOADING, false);
            return commit(AuthStoreTypes.mutations.SET_GATE_ERROR, {
                message: "api.messages.csrf",
            });
        }

        dispatch(AuthStoreTypes.actions.CLEAR_SESSION).then(() => {
            commit(
                AuthStoreTypes.mutations.SET_TOKEN,
                getProperty(params, "state", null)
            );
            return getData("/session")
                .then(({ data }) => {
                    commit(AuthStoreTypes.mutations.SET_USER, data.user);
                    commit(
                        AuthStoreTypes.mutations.SET_FEATURES,
                        data.features
                    );
                    commit(
                        AuthStoreTypes.mutations.SET_WATCHED_VIDEOS,
                        data.watched_videos
                    );
                    commit(
                        AuthStoreTypes.mutations.SET_MANDATORY_TRAINING,
                        data.mandatory_training
                    );

                    const redirect = getProperty(params, "redirect", null);
                    if (redirect) {
                        dispatch(
                            AuthStoreTypes.actions.UPDATE_ONBOARDING_STATE
                        ).then(() => {
                            redirectToPath(redirect);
                        });
                    } else {
                        dispatch(
                            AuthStoreTypes.actions.CHECK_ONBOARDING_STATUS
                        ).then(() => {
                            updateQuery({ gate: "validated" });
                        });
                    }
                })
                .catch((errors) => {
                    commit(AuthStoreTypes.mutations.SET_GATE_ERROR, errors);
                })
                .finally(() => {
                    commit(AuthStoreTypes.mutations.SET_GATE_LOADING, false);
                });
        });
    },

    /**
     * Logs out the user and clears the user data from the store.
     *
     * @param {VuexDispatch} dispatch
     */
    [AuthStoreTypes.actions.LOGOUT]({ dispatch }) {
        dispatch(AuthStoreTypes.actions.CLEAR_SESSION).then(() => {
            redirectTo("overview");
        });
    },

    /**
     * Validates a users session upon page refresh.
     *
     * @param {AuthStoreState} state
     * @param {VuexCommit} commit
     * @param {VuexDispatch} dispatch
     * @return {Promise}
     */
    [AuthStoreTypes.actions.CHECK_CURRENT_SESSION]({
        state,
        commit,
        dispatch,
    }) {
        commit(AuthStoreTypes.mutations.SET_GATE_LOADING, true);
        commit(AuthStoreTypes.mutations.SET_GATE_ERROR, null);
        commit(AuthStoreTypes.mutations.SET_TOKEN, state.token);

        return getData("/session")
            .then(({ data }) => {
                commit(AuthStoreTypes.mutations.SET_USER, data.user);
                commit(AuthStoreTypes.mutations.SET_FEATURES, data.features);
                commit(
                    AuthStoreTypes.mutations.SET_WATCHED_VIDEOS,
                    data.watched_videos
                );
                commit(
                    AuthStoreTypes.mutations.SET_MANDATORY_TRAINING,
                    data.mandatory_training
                );
                dispatch(AuthStoreTypes.actions.CHECK_ONBOARDING_STATUS).then(
                    () => {}
                );
            })
            .catch((errors) => {
                commit(AuthStoreTypes.mutations.SET_GATE_ERROR, errors);
            })
            .finally(() => {
                commit(AuthStoreTypes.mutations.SET_GATE_LOADING, false);
            });
    },

    /**
     * Validates a users session upon page refresh.
     *
     * @param {VuexCommit} commit
     * @return {Promise}
     */
    [AuthStoreTypes.actions.FETCH_SESSION]({ commit }) {
        return getData("/session").then(({ data }) => {
            commit(AuthStoreTypes.mutations.SET_USER, data.user);
            commit(AuthStoreTypes.mutations.SET_FEATURES, data.features);
            commit(
                AuthStoreTypes.mutations.SET_WATCHED_VIDEOS,
                data.watched_videos
            );
            commit(
                AuthStoreTypes.mutations.SET_MANDATORY_TRAINING,
                data.mandatory_training
            );
        });
    },

    /**
     * @param {VuexCommit} commit
     * @param {string} type
     * @param {string} id
     * @return {Promise}
     */
    [AuthStoreTypes.actions.ASSIGN_POINTS]({ commit }, { type, id }) {
        return postData("/points", { type, id })
            .then(({ data }) => {
                commit(AuthStoreTypes.mutations.SET_USER, data.user);

                if (type === "watched_video") {
                    commit(AuthStoreTypes.mutations.ADD_WATCHED_VIDEO, id);
                }
            })
            .catch((errors) => {
                commit(AuthStoreTypes.mutations.SET_POINT_ERROR, errors);
            });
    },

    /**
     * @param {AuthStoreState} state
     */
    [AuthStoreTypes.actions.CHECK_ONBOARDING_STATUS]({ state }) {
        if (!state.user.onboarded) {
            redirectTo("onboarding");
        }
    },

    /**
     * @param {VuexCommit} commit
     */
    [AuthStoreTypes.actions.UPDATE_ONBOARDING_STATE]({ commit }) {
        commit(AuthStoreTypes.mutations.SET_ONBOARDED, true);
    },
};

export const mutations = {
    [AuthStoreTypes.mutations.SET_CSRF_ERROR](state, error) {
        state.csrfError = error;
    },
    [AuthStoreTypes.mutations.SET_CSRF_PROMISE](state, promise) {
        state.csrfPromise = promise;
    },
    [AuthStoreTypes.mutations.SET_CSRF_READY](state, ready) {
        state.csrfReady = ready;
    },
    [AuthStoreTypes.mutations.CLEAR_CSRF_ERROR](state) {
        state.csrfError = null;
    },
    /**
     * Sets the user token and updates axios bearer token.
     *
     * @param {AuthStoreState} state
     * @param {string} token
     */
    [AuthStoreTypes.mutations.SET_TOKEN](state, token) {
        state.token = token;
        setAuthToken(token);
    },

    /**
     * Sets user data.
     *
     * @param {AuthStoreState} state
     * @param {string|null} id
     * @param {string|null} workspace_id
     * @param {string|null} first_name
     * @param {string|null} last_name
     * @param {string|null} full_name
     * @param {string|null} email
     * @param {string|null} avatar
     * @param {Object} score
     * @param {number|null} point_count
     * @param {number|null} progress
     * @param {boolean} onboarded
     * @param {string|null} locale
     */
    [AuthStoreTypes.mutations.SET_USER](
        state,
        {
            id = null,
            workspace_id = null,
            first_name = null,
            last_name = null,
            full_name = null,
            email = null,
            avatar = null,
            score = {
                simulations: 0,
                quizzes: 0,
                skills: 0,
                total: 0,
                videos: 0,
            },
            point_count = 0,
            progress = 0,
            onboarded = false,
            locale = null,
        }
    ) {
        state.user.id = id;
        state.user.workspace_id = workspace_id;
        state.user.first_name = first_name;
        state.user.last_name = last_name;
        state.user.full_name = full_name;
        state.user.email = email;
        state.user.avatar = avatar;
        state.user.score = score;
        state.user.point_count = point_count;
        state.user.progress = progress;
        state.user.onboarded = onboarded;
        state.user.locale = locale;
    },

    /**
     * Sets the features state for the user.
     *
     * @param {AuthStoreState} state
     * @param {array} features
     */
    [AuthStoreTypes.mutations.SET_FEATURES](state, features) {
        state.features = features;
    },

    /**
     * Sets the watched videos state for the user.
     *
     * @param {AuthStoreState} state
     * @param {array} videos
     */
    [AuthStoreTypes.mutations.SET_WATCHED_VIDEOS](state, videos) {
        state.watched_videos = videos;
    },

    /**
     * Sets the watched videos state for the user.
     *
     * @param {AuthStoreState} state
     * @param {string} video
     */
    [AuthStoreTypes.mutations.ADD_WATCHED_VIDEO](state, video) {
        state.watched_videos.push(video);
    },

    /**
     * Sets the mandatory training state for the user.
     *
     * @param {AuthStoreState} state
     * @param {boolean} status
     */
    [AuthStoreTypes.mutations.SET_MANDATORY_STATUS](state, status) {
        state.mandatory_training = status;
    },

    /**
     * Sets the mandatory training state for the user.
     *
     * @param {AuthStoreState} state
     * @param {boolean} mandatoryTraining
     */
    [AuthStoreTypes.mutations.SET_MANDATORY_TRAINING](
        state,
        mandatoryTraining
    ) {
        state.mandatory_training = mandatoryTraining;
    },

    /**
     * Sets the onboarded state for the user.
     *
     * @param {AuthStoreState} state
     * @param {boolean} isOnboarded
     */
    [AuthStoreTypes.mutations.SET_ONBOARDED](state, isOnboarded) {
        state.user.onboarded = isOnboarded;
    },

    /**
     * Sets the loading state for the login form.
     *
     * @param {AuthStoreState} state
     * @param {boolean} loading
     */
    [AuthStoreTypes.mutations.SET_LOGIN_LOADING](state, loading) {
        state.loginLoading = loading;
    },

    /**
     * Sets a new login error.
     *
     * @param {AuthStoreState} state
     * @param {ErrorOrObject} error
     */
    [AuthStoreTypes.mutations.SET_LOGIN_ERROR](state, error) {
        state.loginError = error;
    },

    /**
     * Sets the loading state for the gate.
     *
     * @param {AuthStoreState} state
     * @param {boolean} loading
     */
    [AuthStoreTypes.mutations.SET_GATE_LOADING](state, loading) {
        state.gateLoading = loading;
    },

    /**
     * Sets a new gate error.
     *
     * @param {AuthStoreState} state
     * @param {ErrorOrObject} error
     */
    [AuthStoreTypes.mutations.SET_GATE_ERROR](state, error) {
        state.gateError = error;
    },

    /**
     * Sets a point error.
     *
     * @param {AuthStoreState} state
     * @param {ErrorOrObject} error
     */
    [AuthStoreTypes.mutations.SET_POINT_ERROR](state, error) {
        state.pointError = error;
    },
};

export default {
    namespaced: true,
    Types: AuthStoreTypes,
    NamespacedTypes: AuthStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
