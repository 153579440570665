<template>
    <div class="relative flex items-center">
        <div ref="trigger" @click="openDropdown">
            <slot />
        </div>
        <div
            :class="[
                position,
                open
                    ? 'pointer-events-auto z-[20] scale-100 opacity-100'
                    : 'pointer-events-none -z-[1] scale-90 opacity-0',
            ]"
            class="absolute flex flex-col rounded border border-slate-300 bg-white transition-all duration-300"
        >
            <div v-for="item in items" :key="item.id">
                <div
                    @click="chooseItem(item)"
                    class="flex cursor-pointer select-none justify-start whitespace-nowrap py-2 pl-2 pr-6 text-sm font-medium text-slate-700 transition duration-300 hover:bg-slate-50 hover:text-slate-800"
                >
                    <Icon :icon="item.icon" class="mr-2 size-5" />
                    {{ item.label }}
                </div>
                <div
                    v-if="item.show && item.type === 'separator'"
                    class="my-[6px] border-t border-slate-200"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from "@/components/icons/BaseIcon";

export default {
    name: "DropdownMenu",
    components: {
        Icon,
    },
    props: {
        id: {
            type: String,
            required: false,
            default: "Menu",
        },
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data: () => ({
        open: false,
        position: "",
    }),
    watch: {
        items() {
            this.setOrientation();
        },
    },
    methods: {
        openDropdown() {
            this.open = true;
            this.$emit("open");
        },
        chooseItem(item) {
            this.open = false;
            this.$emit("choose", item);
        },
        nearBottom() {
            let vertical =
                this.rectangle().bottom +
                (window.pageYOffset || document.body.scrollTop);
            return (
                document.body.scrollHeight -
                    vertical -
                    this.items.length * 30 <=
                10
            );
        },
        nearLeft() {
            return (
                this.rectangle().left +
                    (window.pageXOffset || document.body.scrollLeft) <=
                150
            );
        },
        rectangle() {
            return this.$refs.trigger.getBoundingClientRect();
        },
        setOrientation() {
            if (this.nearLeft() && this.nearBottom()) {
                this.position = "origin-bottom-left left-0 bottom-10";
            } else if (this.nearLeft()) {
                this.position = "origin-top-left left-0 top-10";
            } else if (this.nearBottom()) {
                this.position = "origin-bottom-right right-0 bottom-10";
            } else {
                this.position = "origin-top-right right-0 top-10";
            }
        },
    },
    mounted() {
        this.setOrientation();
    },
    created() {
        window.addEventListener("click", (e) => {
            if (!this.$el.contains(e.target)) {
                this.open = false;
            }
        });
    },
};
</script>
