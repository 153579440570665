import i18n from "../lang";
import { getProperty, snakeToCamelKeys } from "./object";

export const generateErrorMessage = (error) => {
    if (error.response) {
        // Handle common network responses
        switch (error.response.status) {
            case 401: // Not logged in
            case 419: // Session expired
            case 503: // Down for maintenance
                return "api.errors.session";
            case 422:
                return "api.errors.validation";
            case 500:
                return "api.errors.network";
        }

        return "api.errors.unknown";
    } else if (error.request) {
        return "api.errors.noResponse";
    }

    return "api.errors.network";
};

export const extractErrorObject = (error) => {
    let response = {};

    // Determine the type of the error
    switch (typeof error) {
        case "object":
            {
                const laravelErrorMessage = getProperty(
                    error,
                    "response.data.message"
                );

                if (laravelErrorMessage) {
                    let errors = [];

                    if (error.response.status === 422) {
                        // Validation error
                        const validationErrorMap = getProperty(
                            error,
                            "response.data.errors",
                            []
                        );

                        Object.keys(validationErrorMap).forEach((key) => {
                            validationErrorMap[key].forEach((subError) => {
                                errors.push({
                                    field: key,
                                    error: i18n.t(
                                        "validations.messages." + subError,
                                        {
                                            _field_: i18n.t(
                                                "validations.fields." + key
                                            ),
                                        }
                                    ),
                                });
                            });
                        });
                    }

                    // Create a response with no translation
                    response = {
                        ignoreI18n: true,
                        message: laravelErrorMessage,
                        status: error.response.status,
                        errors,
                    };
                }
            }

            break;
        case "string":
            response.message = error;
            break;
    }

    if (!response.ignoreI18n) {
        if (response.message) {
            response.message = i18n.t(
                response.message,
                snakeToCamelKeys(response.data)
            );
        } else {
            response.message = generateErrorMessage(error);
        }
    }

    return response;
};
