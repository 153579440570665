<template>
    <span class="animated-n">{{ formattedDisplayValue }}</span>
</template>

<script>
export default {
    name: "AnimatedNumber",
    props: {
        value: {
            type: Number,
            required: true,
        },
        initialValue: {
            type: Number,
            default: 0,
        },
        animationThreshold: {
            type: Number,
            default: 1,
        },
        decimals: {
            type: Number,
            default: 0,
        },
        duration: {
            type: Number,
            default: 1500,
        },
        ease: {
            type: String,
            default: "linear",
            validator: (value) => ["linear", "in-out"].includes(value),
        },
    },
    data: () => ({
        animationFrameRequest: null,
        animationStartTime: 0,
        oldDisplayValue: 0,
        displayValue: 0,
    }),
    computed: {
        formattedDisplayValue() {
            return this.displayValue.toFixed(this.decimals);
        },
    },
    watch: {
        value(newValue, oldValue) {
            if (
                !this.animationFrameRequest &&
                Math.abs(oldValue - newValue) <= this.animationThreshold
            ) {
                this.oldDisplayValue = this.displayValue = newValue;
            } else {
                this.startAnimation();
            }
        },
    },
    methods: {
        stopAnimation() {
            cancelAnimationFrame(this.animationFrameRequest);
        },
        startAnimation() {
            this.stopAnimation();
            this.oldDisplayValue = this.displayValue;
            this.animationStartTime = 0;
            this.animationTick(this.animationStartTime);
        },
        animationTick(timestamp) {
            if (this.animationStartTime === 0) {
                this.animationStartTime = timestamp;
            }

            const animationPercentage = this.applyEasing(
                Math.min(
                    (timestamp - this.animationStartTime) / this.duration,
                    1
                )
            );

            const valueDiff = this.value - this.oldDisplayValue;

            this.displayValue =
                this.oldDisplayValue + animationPercentage * valueDiff;

            if (timestamp < this.animationStartTime + this.duration) {
                this.animationFrameRequest = requestAnimationFrame(
                    this.animationTick.bind(this)
                );
            } else {
                this.animationFrameRequest = null;
            }
        },
        inOut(k) {
            if ((k *= 2) < 1) return -0.5 * (Math.sqrt(1 - k * k) - 1);
            return 0.5 * (Math.sqrt(1 - (k -= 2) * k) + 1);
        },
        applyEasing(value) {
            switch (this.ease) {
                case "linear":
                    return value;
                case "in-out":
                    return this.inOut(value);
                default:
                    throw `Unknown easing method: ${value}`;
            }
        },
    },
    mounted() {
        this.oldDisplayValue = this.initialValue;
        this.displayValue = this.initialValue;

        this.startAnimation();
    },
    beforeDestroy() {
        this.stopAnimation();
    },
};
</script>
