<template>
    <component
        class="form-button inline-flex cursor-pointer items-center justify-center rounded-md border px-4 py-2 font-medium shadow-sm transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-75"
        :class="[
            {
                'is-pressed': pressed,
                'has-content': hasContentInSlot,
            },
            variantClasses,
            sizeClasses,
        ]"
        :disabled="disabled || loading"
        :href="href"
        :is="tagName"
        :title="title"
        :type="buttonType"
        :to="to"
        @click="onClick"
    >
        <svg
            v-if="loading"
            :class="{ 'mr-2': hasContentInSlot }"
            class="h-4 w-4 animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
        >
            <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
            ></circle>
            <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
        </svg>
        <Icon
            v-if="icon && !loading"
            :icon="icon"
            class="h-4 w-4"
            :class="{ 'mr-2': hasContentInSlot }"
        />
        <slot />
    </component>
</template>

<script>
export const ButtonVariants = {
    PRIMARY: "primary",
    SECONDARY: "secondary",
    DARK: "dark",
};

export const ButtonSizeVariants = {
    XL: "xl",
    DEFAULT: "default",
    SM: "sm",
};

import Icon from "@/components/icons/BaseIcon";
import { IconEnums } from "@/utils/icons";

export default {
    name: "FormButton",
    components: {
        Icon,
    },
    props: {
        href: {
            type: String,
            required: false,
        },
        to: {
            type: Object,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        pressed: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "button",
        },
        loading: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: ButtonVariants.PRIMARY,
            validator(type) {
                return Object.values(ButtonVariants).includes(type);
            },
        },
        size: {
            type: String,
            default: ButtonSizeVariants.DEFAULT,
            validator(type) {
                return Object.values(ButtonSizeVariants).includes(type);
            },
        },
        icon: {
            type: Object,
            required: false,
            validator(IconEnum) {
                return Object.values(IconEnums).includes(IconEnum);
            },
        },
    },
    computed: {
        isLink() {
            return !!(this.href || this.to);
        },
        tagName() {
            if (this.to) {
                return "router-link";
            }
            if (this.href) {
                return "a";
            }
            return "button";
        },
        buttonType() {
            return this.isLink ? null : this.type;
        },
        hasContentInSlot() {
            return !!(this.$slots.default && this.$slots.default[0]);
        },
        sizeClasses() {
            if (this.size === ButtonSizeVariants.XL) {
                return "px-5 py-3 text-base";
            }

            if (this.size === ButtonSizeVariants.SM) {
                return "px-3 py-1 text-sm";
            }

            return "px-4 py-2 text-sm";
        },
        variantClasses() {
            if (this.disabled) {
                return "border-slate-200 text-slate-700 bg-slate-200 cursor-not-allowed";
            }

            if (this.variant === ButtonVariants.SECONDARY) {
                return "border-slate-300 text-slate-800 bg-white hover:bg-slate-100 focus:ring-offset-slate-200 focus:ring-slate-400";
            }

            if (this.variant === ButtonVariants.DARK) {
                return "cursor-pointer border-slate-700 text-slate-300 bg-slate-800 hover:bg-slate-700 hover:text-white focus:ring-offset-slate-500 focus:ring-slate-600";
            }

            return "border-transparent text-white bg-slate-600 hover:bg-slate-700 focus:ring-offset-slate-100 focus:ring-slate-400";
        },
    },
    methods: {
        onClick(event) {
            this.$emit("click", event);
        },
    },
};
</script>
