<template>
    <ValidationObserver ref="loginForm" v-slot="{ passes }">
        <form @submit.prevent="passes(login)">
            <FormFieldSet>
                <Alert :error="csrfError || loginError || gateError" />
                <FormInput
                    v-model="email"
                    :placeholder="$t('forms.fields.email').toString()"
                    type="email"
                    rules="required|email"
                    name="email"
                />
                <div class="flex justify-end sm:col-span-6">
                    <FormButton
                        type="submit"
                        :size="ButtonSizeVariants.XL"
                        :loading="loading"
                    >
                        {{ $t("login.buttons.send_code") }}
                    </FormButton>
                </div>
            </FormFieldSet>
        </form>
    </ValidationObserver>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";
import { ValidationObserver } from "vee-validate";

import Alert from "../ui/Alert";
import FormFieldSet from "./fields/FormFieldSet";
import FormInput from "./fields/FormInput";
import FormButton, {
    ButtonSizeVariants,
} from "@/components/forms/fields/FormButton";

export default {
    name: "LoginForm",
    components: {
        ValidationObserver,
        FormButton,
        FormInput,
        Alert,
        FormFieldSet,
    },
    data: () => ({
        ButtonSizeVariants,
        email: null,
    }),
    computed: {
        /**
         * The request loading state
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.LOGIN_LOADING
            ]();
        },
        /**
         * The request error state
         * @type {ErrorOrObject}
         */
        csrfError() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.CSRF_ERROR
            ]();
        },
        /**
         * The gate error state
         * @type {ErrorOrObject}
         */
        gateError() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.GATE_ERROR
            ]();
        },
        /**
         * The request error state
         * @type {ErrorOrObject}
         */
        loginError() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.LOGIN_ERROR
            ]();
        },
    },
    methods: {
        login() {
            this.$store
                .dispatch(AuthStoreNamespacedTypes.actions.LOGIN, {
                    email: this.email,
                })
                .then(() => {
                    if (!this.loginError && !this.csrfError) {
                        this.$emit("validated");
                    }
                });
        },
    },
    beforeCreate() {
        this.$store.commit(
            AuthStoreNamespacedTypes.mutations.SET_LOGIN_ERROR,
            null
        );
    },
};
</script>
