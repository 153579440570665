<template>
    <div>
        <div class="mb-4 flex flex-row rounded-xl bg-indigo-50 px-4 py-3">
            <div class="ml-2 mr-4 flex-shrink-0 self-center">
                <EmailAnimation />
            </div>
            <div>
                <p class="mb-1 font-bold text-indigo-600">
                    {{ $t("login.two_factor.title") }}
                </p>
                <p class="text-sm text-indigo-500">
                    {{ $t("login.two_factor.text") }}
                </p>
            </div>
        </div>
        <ValidationObserver ref="twoFactorForm" v-slot="{ passes }">
            <form @submit.prevent="passes(login)">
                <FormFieldSet>
                    <Alert :error="error" />
                    <FormInput
                        v-model="code"
                        type="number"
                        rules="required|numeric"
                        name="code"
                    />
                    <div class="flex justify-end sm:col-span-6">
                        <FormButton
                            type="submit"
                            :size="ButtonSizeVariants.XL"
                            :loading="loading"
                        >
                            {{ $t("login.buttons.login") }}
                        </FormButton>
                    </div>
                </FormFieldSet>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { AuthStoreNamespacedTypes } from "@/store/modules/auth";
import { LocaleStoreNamespacedTypes } from "@/store/modules/locale";
import { ValidationObserver } from "vee-validate";
import AmplitudeMixin from "@/mixins/AmplitudeMixin";

import { locales } from "@/utils/enums";

import Alert from "../ui/Alert";
import FormFieldSet from "./fields/FormFieldSet";
import FormInput from "./fields/FormInput";
import FormButton, { ButtonSizeVariants } from "./fields/FormButton";
import EmailAnimation from "@/components/ui/EmailAnimation";

export default {
    name: "TwoFactorForm",
    mixins: [AmplitudeMixin],
    components: {
        EmailAnimation,
        ValidationObserver,
        FormButton,
        FormInput,
        Alert,
        FormFieldSet,
    },
    data: () => ({
        ButtonSizeVariants,
        code: null,
    }),
    computed: {
        /**
         * The request loading state
         * @type {Boolean}
         */
        loading() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.LOGIN_LOADING
            ]();
        },
        /**
         * The request error state
         * @type {ErrorOrObject}
         */
        error() {
            return this.$store.getters[
                AuthStoreNamespacedTypes.getters.LOGIN_ERROR
            ]();
        },

        /**
         * The currently authenticated user
         * @type {Object}
         */
        user() {
            return this.$store.getters[AuthStoreNamespacedTypes.getters.USER]();
        },
    },
    methods: {
        login() {
            this.$store
                .dispatch(AuthStoreNamespacedTypes.actions.VALIDATE_CODE, {
                    code: this.code,
                })
                .then(() => {
                    if (!this.error) {
                        if (locales.includes(this.user.locale)) {
                            this.$store.dispatch(
                                LocaleStoreNamespacedTypes.actions
                                    .UPDATE_LOCALE,
                                this.user.locale
                            );
                        }

                        this.$emit("authenticated");

                        this.trackAmplitudeEvent("login", {
                            method: "email",
                        });
                    }
                });
        },
    },
    beforeCreate() {
        this.$store.commit(
            AuthStoreNamespacedTypes.mutations.SET_LOGIN_ERROR,
            null
        );
    },
};
</script>
