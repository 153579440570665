import Vue from "vue";
import VueI18n from "vue-i18n";
import { localeEn } from "@/utils/enums";

import en_US from "./en.json";
import da_DK from "./da.json";
import de_DE from "./de.json";

const messages = {
    en_US: en_US,
    da_DK: da_DK,
    de_DE: de_DE,
};

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: "da_DK",
    fallbackLocale: "da_DK",
    messages: messages,
});

export const setLocale = (locale) => {
    i18n.locale = locale;
};

export const getLocale = () => {
    return i18n.locale || localeEn;
};

export default i18n;
