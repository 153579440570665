/**
 * Form fields mixin
 */
import { ValidationProvider } from "vee-validate";

export default {
    components: { ValidationProvider },
    props: {
        vid: {
            type: String,
            default: undefined,
        },
        name: {
            type: String,
            default: "",
        },
        rules: {
            type: [Object, String],
            default: "",
        },
        value: {
            type: null,
            default: null,
        },
    },
    data: () => ({
        innerValue: null,
    }),
    computed: {
        label() {
            return this.$te("forms.fields." + this.name)
                ? this.$t("forms.fields." + this.name)
                : null;
        },
        help() {
            return this.$te("forms.help." + this.name)
                ? this.$t("forms.help." + this.name)
                : null;
        },
    },
    watch: {
        innerValue(value) {
            this.$emit("input", value);
        },
        value(val) {
            if (val !== this.innerValue) {
                this.innerValue = val;
            }
        },
    },
    created() {
        if (this.value) {
            this.innerValue = this.value;
        }
    },
};
