/**
 * A store for handling multiple choice quizzes
 *
 * @typedef {{ quiz: Object, loading: boolean, error: ErrorOrObject, answer: Object, answers: Array }} MultipleChoiceStoreState
 */
import { addNamespace } from "./namespace";
import { getData, postData } from "@/api";
import { getProperty } from "@/utils/object";

/**
 * The types used in this store
 * @enum {string}
 */
export const MultipleChoiceStoreTypes = {
    getters: {
        QUIZ: "quiz",
        LOADING: "loading",
        ERROR: "error",
        ANSWER: "answer",
        ANSWERS: "answers",
        CORRECT_ANSWERS_COUNT: "correctAnswersCount",
        INCORRECT_ANSWERS_COUNT: "incorrectAnswersCount",
    },
    actions: {
        GET_QUIZ: "getQuiz",
        SAVE_ANSWER: "saveAnswer",
    },
    mutations: {
        SET_QUIZ: "setQuiz",
        SET_LOADING: "setLoading",
        SET_ERROR: "setError",
        SET_ANSWER: "setAnswer",
        SET_ANSWERS: "setAnswers",
        RESET_ANSWERS: "resetAnswers",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const MultipleChoiceStoreNamespacedTypes = addNamespace(
    "multipleChoice",
    MultipleChoiceStoreTypes
);

/**
 * @returns {MultipleChoiceStoreState}
 */
export function state() {
    return {
        quiz: {
            checksum: null,
            questions: [],
        },
        loading: false,
        error: null,
        answer: {
            correct: null,
            checksum: null,
            option: null,
            explanation: null,
            correct_option: null,
        },
        answers: [],
    };
}

export const getters = {
    [MultipleChoiceStoreTypes.getters.QUIZ]: (state) => () => {
        return state.quiz;
    },
    [MultipleChoiceStoreTypes.getters.LOADING]: (state) => () => {
        return state.loading;
    },
    [MultipleChoiceStoreTypes.getters.ERROR]: (state) => () => {
        return state.error;
    },
    [MultipleChoiceStoreTypes.getters.ANSWER]: (state) => () => {
        return state.answer;
    },
    [MultipleChoiceStoreTypes.getters.ANSWERS]: (state) => () => {
        return state.answers;
    },
    [MultipleChoiceStoreTypes.getters.CORRECT_ANSWERS_COUNT]: (state) => () => {
        return state.answers.filter((answer) => answer.correct).length;
    },
    [MultipleChoiceStoreTypes.getters.INCORRECT_ANSWERS_COUNT]:
        (state) => () => {
            return state.answers.filter((answer) => !answer.correct).length;
        },
};

export const actions = {
    /**
     * Sends the given credentials to the server and updates the store with the returned multipleChoice data.
     *
     * @param {VuexCommit} commit
     * @param {String} quiz
     * @return {Promise}
     */
    [MultipleChoiceStoreTypes.actions.GET_QUIZ]({ commit }, quiz) {
        commit(MultipleChoiceStoreTypes.mutations.SET_LOADING, true);
        commit(MultipleChoiceStoreTypes.mutations.SET_ERROR, null);
        commit(MultipleChoiceStoreTypes.mutations.SET_ANSWER, {
            correct: null,
            checksum: null,
            option: null,
            explanation: null,
            correct_option: null,
        });
        commit(MultipleChoiceStoreTypes.mutations.RESET_ANSWERS);

        return getData("/courses/quiz/multiple-choice/questions/" + quiz)
            .then((response) => {
                commit(
                    MultipleChoiceStoreTypes.mutations.SET_QUIZ,
                    response.data
                );
            })
            .catch((errors) => {
                commit(MultipleChoiceStoreTypes.mutations.SET_ERROR, errors);
            })
            .finally(() => {
                commit(MultipleChoiceStoreTypes.mutations.SET_LOADING, false);
            });
    },

    /**
     * Sends the given credentials to the server and updates the store with the returned multipleChoice data.
     *
     * @param {VuexCommit} commit
     * @param {MultipleChoiceStoreState} state
     * @param {String} question
     * @param {String} option
     * @return {Promise}
     */
    [MultipleChoiceStoreTypes.actions.SAVE_ANSWER](
        { commit, state },
        { question, option }
    ) {
        commit(MultipleChoiceStoreTypes.mutations.SET_ERROR, null);

        return postData("/courses/quiz/multiple-choice/answer", {
            question: question,
            option: option,
            checksum: state.quiz.checksum,
        })
            .then((response) => {
                commit(
                    MultipleChoiceStoreTypes.mutations.SET_ANSWER,
                    response.data
                );
                commit(
                    MultipleChoiceStoreTypes.mutations.SET_ANSWERS,
                    response.data
                );

                return getProperty(response, "data.correct", false);
            })
            .catch((errors) => {
                commit(MultipleChoiceStoreTypes.mutations.SET_ERROR, errors);
            });
    },
};

export const mutations = {
    /**
     * Sets a new multipleChoice quiz.
     *
     * @param {MultipleChoiceStoreState} state
     * @param {Object|null} quiz
     */
    [MultipleChoiceStoreTypes.mutations.SET_QUIZ](state, quiz) {
        state.quiz = quiz;
    },

    /**
     * Sets the loading state for simulation.
     *
     * @param {MultipleChoiceStoreState} state
     * @param {boolean} loading
     */
    [MultipleChoiceStoreTypes.mutations.SET_LOADING](state, loading) {
        state.loading = loading;
    },

    /**
     * Sets a new multipleChoice quiz error.
     *
     * @param {MultipleChoiceStoreState} state
     * @param {ErrorOrObject} error
     */
    [MultipleChoiceStoreTypes.mutations.SET_ERROR](state, error) {
        state.error = error;
    },

    /**
     * Sets a new multipleChoice answer.
     *
     * @param {MultipleChoiceStoreState} state
     * @param {string|null} correct
     * @param {string|null} answer
     */
    [MultipleChoiceStoreTypes.mutations.SET_ANSWER](
        state,
        {
            correct = null,
            option = null,
            checksum = null,
            explanation = null,
            correct_option = null,
        }
    ) {
        state.answer.correct = correct;
        state.answer.option = option;
        state.answer.checksum = checksum;
        state.answer.explanation = explanation;
        state.answer.correct_option = correct_option;
    },

    /**
     * Sets a new multipleChoice answer.
     *
     * @param {MultipleChoiceStoreState} state
     * @param {string|null} correct
     * @param {string|null} answer
     */
    [MultipleChoiceStoreTypes.mutations.SET_ANSWERS](
        state,
        {
            correct = null,
            option = null,
            checksum = null,
            explanation = null,
            correct_option = null,
        }
    ) {
        state.answers.push({
            correct: correct,
            option: option,
            checksum: checksum,
            explanation: explanation,
            correct_option: correct_option,
        });
    },

    /**
     * Sets a new multipleChoice answer.
     *
     * @param {MultipleChoiceStoreState} state
     */
    [MultipleChoiceStoreTypes.mutations.RESET_ANSWERS](state) {
        state.answers = [];
    },
};

export default {
    namespaced: true,
    Types: MultipleChoiceStoreTypes,
    NamespacedTypes: MultipleChoiceStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
