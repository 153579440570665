var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{class:[
        '-m-3 flex items-center rounded-md p-3 text-base font-medium hover:bg-slate-100',
        { 'bg-slate-100': _vm.isActive },
        {
            'text-slate-900': !_vm.isActive,
        },
    ],attrs:{"to":_vm.route}},[_vm._v(" "+_vm._s(_vm.$t("navigation." + _vm.text))+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }