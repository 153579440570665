<template>
    <transition name="zoom">
        <div
            v-if="visible"
            class="mb-6 flex w-full flex-1 rounded-md p-4"
            role="alert"
        >
            <div class="flex-shrink-0">
                <svg
                    class="h-5 w-5 text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clip-rule="evenodd"
                    />
                </svg>
            </div>
            <div class="ml-3">
                <button v-if="allowDismiss" class="btn btn-sm" @click="dismiss">
                    x
                </button>
                <div v-if="hasHeader">
                    <slot name="header" />
                </div>
                <slot />
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "MessageItem",
    props: {
        visible: {
            type: Boolean,
            default: true,
        },
        allowDismiss: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasHeader() {
            return this.$slots.header;
        },
    },
    methods: {
        dismiss() {
            this.$emit("dismiss");
        },
    },
};
</script>
