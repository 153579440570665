/**
 * A store simulation.
 *
 * @typedef {{ simulationLoading: boolean, simulationError: ErrorOrObject, simulations: Object, lastUpdateTime: string|null }} SimulationStoreState
 */
import { addNamespace } from "./namespace";
import { getData } from "@/api";
import { isRecentEvent } from "@/utils/date";

/**
 * The types used in this store
 * @enum {string}
 */
export const SimulationStoreTypes = {
    getters: {
        SIMULATION_LOADING: "simulationLoading",
        SIMULATION_ERROR: "simulationError",
        SIMULATIONS: "simulations",
        LAST_UPDATE_TIME: "lastUpdateTime",
    },
    actions: {
        GET_SIMULATIONS: "getSimulations",
        GET_SIMULATION: "getSimulation",
    },
    mutations: {
        SET_SIMULATION_LOADING: "setSimulationLoading",
        SET_SIMULATION_ERROR: "setSimulationError",
        SET_SIMULATIONS: "setSimulations",
        SET_LAST_UPDATE_TIME: "setLastUpdateTime",
    },
};

/**
 * A namespaced version of the types used in this store
 * @enum {string}
 */
export const SimulationStoreNamespacedTypes = addNamespace(
    "simulation",
    SimulationStoreTypes
);

/**
 * @returns {SimulationStoreState}
 */
export function state() {
    return {
        simulationLoading: false,
        simulationError: null,
        simulations: {
            data: [],
            meta: {},
        },
        lastUpdateTime: null,
    };
}

export const getters = {
    [SimulationStoreTypes.getters.SIMULATION_LOADING]: (state) => () => {
        return state.simulationLoading;
    },
    [SimulationStoreTypes.getters.SIMULATION_ERROR]: (state) => () => {
        return state.simulationError;
    },
    [SimulationStoreTypes.getters.SIMULATIONS]: (state) => () => {
        return state.simulations;
    },
};

export const actions = {
    /**
     * Sends the given simulation to the server.
     *
     * @param {SimulationStoreState} state
     * @param {VuexCommit} commit
     * @return {Promise}
     */
    [SimulationStoreTypes.actions.GET_SIMULATIONS]({ state, commit }) {
        if (
            state.lastUpdateTime !== null &&
            isRecentEvent(state.lastUpdateTime)
        ) {
            return Promise.resolve();
        }

        commit(SimulationStoreTypes.mutations.SET_SIMULATION_LOADING, true);
        commit(SimulationStoreTypes.mutations.SET_SIMULATION_ERROR, null);

        return getData("/simulations")
            .then((response) => {
                commit(
                    SimulationStoreTypes.mutations.SET_SIMULATIONS,
                    response.data
                );
                commit(
                    SimulationStoreTypes.mutations.SET_LAST_UPDATE_TIME,
                    new Date().getTime()
                );
            })
            .catch((errors) => {
                commit(
                    SimulationStoreTypes.mutations.SET_SIMULATION_ERROR,
                    errors
                );
            })
            .finally(() => {
                commit(
                    SimulationStoreTypes.mutations.SET_SIMULATION_LOADING,
                    false
                );
            });
    },
};

export const mutations = {
    /**
     * Sets the loading state for simulation.
     *
     * @param {SimulationStoreState} state
     * @param {boolean} loading
     */
    [SimulationStoreTypes.mutations.SET_SIMULATION_LOADING](state, loading) {
        state.simulationLoading = loading;
    },

    /**
     * Sets a new simulation error.
     *
     * @param {SimulationStoreState} state
     * @param {ErrorOrObject} error
     */
    [SimulationStoreTypes.mutations.SET_SIMULATION_ERROR](state, error) {
        state.simulationError = error;
    },

    /**
     * Sets a new simulation error.
     *
     * @param {SimulationStoreState} state
     * @param {Object} data
     */
    [SimulationStoreTypes.mutations.SET_SIMULATIONS](state, data) {
        state.simulations = data;
    },

    /**
     * Sets a new breach error.
     *
     * @param {{lastUpdateTime: Date}} state
     * @param {string} lastUpdateTime
     */
    [SimulationStoreTypes.mutations.SET_LAST_UPDATE_TIME](
        state,
        lastUpdateTime
    ) {
        state.lastUpdateTime = new Date(lastUpdateTime);
    },
};

export default {
    namespaced: true,
    Types: SimulationStoreTypes,
    NamespacedTypes: SimulationStoreNamespacedTypes,
    state,
    getters,
    actions,
    mutations,
};
