var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"mandatory-video relative flex flex-col p-4 lg:flex-row"},[_c('div',{staticClass:"mb-4 w-full lg:mb-0 lg:h-32 lg:w-56"},[_c('router-link',{attrs:{"router-link":"","to":{
                name: 'videos.single',
                params: { category: _vm.slug, video: _vm.id },
                query: {
                    referrer: this.referrer,
                    placement: this.placement,
                },
            }}},[_c('div',{staticClass:"media rounded-xl ring-1 ring-slate-200"},[_c('img',{staticClass:"media-figure",attrs:{"src":_vm.image,"alt":"media"}}),_c('div',{staticClass:"media-plc"}),_c('div',{staticClass:"absolute bottom-2 left-2 flex items-center rounded bg-slate-600 px-1.5 py-0.5 text-sm text-white"},[_c('Icon',{staticClass:"h-3 w-2 fill-current stroke-current",attrs:{"icon":_vm.IconEnums.PLAY}}),_c('time',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.duration))])],1)])])],1),_c('div',{staticClass:"relative flex flex-grow flex-col justify-between lg:ml-4 lg:items-start"},[_c('div',[_c('p',{staticClass:"heading"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.deadline)?_c('DeadlineBadge',{attrs:{"deadline":_vm.deadline}}):_vm._e()],1),_c('FormButton',{attrs:{"to":{
                name: 'videos.single',
                params: { category: _vm.slug, video: _vm.id },
                query: {
                    referrer: this.referrer,
                    placement: this.placement,
                },
            },"variant":_vm.ButtonVariants.SECONDARY,"size":_vm.ButtonSizeVariants.SM}},[_vm._v(" "+_vm._s(_vm.$t("videos.play")))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }